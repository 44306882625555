import { selector } from "recoil";
import { currentFloorState } from "./currentFloorState";


export const floorCustomDataUrlState = selector<string | null>({
    key: "floorCustomDataUrlState",
    get: ({ get }) => {
        const floor = get(currentFloorState);
        return floor?.custom_data_url || null;
    },
});
