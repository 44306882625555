import { FC } from "react";
import { useRecoilValue } from "recoil";
import { routeSearchState } from "../../../stores";
import { lang } from "../../../functions/lang";
import { IfShowRouteMeter } from "../../../features/IfShowRouteMeter";
import { IfBlock } from "../../../components/IfBlock";

// 経路案内 ※フロア内 + 条件指定

export const RouteMessageTotal: FC = () => {
    const { cost } = useRecoilValue(routeSearchState);
    // const costTime = Math.round(cost / 80 + 0.5);
    // TODO: 時間表示のフラグ確認
    return (
        <IfShowRouteMeter>
            <div className="d-flex align-items-center h-100">
                <span style={{ fontSize: '.75em' }}>{lang('目的地まで')}</span>
                <span className="mx-2"><strong>{cost || 0}</strong></span>
                <small>{lang('m')}</small>
                {/* <IfBlock visible={false}>
                    <span className="mx-2">,</span>
                    <span style={{ fontSize: '.75em' }}>{lang('およそ')}</span>
                    <span className="mx-2"><strong>{costTime || 0}</strong></span>
                    <small>{lang('分')}</small>
                </IfBlock> */}
            </div>
        </IfShowRouteMeter>
    );
};
