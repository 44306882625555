import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { openSelectMovingModalState, SameFloorState, IsSkipSelectMoving } from "../../../stores";

export const useResetOpenSelectMovingModal = () => {
    const sameFloor = useRecoilValue(SameFloorState);
    const setOpen = useSetRecoilState(openSelectMovingModalState);
    const isSkipSelectMoving = useRecoilValue(IsSkipSelectMoving);
    useEffect(() => {
        setOpen(() => {
            if (sameFloor) return false;
            if (isSkipSelectMoving) return false;
            return true;
        });
    }, [setOpen, sameFloor]);
    return null;
};
