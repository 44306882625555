import { FC, ReactNode } from "react";
import { Popup } from "react-leaflet";

export const DestinationPopup: FC<{
    children: ReactNode,
}> = ({ children }) => (
    <Popup
        className="cst-right-popup"
        autoPanPaddingTopLeft={[10, 10]}
        autoPanPaddingBottomRight={[10, 10]}
        maxHeight={height}
        minWidth={width}
        maxWidth={width}
        closeButton={false}
        offset={[140, 100]}
    >
        <div style={{ height: height }}>
            {children}
        </div>
    </Popup>
);

const width = 200;
const height = 126;