import { FC } from "react";

export const DetailButton: FC<{ href: string; }> = ({ href }) => (
    <a
        className="btn btn-sm btn-outline-primary w-100"
        href={href}
        target="_blank"
        rel="noopener noreferrer"
    >
        詳細
    </a>
);
