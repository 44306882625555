import { selector } from "recoil";
import { routeSearchState } from "./routeSearchState";


export const naviAngleBearingState = selector<number>({
    key: "naviAngleBearingState",
    get: ({ get }) => {
        const { qrAngle } = get(routeSearchState);
        const table = {
            "1": 360 - (0),
            "2": 360 - (90),
            "3": 360 - (180),
            "4": 360 - (270),
            "5": 360 - (45 + 0),
            "6": 360 - (45 + 90),
            "7": 360 - (45 + 180),
            "8": 360 - (45 + 270),
        };
        return table[qrAngle] || 0;
    }
});
