import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
};

export type ActiveFloorMoveType = {
  __typename?: 'ActiveFloorMoveType';
  es: Scalars['Boolean'];
  ev: Scalars['Boolean'];
  step: Scalars['Boolean'];
};

export type CacheOption = {
  load?: InputMaybe<Scalars['Boolean']>;
  save?: InputMaybe<Scalars['Boolean']>;
};

export type CategoriesHasMany = {
  create?: InputMaybe<Array<InputMaybe<CreateCategoryInput>>>;
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  update?: InputMaybe<Array<InputMaybe<UpdateCategoryInput>>>;
};

export type Category = {
  __typename?: 'Category';
  created_at?: Maybe<Scalars['DateTime']>;
  destinations?: Maybe<Array<Maybe<Destination>>>;
  floorGroup?: Maybe<FloorGroup>;
  floor_group_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  keyword?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sort_no?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of Category items. */
export type CategoryPaginator = {
  __typename?: 'CategoryPaginator';
  /** A list of Category items. */
  data: Array<Category>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type CreateCategoryInput = {
  floor_group_id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  sort_no?: InputMaybe<Scalars['Int']>;
};

export type CreateDestinationColumnInput = {
  field_type_id?: InputMaybe<Scalars['ID']>;
  floor_group_id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  sort_no?: InputMaybe<Scalars['Int']>;
};

export type CreateDestinationInput = {
  category_id?: InputMaybe<Scalars['ID']>;
  destinationValues?: InputMaybe<DestinationValuesHasMany>;
  floor_id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  point_id?: InputMaybe<Scalars['ID']>;
  points?: InputMaybe<PointsBelongsToMany>;
  sort_no?: InputMaybe<Scalars['Int']>;
};

export type CreateDestinationValueInput = {
  destination_column_id?: InputMaybe<Scalars['ID']>;
  destination_id?: InputMaybe<Scalars['ID']>;
  text1?: InputMaybe<Scalars['String']>;
  text2?: InputMaybe<Scalars['String']>;
  text3?: InputMaybe<Scalars['String']>;
};

export type CreateFloorGroupInput = {
  categories?: InputMaybe<CategoriesHasMany>;
  floorMovements?: InputMaybe<FloorMovementsHasMany>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['ID']>;
};

export type CreateFloorInput = {
  floor_group_id?: InputMaybe<Scalars['ID']>;
  height_size?: InputMaybe<Scalars['Int']>;
  image_path?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  ppm?: InputMaybe<Scalars['Float']>;
  sort_no?: InputMaybe<Scalars['Int']>;
  width_size?: InputMaybe<Scalars['Int']>;
};

export type CreateFloorMovementInput = {
  floor_group_id?: InputMaybe<Scalars['ID']>;
  floor_move_type_id?: InputMaybe<Scalars['ID']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  sort_no?: InputMaybe<Scalars['Int']>;
};

export type CreateOrganizationInput = {
  is_active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CreatePointInput = {
  center?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  code?: InputMaybe<Scalars['String']>;
  destination_id?: InputMaybe<Scalars['ID']>;
  destinations?: InputMaybe<DestinationsBelongsToMany>;
  floor_id?: InputMaybe<Scalars['ID']>;
  floor_movement_id?: InputMaybe<Scalars['ID']>;
  icon_id?: InputMaybe<Scalars['ID']>;
  is_destination?: InputMaybe<Scalars['Boolean']>;
  is_floor_movement?: InputMaybe<Scalars['Boolean']>;
  is_qr?: InputMaybe<Scalars['Boolean']>;
  is_visible?: InputMaybe<Scalars['Boolean']>;
  tooltip?: InputMaybe<Scalars['String']>;
};

export type CreateRouteInput = {
  floor_id?: InputMaybe<Scalars['ID']>;
  from_point_id?: InputMaybe<Scalars['ID']>;
  from_route_position_type_id?: InputMaybe<Scalars['ID']>;
  is_move_from_to?: InputMaybe<Scalars['Boolean']>;
  is_move_to_from?: InputMaybe<Scalars['Boolean']>;
  length?: InputMaybe<Scalars['Int']>;
  to_point_id?: InputMaybe<Scalars['ID']>;
  to_route_position_type_id?: InputMaybe<Scalars['ID']>;
};

export type CreateUserInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  userRole?: InputMaybe<UserRoleHasOne>;
};

export type CreateUserRoleInput = {
  is_active?: InputMaybe<Scalars['Boolean']>;
  organization_id?: InputMaybe<Scalars['ID']>;
  user_role_type_id?: InputMaybe<Scalars['ID']>;
};

export type Destination = {
  __typename?: 'Destination';
  body?: Maybe<Scalars['String']>;
  category?: Maybe<Category>;
  category_id?: Maybe<Scalars['ID']>;
  created_at?: Maybe<Scalars['DateTime']>;
  destinationColumns?: Maybe<Array<Maybe<DestinationColumn>>>;
  destinationPageType?: Maybe<DestinationPageType>;
  destinationValues?: Maybe<Array<Maybe<DestinationValue>>>;
  destination_page_type_id?: Maybe<Scalars['ID']>;
  detail_url?: Maybe<Scalars['String']>;
  distance?: Maybe<DistanceOfPoint>;
  floor?: Maybe<Floor>;
  floor_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  iframe_url?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  keyword?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  odaimoku?: Maybe<Array<Maybe<Odaimoku>>>;
  point?: Maybe<Point>;
  point_id?: Maybe<Scalars['ID']>;
  points?: Maybe<Array<Maybe<Point>>>;
  sort_no?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type DestinationColumn = {
  __typename?: 'DestinationColumn';
  created_at?: Maybe<Scalars['DateTime']>;
  fieldType?: Maybe<FieldType>;
  field_type_id?: Maybe<Scalars['ID']>;
  floor_group_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  is_active?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  sort_no?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of DestinationColumn items. */
export type DestinationColumnPaginator = {
  __typename?: 'DestinationColumnPaginator';
  /** A list of DestinationColumn items. */
  data: Array<DestinationColumn>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type DestinationColumnsHasMany = {
  create?: InputMaybe<Array<InputMaybe<CreateDestinationColumnInput>>>;
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  update?: InputMaybe<Array<InputMaybe<UpdateDestinationColumnInput>>>;
};

export type DestinationPageType = {
  __typename?: 'DestinationPageType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** A paginated list of Destination items. */
export type DestinationPaginator = {
  __typename?: 'DestinationPaginator';
  /** A list of Destination items. */
  data: Array<Destination>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** A paginated list of Destination items. */
export type DestinationSimplePaginator = {
  __typename?: 'DestinationSimplePaginator';
  /** A list of Destination items. */
  data: Array<Destination>;
  /** Pagination information about the list of items. */
  paginatorInfo: SimplePaginatorInfo;
};

export type DestinationValue = {
  __typename?: 'DestinationValue';
  destinationColumn?: Maybe<DestinationColumn>;
  destination_column_id?: Maybe<Scalars['ID']>;
  destination_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  text1?: Maybe<Scalars['String']>;
  text2?: Maybe<Scalars['String']>;
  text3?: Maybe<Scalars['String']>;
};

export type DestinationValuesHasMany = {
  create?: InputMaybe<Array<InputMaybe<CreateDestinationValueInput>>>;
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  update?: InputMaybe<Array<InputMaybe<UpdateDestinationValueInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<UpdateDestinationValueInput>>>;
};

export type DestinationsBelongsToMany = {
  connect?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  create?: InputMaybe<Array<InputMaybe<CreateDestinationInput>>>;
  sync?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  update?: InputMaybe<Array<InputMaybe<UpdateDestinationInput>>>;
};

export type DestinationsHasMany = {
  create?: InputMaybe<Array<InputMaybe<CreateDestinationInput>>>;
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  update?: InputMaybe<Array<InputMaybe<UpdateDestinationInput>>>;
};

export type DistanceOfPoint = {
  __typename?: 'DistanceOfPoint';
  cost?: Maybe<Scalars['Int']>;
  costSortNo?: Maybe<Scalars['Int']>;
  isArrived?: Maybe<Scalars['Boolean']>;
  isNotFound?: Maybe<Scalars['Boolean']>;
  sameFloor?: Maybe<Scalars['Boolean']>;
  to?: Maybe<Scalars['ID']>;
  useEs?: Maybe<Scalars['Boolean']>;
  useEv?: Maybe<Scalars['Boolean']>;
  useStep?: Maybe<Scalars['Boolean']>;
};

export type DistanceOfPointsResult = {
  __typename?: 'DistanceOfPointsResult';
  from?: Maybe<Scalars['ID']>;
  points?: Maybe<Array<Maybe<DistanceOfPoint>>>;
};

export type FieldType = {
  __typename?: 'FieldType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type FilterDestinationInput = {
  category_id?: InputMaybe<Scalars['ID']>;
  destinationValues?: InputMaybe<DestinationValuesHasMany>;
  floor_group_id?: InputMaybe<Scalars['ID']>;
  floor_id?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  keyword?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  point_id?: InputMaybe<Scalars['ID']>;
  points?: InputMaybe<PointsBelongsToMany>;
  sort_no?: InputMaybe<Scalars['Int']>;
};

export type FilterFloorInput = {
  floor_group_id?: InputMaybe<Scalars['ID']>;
  height_size?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  image_path?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  ppm?: InputMaybe<Scalars['Float']>;
  sort_no?: InputMaybe<Scalars['Int']>;
  width_size?: InputMaybe<Scalars['Int']>;
};

export type FilterPointInput = {
  code?: InputMaybe<Scalars['ID']>;
  destination_id?: InputMaybe<Scalars['ID']>;
  floor_group_id?: InputMaybe<Scalars['ID']>;
  floor_id?: InputMaybe<Scalars['ID']>;
  floor_movement_id?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  is_destination?: InputMaybe<Scalars['Boolean']>;
  is_floor_movement?: InputMaybe<Scalars['Boolean']>;
  is_qr?: InputMaybe<Scalars['Boolean']>;
  is_visible?: InputMaybe<Scalars['Boolean']>;
  tooltip?: InputMaybe<Scalars['String']>;
};

export type Floor = {
  __typename?: 'Floor';
  created_at?: Maybe<Scalars['DateTime']>;
  custom_data_url?: Maybe<Scalars['String']>;
  floorMovements?: Maybe<Array<Maybe<FloorMovement>>>;
  floor_group_id?: Maybe<Scalars['ID']>;
  height_size?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  image_path?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  points: PointSimplePaginator;
  ppm?: Maybe<Scalars['Int']>;
  routes: RouteSimplePaginator;
  sort_no?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  width_size?: Maybe<Scalars['Int']>;
  zoom_icon_hide?: Maybe<Scalars['Float']>;
  zoom_max?: Maybe<Scalars['Float']>;
  zoom_min?: Maybe<Scalars['Float']>;
  zoom_name_hide?: Maybe<Scalars['Float']>;
  zoom_start?: Maybe<Scalars['Float']>;
};


export type FloorPointsArgs = {
  first?: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};


export type FloorRoutesArgs = {
  first?: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};

export type FloorGroup = {
  __typename?: 'FloorGroup';
  activeFloorMove?: Maybe<ActiveFloorMoveType>;
  categories?: Maybe<Array<Maybe<Category>>>;
  custom_data_url?: Maybe<Scalars['String']>;
  destinationColumns?: Maybe<Array<Maybe<DestinationColumn>>>;
  floorMovements?: Maybe<Array<Maybe<FloorMovement>>>;
  floors?: Maybe<Array<Maybe<Floor>>>;
  icons2?: Maybe<Array<Maybe<Icon>>>;
  id?: Maybe<Scalars['ID']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_auto_map_bearing?: Maybe<Scalars['Boolean']>;
  is_edit_lock?: Maybe<Scalars['Boolean']>;
  is_show_point?: Maybe<Scalars['Boolean']>;
  is_show_route_info?: Maybe<Scalars['Boolean']>;
  is_show_route_meter?: Maybe<Scalars['Boolean']>;
  is_show_route_time?: Maybe<Scalars['Boolean']>;
  is_show_scale?: Maybe<Scalars['Boolean']>;
  is_skip_select_moving?: Maybe<Scalars['Boolean']>;
  is_use_map_bearing?: Maybe<Scalars['Boolean']>;
  is_use_route_search?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  navi_end_name?: Maybe<Scalars['String']>;
  navi_start_name?: Maybe<Scalars['String']>;
  opening_movie_url?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  organization_id?: Maybe<Scalars['ID']>;
  publicDestinations: DestinationSimplePaginator;
  publicPoints: PointSimplePaginator;
};


export type FloorGroupPublicDestinationsArgs = {
  first?: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};


export type FloorGroupPublicPointsArgs = {
  first?: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};

/** A paginated list of FloorGroup items. */
export type FloorGroupPaginator = {
  __typename?: 'FloorGroupPaginator';
  /** A list of FloorGroup items. */
  data: Array<FloorGroup>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type FloorGroupsHasMany = {
  create?: InputMaybe<Array<InputMaybe<CreateFloorGroupInput>>>;
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  update?: InputMaybe<Array<InputMaybe<UpdateFloorGroupInput>>>;
};

export type FloorMoveType = {
  __typename?: 'FloorMoveType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type FloorMovement = {
  __typename?: 'FloorMovement';
  created_at?: Maybe<Scalars['DateTime']>;
  floorGroup?: Maybe<FloorGroup>;
  floorMoveType?: Maybe<FloorMoveType>;
  floor_group_id?: Maybe<Scalars['ID']>;
  floor_move_type_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  is_active?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  points?: Maybe<Array<Maybe<Point>>>;
  sort_no?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of FloorMovement items. */
export type FloorMovementPaginator = {
  __typename?: 'FloorMovementPaginator';
  /** A list of FloorMovement items. */
  data: Array<FloorMovement>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type FloorMovementsHasMany = {
  create?: InputMaybe<Array<InputMaybe<CreateFloorMovementInput>>>;
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  update?: InputMaybe<Array<InputMaybe<UpdateFloorMovementInput>>>;
};

/** A paginated list of Floor items. */
export type FloorPaginator = {
  __typename?: 'FloorPaginator';
  /** A list of Floor items. */
  data: Array<Floor>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type FloorsHasMany = {
  create?: InputMaybe<Array<InputMaybe<CreateFloorInput>>>;
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  update?: InputMaybe<Array<InputMaybe<UpdateFloorInput>>>;
};

export type Icon = {
  __typename?: 'Icon';
  color?: Maybe<Scalars['String']>;
  fill_color?: Maybe<Scalars['String']>;
  fill_opacity?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  iconType?: Maybe<IconType>;
  icon_type_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  image_path?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  opacity?: Maybe<Scalars['Float']>;
  organization?: Maybe<Organization>;
  organization_id?: Maybe<Scalars['ID']>;
  radius?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

/** A paginated list of Icon items. */
export type IconPaginator = {
  __typename?: 'IconPaginator';
  /** A list of Icon items. */
  data: Array<Icon>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type IconType = {
  __typename?: 'IconType';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type MoveOption = {
  useEs?: InputMaybe<Scalars['Boolean']>;
  useEv?: InputMaybe<Scalars['Boolean']>;
  useStep?: InputMaybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  joinPoint?: Maybe<Array<Maybe<Route>>>;
  login: User;
  logout?: Maybe<User>;
  syncDestinationPoint?: Maybe<Point>;
  syncPointDestination?: Maybe<Point>;
  syncRoutePaths?: Maybe<Array<Maybe<Route>>>;
  updateFloor?: Maybe<Floor>;
};


export type MutationJoinPointArgs = {
  floor_group_id: Scalars['ID'];
  katamichi?: InputMaybe<Scalars['Boolean']>;
  point_id?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationLoginArgs = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};


export type MutationSyncDestinationPointArgs = {
  destination_id: Scalars['ID'];
  point_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationSyncPointDestinationArgs = {
  destination_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  point_id: Scalars['ID'];
};


export type MutationSyncRoutePathsArgs = {
  input?: InputMaybe<Array<InputMaybe<SyncRoutePathsInput>>>;
};


export type MutationUpdateFloorArgs = {
  floor_group_id?: InputMaybe<Scalars['ID']>;
  height_size?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  image_path?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<UpdateFloorInput>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  points?: InputMaybe<UpdatePointsHasMany>;
  ppm?: InputMaybe<Scalars['Float']>;
  routes?: InputMaybe<UpdateRoutesHasMany>;
  sort_no?: InputMaybe<Scalars['Int']>;
  width_size?: InputMaybe<Scalars['Int']>;
  zoom_icon_hide?: InputMaybe<Scalars['Float']>;
  zoom_max?: InputMaybe<Scalars['Float']>;
  zoom_min?: InputMaybe<Scalars['Float']>;
  zoom_name_hide?: InputMaybe<Scalars['Float']>;
  zoom_start?: InputMaybe<Scalars['Float']>;
};

export type Odaimoku = {
  __typename?: 'Odaimoku';
  md?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM'
}

export type Organization = {
  __typename?: 'Organization';
  floorGroups?: Maybe<Array<FloorGroup>>;
  id?: Maybe<Scalars['ID']>;
  is_active?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  userRoles?: Maybe<Array<UserRole>>;
};

/** A paginated list of Organization items. */
export type OrganizationPaginator = {
  __typename?: 'OrganizationPaginator';
  /** A list of Organization items. */
  data: Array<Organization>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type OrganizationsHasMany = {
  create?: InputMaybe<Array<InputMaybe<CreateOrganizationInput>>>;
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  update?: InputMaybe<Array<InputMaybe<UpdateOrganizationInput>>>;
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

export type Point = {
  __typename?: 'Point';
  center?: Maybe<Array<Maybe<Scalars['Float']>>>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  destination?: Maybe<Destination>;
  destination_id?: Maybe<Scalars['ID']>;
  destinations?: Maybe<Array<Maybe<Destination>>>;
  floor?: Maybe<Floor>;
  floorMovement?: Maybe<FloorMovement>;
  floor_id?: Maybe<Scalars['ID']>;
  floor_movement_id?: Maybe<Scalars['ID']>;
  fromRoutes?: Maybe<Array<Maybe<Route>>>;
  icon?: Maybe<Icon>;
  icon_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  is_destination?: Maybe<Scalars['Boolean']>;
  is_floor_movement?: Maybe<Scalars['Boolean']>;
  is_qr?: Maybe<Scalars['Boolean']>;
  is_route_info?: Maybe<Scalars['Boolean']>;
  is_visible?: Maybe<Scalars['Boolean']>;
  name2?: Maybe<Scalars['String']>;
  qrDirectionType?: Maybe<Array<Maybe<QrDirectionType>>>;
  qr_direction_type_id?: Maybe<Scalars['ID']>;
  toRoutes?: Maybe<Array<Maybe<Route>>>;
  tooltip?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of Point items. */
export type PointPaginator = {
  __typename?: 'PointPaginator';
  /** A list of Point items. */
  data: Array<Point>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** A paginated list of Point items. */
export type PointSimplePaginator = {
  __typename?: 'PointSimplePaginator';
  /** A list of Point items. */
  data: Array<Point>;
  /** Pagination information about the list of items. */
  paginatorInfo: SimplePaginatorInfo;
};

export type PointsBelongsToMany = {
  connect?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  create?: InputMaybe<Array<InputMaybe<CreatePointInput>>>;
  sync?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  update?: InputMaybe<Array<InputMaybe<UpdatePointInput>>>;
};

export type PointsHasMany = {
  create?: InputMaybe<Array<InputMaybe<CreatePointInput>>>;
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  update?: InputMaybe<Array<InputMaybe<UpdatePointInput>>>;
};

export type QrDirectionType = {
  __typename?: 'QrDirectionType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  Category?: Maybe<Category>;
  CleanKeyword?: Maybe<Scalars['String']>;
  Destination?: Maybe<Destination>;
  DestinationColumn?: Maybe<DestinationColumn>;
  Floor?: Maybe<Floor>;
  FloorGroup?: Maybe<FloorGroup>;
  FloorMovement?: Maybe<FloorMovement>;
  Icon?: Maybe<Icon>;
  Organization?: Maybe<Organization>;
  Point?: Maybe<Point>;
  Route?: Maybe<Route>;
  User?: Maybe<User>;
  UserRole?: Maybe<UserRole>;
  adminFirstFloor?: Maybe<Floor>;
  adminFloorGroup?: Maybe<FloorGroup>;
  allCategory: CategoryPaginator;
  allDestination: DestinationPaginator;
  allDestinationColumn: DestinationColumnPaginator;
  allFloor: FloorPaginator;
  allFloorGroup: FloorGroupPaginator;
  allFloorMovement: FloorMovementPaginator;
  allIcon: IconPaginator;
  allOrganization: OrganizationPaginator;
  allPoint: PointPaginator;
  allRoute: RoutePaginator;
  allUser: UserPaginator;
  allUserRole: UserRolePaginator;
  distanceOfPoints?: Maybe<DistanceOfPointsResult>;
  firstDestination?: Maybe<Destination>;
  firstFloor?: Maybe<Floor>;
  firstPoint?: Maybe<Point>;
  mapIcons?: Maybe<Array<Maybe<Icon>>>;
  me: User;
  routeSearch?: Maybe<RouteSearchResult>;
  searchDestination3?: Maybe<SearchDestinationResult3>;
};


export type QueryCategoryArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCleanKeywordArgs = {
  keyword?: InputMaybe<Scalars['String']>;
};


export type QueryDestinationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryDestinationColumnArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryFloorArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryFloorGroupArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryFloorMovementArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryIconArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryOrganizationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPointArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryRouteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUserRoleArgs = {
  user_id?: InputMaybe<Scalars['ID']>;
};


export type QueryAdminFirstFloorArgs = {
  input?: InputMaybe<FilterFloorInput>;
};


export type QueryAdminFloorGroupArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryAllCategoryArgs = {
  first?: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryAllDestinationArgs = {
  first?: Scalars['Int'];
  input?: InputMaybe<FilterDestinationInput>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryAllDestinationColumnArgs = {
  first?: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryAllFloorArgs = {
  first?: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryAllFloorGroupArgs = {
  first?: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryAllFloorMovementArgs = {
  first?: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryAllIconArgs = {
  first?: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryAllOrganizationArgs = {
  first?: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryAllPointArgs = {
  first?: Scalars['Int'];
  input?: InputMaybe<FilterPointInput>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryAllRouteArgs = {
  first?: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryAllUserArgs = {
  first?: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryAllUserRoleArgs = {
  first?: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryDistanceOfPointsArgs = {
  cache?: InputMaybe<CacheOption>;
  from: Scalars['ID'];
  id: Scalars['ID'];
  moveOption?: InputMaybe<MoveOption>;
};


export type QueryFirstDestinationArgs = {
  input?: InputMaybe<FilterDestinationInput>;
};


export type QueryFirstFloorArgs = {
  input?: InputMaybe<FilterFloorInput>;
};


export type QueryFirstPointArgs = {
  input?: InputMaybe<FilterPointInput>;
};


export type QueryMapIconsArgs = {
  id: Scalars['ID'];
};


export type QueryRouteSearchArgs = {
  cache?: InputMaybe<CacheOption>;
  from?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  moveOption?: InputMaybe<MoveOption>;
  to?: InputMaybe<Scalars['ID']>;
};


export type QuerySearchDestination3Args = {
  input?: InputMaybe<SearchDestinationInput3>;
};

export type Route = {
  __typename?: 'Route';
  created_at?: Maybe<Scalars['DateTime']>;
  floor_id?: Maybe<Scalars['ID']>;
  fromPoint?: Maybe<Point>;
  from_body?: Maybe<Scalars['String']>;
  from_info?: Maybe<Scalars['String']>;
  from_point_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  is_auto_from_info?: Maybe<Scalars['Boolean']>;
  is_auto_length?: Maybe<Scalars['Boolean']>;
  is_auto_to_info?: Maybe<Scalars['Boolean']>;
  is_move_from_to?: Maybe<Scalars['Boolean']>;
  is_move_to_from?: Maybe<Scalars['Boolean']>;
  length?: Maybe<Scalars['Float']>;
  meter?: Maybe<Scalars['Int']>;
  paths?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']>>>>>;
  toPoint?: Maybe<Point>;
  to_body?: Maybe<Scalars['String']>;
  to_info?: Maybe<Scalars['String']>;
  to_point_id?: Maybe<Scalars['ID']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of Route items. */
export type RoutePaginator = {
  __typename?: 'RoutePaginator';
  /** A list of Route items. */
  data: Array<Route>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type RouteSearchResult = {
  __typename?: 'RouteSearchResult';
  ForUnRouteSearchMessage?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Int']>;
  isArrived?: Maybe<Scalars['Boolean']>;
  isNotFound?: Maybe<Scalars['Boolean']>;
  lineEnd?: Maybe<Point>;
  lineStart?: Maybe<Point>;
  naviAngle?: Maybe<Scalars['String']>;
  naviRoute?: Maybe<Scalars['String']>;
  points?: Maybe<Array<Maybe<Point>>>;
  qrAngle?: Maybe<Scalars['String']>;
  qrInfo?: Maybe<Scalars['String']>;
  routes?: Maybe<Array<Maybe<Route>>>;
  tooltips: Array<Maybe<Tooltip>>;
  useEs?: Maybe<Scalars['Boolean']>;
  useEv?: Maybe<Scalars['Boolean']>;
  useStep?: Maybe<Scalars['Boolean']>;
};

/** A paginated list of Route items. */
export type RouteSimplePaginator = {
  __typename?: 'RouteSimplePaginator';
  /** A list of Route items. */
  data: Array<Route>;
  /** Pagination information about the list of items. */
  paginatorInfo: SimplePaginatorInfo;
};

export type RoutesHasMany = {
  create?: InputMaybe<Array<InputMaybe<CreateRouteInput>>>;
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  update?: InputMaybe<Array<InputMaybe<UpdateRouteInput>>>;
};

export type SearchDestinationInput3 = {
  category_id?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  floor_group_id: Scalars['ID'];
  floor_id?: InputMaybe<Scalars['ID']>;
  from: Scalars['ID'];
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type SearchDestinationResult3 = {
  __typename?: 'SearchDestinationResult3';
  data: Array<Destination>;
  paginatorInfo: PaginatorInfo;
};

/** Information about pagination using a simple paginator. */
export type SimplePaginatorInfo = {
  __typename?: 'SimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
};

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type SyncRoutePathsInput = {
  id: Scalars['ID'];
  paths?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['Float']>>>>>;
};

export type Tooltip = {
  __typename?: 'Tooltip';
  body?: Maybe<Scalars['String']>;
  center?: Maybe<Array<Maybe<Scalars['Float']>>>;
  floor_id?: Maybe<Scalars['ID']>;
  fromFloorId?: Maybe<Scalars['ID']>;
  toFloorId?: Maybe<Scalars['ID']>;
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type UpdateCategoryInput = {
  floor_group_id?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  sort_no?: InputMaybe<Scalars['Int']>;
};

export type UpdateDestinationColumnInput = {
  field_type_id?: InputMaybe<Scalars['ID']>;
  floor_group_id?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  sort_no?: InputMaybe<Scalars['Int']>;
};

export type UpdateDestinationInput = {
  category_id?: InputMaybe<Scalars['ID']>;
  destinationValues?: InputMaybe<DestinationValuesHasMany>;
  floor_id?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  point_id?: InputMaybe<Scalars['ID']>;
  points?: InputMaybe<PointsBelongsToMany>;
  sort_no?: InputMaybe<Scalars['Int']>;
};

export type UpdateDestinationValueInput = {
  destination_column_id?: InputMaybe<Scalars['ID']>;
  destination_id?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  text1?: InputMaybe<Scalars['String']>;
  text2?: InputMaybe<Scalars['String']>;
  text3?: InputMaybe<Scalars['String']>;
};

export type UpdateFloorGroupInput = {
  categories?: InputMaybe<CategoriesHasMany>;
  floorMovements?: InputMaybe<FloorMovementsHasMany>;
  id: Scalars['ID'];
  is_active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['ID']>;
};

export type UpdateFloorInput = {
  floor_group_id?: InputMaybe<Scalars['ID']>;
  height_size?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  image_path?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  points?: InputMaybe<UpdatePointsHasMany>;
  ppm?: InputMaybe<Scalars['Float']>;
  routes?: InputMaybe<UpdateRoutesHasMany>;
  sort_no?: InputMaybe<Scalars['Int']>;
  width_size?: InputMaybe<Scalars['Int']>;
};

export type UpdateFloorMovementInput = {
  floor_group_id?: InputMaybe<Scalars['ID']>;
  floor_move_type_id?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  is_active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  sort_no?: InputMaybe<Scalars['Int']>;
};

export type UpdateOrganizationInput = {
  id: Scalars['ID'];
  is_active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<UsersHasMany>;
};

export type UpdatePointInput = {
  center?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  code?: InputMaybe<Scalars['String']>;
  destination_id?: InputMaybe<Scalars['ID']>;
  destinations?: InputMaybe<DestinationsBelongsToMany>;
  floor_id?: InputMaybe<Scalars['ID']>;
  floor_movement_id?: InputMaybe<Scalars['ID']>;
  icon_id?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  is_destination?: InputMaybe<Scalars['Boolean']>;
  is_floor_movement?: InputMaybe<Scalars['Boolean']>;
  is_qr?: InputMaybe<Scalars['Boolean']>;
  is_visible?: InputMaybe<Scalars['Boolean']>;
  tooltip?: InputMaybe<Scalars['String']>;
};

export type UpdatePointsHasMany = {
  create?: InputMaybe<Array<CreatePointInput>>;
  delete?: InputMaybe<Array<Scalars['ID']>>;
  update?: InputMaybe<Array<UpdatePointInput>>;
};

export type UpdateRouteInput = {
  floor_id?: InputMaybe<Scalars['ID']>;
  from_point_id?: InputMaybe<Scalars['ID']>;
  from_route_position_type_id?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  is_move_from_to?: InputMaybe<Scalars['Boolean']>;
  is_move_to_from?: InputMaybe<Scalars['Boolean']>;
  length?: InputMaybe<Scalars['Int']>;
  to_point_id?: InputMaybe<Scalars['ID']>;
  to_route_position_type_id?: InputMaybe<Scalars['ID']>;
};

export type UpdateRoutesHasMany = {
  create?: InputMaybe<Array<CreateRouteInput>>;
  delete?: InputMaybe<Array<Scalars['ID']>>;
  update?: InputMaybe<Array<UpdateRouteInput>>;
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  userRole?: InputMaybe<UserRoleHasOne>;
};

export type UpdateUserRoleInput = {
  is_active?: InputMaybe<Scalars['Boolean']>;
  organization_id?: InputMaybe<Scalars['ID']>;
  user_id: Scalars['ID'];
  user_role_type_id?: InputMaybe<Scalars['ID']>;
};

export type User = {
  __typename?: 'User';
  created_at?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  is_active?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  organization_name?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  user_role_type_name?: Maybe<Scalars['String']>;
};

/** A paginated list of User items. */
export type UserPaginator = {
  __typename?: 'UserPaginator';
  /** A list of User items. */
  data: Array<User>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type UserRole = {
  __typename?: 'UserRole';
  is_active?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<Organization>;
  organization_id?: Maybe<Scalars['ID']>;
  user: User;
  userRoleType?: Maybe<UserRoleType>;
  user_id?: Maybe<Scalars['ID']>;
  user_role_type_id?: Maybe<Scalars['ID']>;
};

export type UserRoleHasOne = {
  create?: InputMaybe<CreateUserRoleInput>;
  update?: InputMaybe<UpdateUserRoleInput>;
};

/** A paginated list of UserRole items. */
export type UserRolePaginator = {
  __typename?: 'UserRolePaginator';
  /** A list of UserRole items. */
  data: Array<UserRole>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type UserRoleType = {
  __typename?: 'UserRoleType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UserRolesHasMany = {
  create?: InputMaybe<Array<InputMaybe<CreateUserRoleInput>>>;
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  update?: InputMaybe<Array<InputMaybe<UpdateUserRoleInput>>>;
};

export type UsersHasMany = {
  create?: InputMaybe<Array<InputMaybe<CreateUserInput>>>;
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  update?: InputMaybe<Array<InputMaybe<UpdateUserInput>>>;
};

export type PointFieldFragment = { __typename?: 'Point', center?: Array<number | null> | null, tooltip?: string | null, icon_id?: string | null, is_qr?: boolean | null, is_destination?: boolean | null, floor_id?: string | null, destination_id?: string | null };

export type FloorFieldFragment = { __typename?: 'Floor', name?: string | null, image_url?: string | null, width_size?: number | null, height_size?: number | null, zoom_min?: number | null, zoom_max?: number | null, zoom_start?: number | null, zoom_icon_hide?: number | null, zoom_name_hide?: number | null, ppm?: number | null, custom_data_url?: string | null };

export type IconFieldFragment = { __typename?: 'Icon', name?: string | null, radius?: number | null, width?: number | null, height?: number | null, image_url?: string | null, color?: string | null, fill_color?: string | null, opacity?: number | null, fill_opacity?: number | null, weight?: number | null, icon_type_id?: string | null };

export type DestinationFieldFragment = { __typename?: 'Destination', name?: string | null, image_url?: string | null, detail_url?: string | null, point_id?: string | null, category_id?: string | null, floor_id?: string | null, category?: { __typename?: 'Category', id?: string | null, name?: string | null } | null, floor?: { __typename?: 'Floor', id?: string | null, name?: string | null } | null, distance?: { __typename?: 'DistanceOfPoint', cost?: number | null, isArrived?: boolean | null, sameFloor?: boolean | null, costSortNo?: number | null, useEv?: boolean | null, useEs?: boolean | null, useStep?: boolean | null } | null };

export type FloorGroupFeildFragment = { __typename?: 'FloorGroup', name?: string | null, is_show_scale?: boolean | null, is_show_point?: boolean | null, is_use_route_search?: boolean | null, is_show_route_meter?: boolean | null, is_skip_select_moving?: boolean | null, is_use_map_bearing?: boolean | null, is_auto_map_bearing?: boolean | null, navi_start_name?: string | null, navi_end_name?: string | null, custom_data_url?: string | null, floors?: Array<{ __typename?: 'Floor', id?: string | null, name?: string | null, image_url?: string | null, width_size?: number | null, height_size?: number | null, zoom_min?: number | null, zoom_max?: number | null, zoom_start?: number | null, zoom_icon_hide?: number | null, zoom_name_hide?: number | null, ppm?: number | null, custom_data_url?: string | null } | null> | null, categories?: Array<{ __typename?: 'Category', id?: string | null, name?: string | null } | null> | null, activeFloorMove?: { __typename?: 'ActiveFloorMoveType', ev: boolean, es: boolean, step: boolean } | null };

export type DistanceOfPointFieldFragment = { __typename?: 'DistanceOfPoint', to?: string | null, cost?: number | null, useEv?: boolean | null, useEs?: boolean | null, useStep?: boolean | null, sameFloor?: boolean | null, isArrived?: boolean | null, isNotFound?: boolean | null };

export type DistanceOfPointsEsQueryVariables = Exact<{
  id: Scalars['ID'];
  from: Scalars['ID'];
}>;


export type DistanceOfPointsEsQuery = { __typename?: 'Query', pointsEs?: { __typename?: 'DistanceOfPointsResult', points?: Array<{ __typename?: 'DistanceOfPoint', to?: string | null, cost?: number | null, useEv?: boolean | null, useEs?: boolean | null, useStep?: boolean | null, sameFloor?: boolean | null, isArrived?: boolean | null, isNotFound?: boolean | null } | null> | null } | null };

export type DistanceOfPointsEvQueryVariables = Exact<{
  id: Scalars['ID'];
  from: Scalars['ID'];
}>;


export type DistanceOfPointsEvQuery = { __typename?: 'Query', pointsEv?: { __typename?: 'DistanceOfPointsResult', points?: Array<{ __typename?: 'DistanceOfPoint', to?: string | null, cost?: number | null, useEv?: boolean | null, useEs?: boolean | null, useStep?: boolean | null, sameFloor?: boolean | null, isArrived?: boolean | null, isNotFound?: boolean | null } | null> | null } | null };

export type DistanceOfPointsStepQueryVariables = Exact<{
  id: Scalars['ID'];
  from: Scalars['ID'];
}>;


export type DistanceOfPointsStepQuery = { __typename?: 'Query', pointsStep?: { __typename?: 'DistanceOfPointsResult', points?: Array<{ __typename?: 'DistanceOfPoint', to?: string | null, cost?: number | null, useEv?: boolean | null, useEs?: boolean | null, useStep?: boolean | null, sameFloor?: boolean | null, isArrived?: boolean | null, isNotFound?: boolean | null } | null> | null } | null };

export type RouteSearchQueryVariables = Exact<{
  group: Scalars['ID'];
  from: Scalars['ID'];
  to: Scalars['ID'];
  moveOption?: InputMaybe<MoveOption>;
}>;


export type RouteSearchQuery = { __typename?: 'Query', routeSearch?: { __typename?: 'RouteSearchResult', qrAngle?: string | null, qrInfo?: string | null, naviAngle?: string | null, naviRoute?: string | null, cost?: number | null, useEv?: boolean | null, useEs?: boolean | null, useStep?: boolean | null, isArrived?: boolean | null, isNotFound?: boolean | null, tooltips: Array<{ __typename?: 'Tooltip', floor_id?: string | null, body?: string | null, center?: Array<number | null> | null, toFloorId?: string | null, fromFloorId?: string | null } | null>, routes?: Array<{ __typename?: 'Route', floor_id?: string | null, paths?: Array<Array<number | null> | null> | null } | null> | null, lineStart?: { __typename?: 'Point', floor_id?: string | null, center?: Array<number | null> | null } | null, lineEnd?: { __typename?: 'Point', floor_id?: string | null, center?: Array<number | null> | null } | null } | null };

export type MapToQueryVariables = Exact<{
  id: Scalars['ID'];
  from?: InputMaybe<Scalars['ID']>;
  withFrom?: InputMaybe<Scalars['Boolean']>;
}>;


export type MapToQuery = { __typename?: 'Query', FloorGroup?: { __typename?: 'FloorGroup', id?: string | null, name?: string | null, is_show_scale?: boolean | null, is_show_point?: boolean | null, is_use_route_search?: boolean | null, is_show_route_meter?: boolean | null, is_skip_select_moving?: boolean | null, is_use_map_bearing?: boolean | null, is_auto_map_bearing?: boolean | null, navi_start_name?: string | null, navi_end_name?: string | null, custom_data_url?: string | null, floors?: Array<{ __typename?: 'Floor', id?: string | null, name?: string | null, image_url?: string | null, width_size?: number | null, height_size?: number | null, zoom_min?: number | null, zoom_max?: number | null, zoom_start?: number | null, zoom_icon_hide?: number | null, zoom_name_hide?: number | null, ppm?: number | null, custom_data_url?: string | null } | null> | null, categories?: Array<{ __typename?: 'Category', id?: string | null, name?: string | null } | null> | null, activeFloorMove?: { __typename?: 'ActiveFloorMoveType', ev: boolean, es: boolean, step: boolean } | null } | null };

export type BaseFloorGroupQueryVariables = Exact<{
  group: Scalars['ID'];
}>;


export type BaseFloorGroupQuery = { __typename?: 'Query', FloorGroup?: { __typename?: 'FloorGroup', id?: string | null, name?: string | null, is_show_scale?: boolean | null, is_show_point?: boolean | null, is_use_route_search?: boolean | null, is_show_route_meter?: boolean | null, is_skip_select_moving?: boolean | null, is_use_map_bearing?: boolean | null, is_auto_map_bearing?: boolean | null, navi_start_name?: string | null, navi_end_name?: string | null, custom_data_url?: string | null, floors?: Array<{ __typename?: 'Floor', id?: string | null, name?: string | null, image_url?: string | null, width_size?: number | null, height_size?: number | null, zoom_min?: number | null, zoom_max?: number | null, zoom_start?: number | null, zoom_icon_hide?: number | null, zoom_name_hide?: number | null, ppm?: number | null, custom_data_url?: string | null } | null> | null, categories?: Array<{ __typename?: 'Category', id?: string | null, name?: string | null } | null> | null, activeFloorMove?: { __typename?: 'ActiveFloorMoveType', ev: boolean, es: boolean, step: boolean } | null } | null, mapIcons?: Array<{ __typename?: 'Icon', id: string, name?: string | null, radius?: number | null, width?: number | null, height?: number | null, image_url?: string | null, color?: string | null, fill_color?: string | null, opacity?: number | null, fill_opacity?: number | null, weight?: number | null, icon_type_id?: string | null } | null> | null };

export type GetPointQueryVariables = Exact<{
  group: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type GetPointQuery = { __typename?: 'Query', Point?: { __typename?: 'Point', id?: string | null, center?: Array<number | null> | null, tooltip?: string | null, icon_id?: string | null, is_qr?: boolean | null, is_destination?: boolean | null, floor_id?: string | null, destination_id?: string | null, destination?: { __typename?: 'Destination', id?: string | null, name?: string | null, image_url?: string | null, detail_url?: string | null, point_id?: string | null, category_id?: string | null, floor_id?: string | null, category?: { __typename?: 'Category', id?: string | null, name?: string | null } | null, floor?: { __typename?: 'Floor', id?: string | null, name?: string | null } | null, distance?: { __typename?: 'DistanceOfPoint', cost?: number | null, isArrived?: boolean | null, sameFloor?: boolean | null, costSortNo?: number | null, useEv?: boolean | null, useEs?: boolean | null, useStep?: boolean | null } | null } | null } | null };

export type PointsQueryVariables = Exact<{
  group: Scalars['ID'];
}>;


export type PointsQuery = { __typename?: 'Query', FloorGroup?: { __typename?: 'FloorGroup', id?: string | null, points: { __typename?: 'PointSimplePaginator', data: Array<{ __typename?: 'Point', id?: string | null, center?: Array<number | null> | null, tooltip?: string | null, icon_id?: string | null, is_qr?: boolean | null, is_destination?: boolean | null, floor_id?: string | null, destination_id?: string | null }> } } | null };

export type DestinationsQueryVariables = Exact<{
  group: Scalars['ID'];
}>;


export type DestinationsQuery = { __typename?: 'Query', FloorGroup?: { __typename?: 'FloorGroup', id?: string | null, destinations: { __typename?: 'DestinationSimplePaginator', data: Array<{ __typename?: 'Destination', id?: string | null, name?: string | null, image_url?: string | null, detail_url?: string | null, point_id?: string | null, category_id?: string | null, floor_id?: string | null, category?: { __typename?: 'Category', id?: string | null, name?: string | null } | null, floor?: { __typename?: 'Floor', id?: string | null, name?: string | null } | null, distance?: { __typename?: 'DistanceOfPoint', cost?: number | null, isArrived?: boolean | null, sameFloor?: boolean | null, costSortNo?: number | null, useEv?: boolean | null, useEs?: boolean | null, useStep?: boolean | null } | null }> } } | null };

export const PointFieldFragmentDoc = gql`
    fragment PointField on Point {
  center
  tooltip
  icon_id
  is_qr
  is_destination
  floor_id
  destination_id
}
    `;
export const IconFieldFragmentDoc = gql`
    fragment IconField on Icon {
  name
  radius
  width
  height
  image_url
  color
  fill_color
  opacity
  fill_opacity
  weight
  icon_type_id
}
    `;
export const DestinationFieldFragmentDoc = gql`
    fragment DestinationField on Destination {
  name
  image_url
  detail_url
  point_id
  category_id
  category {
    id
    name
  }
  floor_id
  floor {
    id
    name
  }
  distance {
    cost
    isArrived
    sameFloor
    costSortNo
    useEv
    useEs
    useStep
  }
}
    `;
export const FloorFieldFragmentDoc = gql`
    fragment FloorField on Floor {
  name
  image_url
  width_size
  height_size
  zoom_min
  zoom_max
  zoom_start
  zoom_icon_hide
  zoom_name_hide
  ppm
  custom_data_url
}
    `;
export const FloorGroupFeildFragmentDoc = gql`
    fragment FloorGroupFeild on FloorGroup {
  name
  is_show_scale
  is_show_point
  is_use_route_search
  is_show_route_meter
  is_skip_select_moving
  is_use_map_bearing
  is_auto_map_bearing
  navi_start_name
  navi_end_name
  custom_data_url
  floors {
    id
    ...FloorField
  }
  categories {
    id
    name
  }
  activeFloorMove {
    ev
    es
    step
  }
}
    ${FloorFieldFragmentDoc}`;
export const DistanceOfPointFieldFragmentDoc = gql`
    fragment DistanceOfPointField on DistanceOfPoint {
  to
  cost
  useEv
  useEs
  useStep
  sameFloor
  isArrived
  isNotFound
}
    `;
export const DistanceOfPointsEsDocument = gql`
    query distanceOfPointsEs($id: ID!, $from: ID!) {
  pointsEs: distanceOfPoints(
    id: $id
    from: $from
    moveOption: {useEs: true, useEv: false, useStep: false}
  ) {
    points {
      ...DistanceOfPointField
    }
  }
}
    ${DistanceOfPointFieldFragmentDoc}`;

/**
 * __useDistanceOfPointsEsQuery__
 *
 * To run a query within a React component, call `useDistanceOfPointsEsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistanceOfPointsEsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistanceOfPointsEsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      from: // value for 'from'
 *   },
 * });
 */
export function useDistanceOfPointsEsQuery(baseOptions: Apollo.QueryHookOptions<DistanceOfPointsEsQuery, DistanceOfPointsEsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DistanceOfPointsEsQuery, DistanceOfPointsEsQueryVariables>(DistanceOfPointsEsDocument, options);
      }
export function useDistanceOfPointsEsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DistanceOfPointsEsQuery, DistanceOfPointsEsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DistanceOfPointsEsQuery, DistanceOfPointsEsQueryVariables>(DistanceOfPointsEsDocument, options);
        }
export type DistanceOfPointsEsQueryHookResult = ReturnType<typeof useDistanceOfPointsEsQuery>;
export type DistanceOfPointsEsLazyQueryHookResult = ReturnType<typeof useDistanceOfPointsEsLazyQuery>;
export type DistanceOfPointsEsQueryResult = Apollo.QueryResult<DistanceOfPointsEsQuery, DistanceOfPointsEsQueryVariables>;
export const DistanceOfPointsEvDocument = gql`
    query distanceOfPointsEv($id: ID!, $from: ID!) {
  pointsEv: distanceOfPoints(
    id: $id
    from: $from
    moveOption: {useEs: false, useEv: true, useStep: false}
  ) {
    points {
      ...DistanceOfPointField
    }
  }
}
    ${DistanceOfPointFieldFragmentDoc}`;

/**
 * __useDistanceOfPointsEvQuery__
 *
 * To run a query within a React component, call `useDistanceOfPointsEvQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistanceOfPointsEvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistanceOfPointsEvQuery({
 *   variables: {
 *      id: // value for 'id'
 *      from: // value for 'from'
 *   },
 * });
 */
export function useDistanceOfPointsEvQuery(baseOptions: Apollo.QueryHookOptions<DistanceOfPointsEvQuery, DistanceOfPointsEvQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DistanceOfPointsEvQuery, DistanceOfPointsEvQueryVariables>(DistanceOfPointsEvDocument, options);
      }
export function useDistanceOfPointsEvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DistanceOfPointsEvQuery, DistanceOfPointsEvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DistanceOfPointsEvQuery, DistanceOfPointsEvQueryVariables>(DistanceOfPointsEvDocument, options);
        }
export type DistanceOfPointsEvQueryHookResult = ReturnType<typeof useDistanceOfPointsEvQuery>;
export type DistanceOfPointsEvLazyQueryHookResult = ReturnType<typeof useDistanceOfPointsEvLazyQuery>;
export type DistanceOfPointsEvQueryResult = Apollo.QueryResult<DistanceOfPointsEvQuery, DistanceOfPointsEvQueryVariables>;
export const DistanceOfPointsStepDocument = gql`
    query distanceOfPointsStep($id: ID!, $from: ID!) {
  pointsStep: distanceOfPoints(
    id: $id
    from: $from
    moveOption: {useEs: false, useEv: false, useStep: true}
  ) {
    points {
      ...DistanceOfPointField
    }
  }
}
    ${DistanceOfPointFieldFragmentDoc}`;

/**
 * __useDistanceOfPointsStepQuery__
 *
 * To run a query within a React component, call `useDistanceOfPointsStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistanceOfPointsStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistanceOfPointsStepQuery({
 *   variables: {
 *      id: // value for 'id'
 *      from: // value for 'from'
 *   },
 * });
 */
export function useDistanceOfPointsStepQuery(baseOptions: Apollo.QueryHookOptions<DistanceOfPointsStepQuery, DistanceOfPointsStepQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DistanceOfPointsStepQuery, DistanceOfPointsStepQueryVariables>(DistanceOfPointsStepDocument, options);
      }
export function useDistanceOfPointsStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DistanceOfPointsStepQuery, DistanceOfPointsStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DistanceOfPointsStepQuery, DistanceOfPointsStepQueryVariables>(DistanceOfPointsStepDocument, options);
        }
export type DistanceOfPointsStepQueryHookResult = ReturnType<typeof useDistanceOfPointsStepQuery>;
export type DistanceOfPointsStepLazyQueryHookResult = ReturnType<typeof useDistanceOfPointsStepLazyQuery>;
export type DistanceOfPointsStepQueryResult = Apollo.QueryResult<DistanceOfPointsStepQuery, DistanceOfPointsStepQueryVariables>;
export const RouteSearchDocument = gql`
    query routeSearch($group: ID!, $from: ID!, $to: ID!, $moveOption: MoveOption) {
  routeSearch(id: $group, from: $from, to: $to, moveOption: $moveOption) {
    qrAngle
    qrInfo
    naviAngle
    naviRoute
    cost
    useEv
    useEs
    useStep
    isArrived
    isNotFound
    tooltips {
      floor_id
      body
      center
      toFloorId
      fromFloorId
    }
    routes {
      floor_id
      paths
    }
    lineStart {
      floor_id
      center
    }
    lineEnd {
      floor_id
      center
    }
  }
}
    `;

/**
 * __useRouteSearchQuery__
 *
 * To run a query within a React component, call `useRouteSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useRouteSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRouteSearchQuery({
 *   variables: {
 *      group: // value for 'group'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      moveOption: // value for 'moveOption'
 *   },
 * });
 */
export function useRouteSearchQuery(baseOptions: Apollo.QueryHookOptions<RouteSearchQuery, RouteSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RouteSearchQuery, RouteSearchQueryVariables>(RouteSearchDocument, options);
      }
export function useRouteSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RouteSearchQuery, RouteSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RouteSearchQuery, RouteSearchQueryVariables>(RouteSearchDocument, options);
        }
export type RouteSearchQueryHookResult = ReturnType<typeof useRouteSearchQuery>;
export type RouteSearchLazyQueryHookResult = ReturnType<typeof useRouteSearchLazyQuery>;
export type RouteSearchQueryResult = Apollo.QueryResult<RouteSearchQuery, RouteSearchQueryVariables>;
export const MapToDocument = gql`
    query mapTo($id: ID!, $from: ID, $withFrom: Boolean = true) {
  FloorGroup(id: $id) {
    id
    ...FloorGroupFeild
  }
}
    ${FloorGroupFeildFragmentDoc}`;

/**
 * __useMapToQuery__
 *
 * To run a query within a React component, call `useMapToQuery` and pass it any options that fit your needs.
 * When your component renders, `useMapToQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMapToQuery({
 *   variables: {
 *      id: // value for 'id'
 *      from: // value for 'from'
 *      withFrom: // value for 'withFrom'
 *   },
 * });
 */
export function useMapToQuery(baseOptions: Apollo.QueryHookOptions<MapToQuery, MapToQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MapToQuery, MapToQueryVariables>(MapToDocument, options);
      }
export function useMapToLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MapToQuery, MapToQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MapToQuery, MapToQueryVariables>(MapToDocument, options);
        }
export type MapToQueryHookResult = ReturnType<typeof useMapToQuery>;
export type MapToLazyQueryHookResult = ReturnType<typeof useMapToLazyQuery>;
export type MapToQueryResult = Apollo.QueryResult<MapToQuery, MapToQueryVariables>;
export const BaseFloorGroupDocument = gql`
    query baseFloorGroup($group: ID!) {
  FloorGroup(id: $group) {
    id
    ...FloorGroupFeild
  }
  mapIcons(id: $group) {
    id
    ...IconField
  }
}
    ${FloorGroupFeildFragmentDoc}
${IconFieldFragmentDoc}`;

/**
 * __useBaseFloorGroupQuery__
 *
 * To run a query within a React component, call `useBaseFloorGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useBaseFloorGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBaseFloorGroupQuery({
 *   variables: {
 *      group: // value for 'group'
 *   },
 * });
 */
export function useBaseFloorGroupQuery(baseOptions: Apollo.QueryHookOptions<BaseFloorGroupQuery, BaseFloorGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BaseFloorGroupQuery, BaseFloorGroupQueryVariables>(BaseFloorGroupDocument, options);
      }
export function useBaseFloorGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BaseFloorGroupQuery, BaseFloorGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BaseFloorGroupQuery, BaseFloorGroupQueryVariables>(BaseFloorGroupDocument, options);
        }
export type BaseFloorGroupQueryHookResult = ReturnType<typeof useBaseFloorGroupQuery>;
export type BaseFloorGroupLazyQueryHookResult = ReturnType<typeof useBaseFloorGroupLazyQuery>;
export type BaseFloorGroupQueryResult = Apollo.QueryResult<BaseFloorGroupQuery, BaseFloorGroupQueryVariables>;
export const GetPointDocument = gql`
    query getPoint($group: ID!, $id: ID!) {
  Point: firstPoint(input: {floor_group_id: $group, id: [$id]}) {
    id
    ...PointField
    destination {
      id
      ...DestinationField
    }
  }
}
    ${PointFieldFragmentDoc}
${DestinationFieldFragmentDoc}`;

/**
 * __useGetPointQuery__
 *
 * To run a query within a React component, call `useGetPointQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPointQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPointQuery({
 *   variables: {
 *      group: // value for 'group'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPointQuery(baseOptions: Apollo.QueryHookOptions<GetPointQuery, GetPointQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPointQuery, GetPointQueryVariables>(GetPointDocument, options);
      }
export function useGetPointLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPointQuery, GetPointQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPointQuery, GetPointQueryVariables>(GetPointDocument, options);
        }
export type GetPointQueryHookResult = ReturnType<typeof useGetPointQuery>;
export type GetPointLazyQueryHookResult = ReturnType<typeof useGetPointLazyQuery>;
export type GetPointQueryResult = Apollo.QueryResult<GetPointQuery, GetPointQueryVariables>;
export const PointsDocument = gql`
    query points($group: ID!) {
  FloorGroup(id: $group) {
    id
    points: publicPoints {
      data {
        id
        ...PointField
      }
    }
  }
}
    ${PointFieldFragmentDoc}`;

/**
 * __usePointsQuery__
 *
 * To run a query within a React component, call `usePointsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePointsQuery({
 *   variables: {
 *      group: // value for 'group'
 *   },
 * });
 */
export function usePointsQuery(baseOptions: Apollo.QueryHookOptions<PointsQuery, PointsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PointsQuery, PointsQueryVariables>(PointsDocument, options);
      }
export function usePointsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PointsQuery, PointsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PointsQuery, PointsQueryVariables>(PointsDocument, options);
        }
export type PointsQueryHookResult = ReturnType<typeof usePointsQuery>;
export type PointsLazyQueryHookResult = ReturnType<typeof usePointsLazyQuery>;
export type PointsQueryResult = Apollo.QueryResult<PointsQuery, PointsQueryVariables>;
export const DestinationsDocument = gql`
    query destinations($group: ID!) {
  FloorGroup(id: $group) {
    id
    destinations: publicDestinations {
      data {
        id
        ...DestinationField
      }
    }
  }
}
    ${DestinationFieldFragmentDoc}`;

/**
 * __useDestinationsQuery__
 *
 * To run a query within a React component, call `useDestinationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDestinationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDestinationsQuery({
 *   variables: {
 *      group: // value for 'group'
 *   },
 * });
 */
export function useDestinationsQuery(baseOptions: Apollo.QueryHookOptions<DestinationsQuery, DestinationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DestinationsQuery, DestinationsQueryVariables>(DestinationsDocument, options);
      }
export function useDestinationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DestinationsQuery, DestinationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DestinationsQuery, DestinationsQueryVariables>(DestinationsDocument, options);
        }
export type DestinationsQueryHookResult = ReturnType<typeof useDestinationsQuery>;
export type DestinationsLazyQueryHookResult = ReturnType<typeof useDestinationsLazyQuery>;
export type DestinationsQueryResult = Apollo.QueryResult<DestinationsQuery, DestinationsQueryVariables>;