import { selector } from "recoil";
import { floorGroupState } from "./floorGroupState";


export const isShowRouteMeterState = selector<boolean>({
    key: "isShowRouteMeterState",
    get: ({ get }) => {
        const floorGroup = get(floorGroupState);
        return floorGroup?.is_show_route_meter || false;
    },
});
