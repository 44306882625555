import { FC, ReactNode } from "react";

export const PointIcon: FC<{
    children: ReactNode;
    className: string;
}> = ({ children, className }) => {
    return (
        <span
            style={style}
            className={`${className} d-flex align-items-center shadow botder-1 btn btn-light rounded-circle`}
        >
            {children}
        </span>
    );
};

const style = {
    fontSize: 20,
    padding: 10,
    border: "1px solid rgba(200,200,200,100)",
};