const COOKIE_ID = "koko";

// type
type valueOf<T> = T[keyof T];
interface LocalStorageType {
    toPoint?: string;
}

// cookie
function removeCookie(key: string): void {
    document.cookie = `${key}=; max-age=0; path=/;`;
}
function setCookie(key: string, value: object): void {
    document.cookie = `${key}=${JSON.stringify(value)}; max-age=3600; path=/;`;
}
function getCookie(key: string): string {
    const text = document.cookie.split("; ").find((row) => row.startsWith(key)) || "=";
    return text.split("=")[1];
}
function getCookieJson(key: string): object {
    try {
        const data = JSON.parse(getCookie(key)) || {};
        if (!(data instanceof Object)) return {};
        return data;
    } catch {
        return {};
    }
}

// local
function getLocalStorageAll(): LocalStorageType {
    return getCookieJson(COOKIE_ID);
}
function getLocalStorage( key: keyof LocalStorageType): valueOf<LocalStorageType> {
    const data = getLocalStorageAll();
    return data[key];
}

function setLocalStorage( key: keyof LocalStorageType, value: valueOf<LocalStorageType>) {
    const data = getLocalStorageAll();
    data[key] = value;
    setCookie(COOKIE_ID, data);
}
// function removeLocalStorage(key: keyof LocalStorageType) {
//     const data = getLocalStorageAll();
//     delete data[key];
//     setCookie(COOKIE_ID, data);
// }
export function removeLocalStorageAll(): void {
    removeCookie(COOKIE_ID);
}

// items
const KEY_3 = "toPoint";
export function getToPoint(): string {
    return getLocalStorage(KEY_3) || "";
}
export function setToPointStorage(props: string) {
    setLocalStorage(KEY_3, props);
}
// export function removeToPoint() {
//     removeLocalStorage(KEY_3);
// }
