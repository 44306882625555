import { FC } from "react";
import { useRecoilValue } from "recoil";

// graphql
import { Destination } from "../../../generated/graphql";

// local
import { showCategoryState, showFloorState } from "../../../stores";
import { IfShowCategory } from "../../../features/IfShowCategory";
import { IfShowFloor } from "../../../features/IfShowFloor";

export const MapRoutePartsCard: FC<{
    destination: Destination;
}> = ({ destination }) => {
    const image_url = destination?.image_url || null;
    const { detail_url } = destination;
    return (
        <div className={`card h-100`}>
            {image_url && <CustomCardImgTop src={image_url} />}
            <div className="card-body">
                <div className="d-flex justify-content-between mb-2">
                    <IfShowCategory>
                        <CategoryNameBlock name={destination?.category?.name || ""} />
                    </IfShowCategory>
                    <IfShowFloor>
                        <FloorNameBlock name={destination?.floor?.name || ""} />
                    </IfShowFloor>
                </div>
                <div className="card-title">
                    <h6>{destination.name}</h6>
                </div>
                <div className="row row-cols-2 g-2 justify-content-start">
                    <div className="col-12">
                        {detail_url && <DetailButton href={detail_url} />}
                    </div>
                </div>
            </div>
        </div>
    );
};

const CustomCardImgTop: FC<{
    src: string;
}> = ({ src }) => (
    <img
        src={src}
        alt="写真"
        className="card-img-top"
        style={{ maxHeight: 200, objectFit: "cover" }}
    />
);

const FloorNameBlock: FC<{
    name: string;
}> = ({ name }) => (
    <div>
        <small>{name}</small>
    </div>
);

const CategoryNameBlock: FC<{
    name: string;
}> = ({ name }) => (
    <div>
        <small>{name}</small>
    </div>
);

const DetailButton: FC<{ href: string }> = ({ href }) => (
    <a
        className="btn btn-sm btn-outline-primary w-100"
        href={href}
        target="_blank"
        rel="noopener noreferrer"
    >
        詳細
    </a>
);
