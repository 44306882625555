import { createControlComponent } from "@react-leaflet/core";
import { Control, ControlOptions } from "leaflet";
import { Rotate } from "./Rotate";

export const RotateControl = createControlComponent<
    Control,
    ControlOptions
>((props) => {
    return new Rotate(props);
});
