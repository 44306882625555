import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { DivIcon as LeafletDivIcon, LatLngTuple } from "leaflet";
import {
    CircleMarker,
    Marker, LayerGroup
} from "react-leaflet";


export const TooltipMarker: FC<{
    position: LatLngTuple;
    body: string;
    url: string;
}> = ({
    position, body, url
}) => {
        const navigate = useNavigate();

        const w = (body || '').length * 12 + 14;
        const icon = new LeafletDivIcon({
            className: 'leaflet-tooltip leaflet-tooltip-top text-center',
            html: body,
            iconSize: [w, 30],
            iconAnchor: [w / 2, 35],
        });

        return (
            <LayerGroup>
                <CircleMarker
                    center={position}
                    radius={5}
                    fillOpacity={1}
                    stroke={false}
                    interactive={false} />
                <Marker
                    zIndexOffset={1000}
                    icon={icon}
                    position={position}
                    eventHandlers={{
                        click: () => {
                            navigate(url);
                        }
                    }} />
            </LayerGroup>
        );
    };
