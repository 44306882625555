import { FC, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { fromPointQueryState } from "../../stores";
import { useGetPointQuery } from "../../generated/graphql";
import { useParams } from "react-router-dom";


export const LoadFromPoint: FC = () => {
    useLoadFromPoint();
    return null;
};


function useLoadFromPoint() {
    const { group, from } = useParams();
    const { data, loading } = useGetPointQuery({
        skip: !group || !from,
        variables: { group, id: from },
    });
    const setFromPointQueryState = useSetRecoilState(fromPointQueryState);
    useEffect(() => {
        setFromPointQueryState({ data, loading });
    }, [data, loading, setFromPointQueryState]);
}