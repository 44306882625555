import { selector } from "recoil";
import { loadingToPointState } from "./loadingToPointState";


export const loadingSameFloorState = selector<boolean>({
    key: "loadingSameFloorState",
    get: ({ get }) => {
        const loadingToPoint = get(loadingToPointState);
        const loadingFromPoint = get(loadingToPointState);
        return loadingToPoint || loadingFromPoint;
    }
});
