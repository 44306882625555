import { FC } from "react";
import { LatLngTuple } from "leaflet";
import { CircleMarker } from "react-leaflet";


export const CustomMiniCircleMarker: FC<{
    center: LatLngTuple;
}> = ({ center }) => {
    return <CircleMarker
        center={center}
        radius={5}
        fillOpacity={1}
        stroke={false} />;
};
