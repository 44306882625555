import { FC } from "react";
import { useRecoilValue } from "recoil";
import { routeSearchState } from "../../../stores";

// 経路案内 ※フロア外

export const RouteMessageNaviRoute: FC = () => {
    const { naviRoute } = useRecoilValue(routeSearchState);
    if (!naviRoute) return null;
    return <div><small className="text-muted">{naviRoute.split(',').join(' → ')}</small></div>;
};
