import { FC } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Destination } from "../../../generated/graphql";
import { DestinationCard } from "./DestinationCard";


export const DestinationCards: FC<{
    destinations: Destination[];
    getSearch: (page: number) => void;
    hasMore: boolean;
}> = ({ destinations: destinations, getSearch, hasMore }) => (
    <InfiniteScroll
        pageStart={0}
        loadMore={(newPage: number) => {
            getSearch(newPage);
        }}
        hasMore={hasMore}
        loader={<div className="loader" key={0}>Loading ...</div>}
        className="row g-2 mb-2 row-cols-1 row-cols-lg-2 row-cols-xl-3"
    >
        {destinations.map((destination: Destination, i: number) => (
            <div className="col" key={i}>
                <DestinationCard
                    sameFloor={destination?.distance?.sameFloor}
                    distanceCost={destination?.distance?.cost || 0}
                    distanceVisible={destination?.distance && !destination?.distance?.isNotFound}
                    categoryName={destination?.category?.name || ''}
                    floorName={destination?.floor?.name || ''}
                    name={destination.name}
                    imageUrl={destination.image_url}
                    pointId={destination.point_id}
                    detailUrl={destination.detail_url}
                />
            </div>
        ))}
    </InfiniteScroll>
);
