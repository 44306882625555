import { FC } from "react";
import { Floor } from "../../../generated/graphql";
import { useRecoilValue } from "recoil";
import { useSearchParams } from "react-router-dom";
import { setQueryValue } from "../functions/setQueryValue";
import { floorsState } from "../../../stores";

export const SelectFloor: FC = () => {
    const key = "q_floor";
    const floors = useRecoilValue(floorsState);
    const [query, setQuery] = useSearchParams();
    const value = query.get(key) || '';
    function setValue(value: string) {
        setQuery((query) => setQueryValue(query, key, value));
    }
    return (
        <select
            onChange={(e) => { setValue(e.target.value); }}
            value={value}
            className="form-select"
        >
            <option value="">すべてのフロア</option>
            {floors
                .filter((e: Floor, i: number) => e?.id && e?.name)
                .map((e: Floor, i: number) => (
                    <option key={i} value={e.id || ""}>
                        {e.name}
                    </option>
                ))}
        </select>
    );
};