import { ChangeEvent, FC } from "react";
import { useSearchParams, useSubmit } from "react-router-dom";
import { lang } from "../../../functions/lang";

export const SearchKeyword: FC = () => {
    const [query] = useSearchParams();
    const q_word = query.get('q') || '';
    const submit = useSubmit();
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        submit(e.currentTarget.form);
    }
    return (
        <input
            onChange={handleChange}
            defaultValue={q_word}
            type="text"
            name="q"
            className="form-control"
            placeholder={lang('ワード検索')}
        />
    );
};