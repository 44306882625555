import { LazyQueryHookOptions, SuspenseQueryHookOptions, gql, useLazyQuery, useSuspenseQuery } from '@apollo/client';
import { Destination } from '../../generated/graphql';

const QUERY = gql`
query searchDestination(
    $group: ID!,
    $from: ID!,
    $keyword: String,
    $page: Int = 1,
    $first: Int = 100,
    $category_id: ID,
    $floor_id: ID
) {
    searchDestination3(
        input: {
            floor_group_id: $group
            from: $from
            keyword: $keyword
            page: $page
            first: $first
            category_id: $category_id
            floor_id: $floor_id
        }
    ) {
        paginatorInfo {
            currentPage
            total
            hasMorePages
        }
        data {
            id
            ...DestinationField
        }
    }
}
fragment DestinationField on Destination {
    name
    image_url
    detail_url
    point_id
    category_id
    category {
        id
        name
    }
    floor_id
    floor {
        id
        name
    }
    distance {
        cost
        isArrived
        sameFloor
        costSortNo
        useEv
        useEs
        useStep
    }
}
`;
type QueryData = {
    searchDestination3: {
        paginatorInfo: { currentPage: number, total: number, hasMorePages: boolean },
        data: Destination[],
    }
}
type QueryVariables = {
    group: string;
    from: string;
    keyword?: string;
    page?: number;
    first?: number;
    category_id?: string;
    floor_id?: string;
};
export function useSearchDestinationSuspenseQuery(options: SuspenseQueryHookOptions<QueryData, QueryVariables>) {
    return useSuspenseQuery<QueryData, QueryVariables>(QUERY, options);
}
export function useSearchDestinationLazyQuery(options: LazyQueryHookOptions<QueryData, QueryVariables>) {
    return useLazyQuery<QueryData, QueryVariables>(QUERY, options);
}