import { selector } from "recoil";
import { floorGroupState } from "./floorGroupState";


export const mapBearingConfigState = selector<{
    is_auto: boolean;
    is_use: boolean;
}>({
    key: "mapBearingConfigState",
    get: ({ get }) => {
        const floorGroup = get(floorGroupState);
        const is_auto = floorGroup.is_auto_map_bearing || false;
        const is_use = floorGroup.is_use_map_bearing || is_auto || false;
        return { is_use, is_auto };
    },
});
