import { selector } from "recoil";
import { currentParamState } from "../atoms/currentParamState";

// select, currentParam

export const currentFloorIdState = selector<string | null>({
    key: "currentFloorIdState",
    get: ({ get }) => {
        const { floor } = get(currentParamState);
        return floor || null;
    },
});
