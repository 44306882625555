import { FC, ReactNode } from "react";
import { Layout } from "../features/MainLayout";

export const PointLayout: FC<{
    children: ReactNode;
}> = ({ children }) => (
    <Layout showCamera={false}>
        <div className="container my-2">{children}</div>
    </Layout>
);
