import { selector, selectorFamily } from "recoil";
import { activeFloorMoveState } from "../selectors/activeFloorMoveState";
import { isShowRouteMeterState } from "../selectors/isShowRouteMeterState";
import { isUseRouteSearchState } from "../selectors/isUseRouteSearchState";


export const isShowDistanceState = selectorFamily<boolean, { sameFloor: boolean }>({
    key: "isShowDistanceState",
    get: ({ sameFloor }) => ({ get }) => {
        const { ev, es, step } = get(activeFloorMoveState);
        const isShowRouteMeter = get(isShowRouteMeterState);
        const isUseRouteSearch = get(isUseRouteSearchState);

        // 非表示
        if (isUseRouteSearch === false) return false;
        if (!isShowRouteMeter && !ev && !es && !step) return false;
        if (!isShowRouteMeter && sameFloor) return false;

        return true;
    },
});
