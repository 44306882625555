import { FC, useEffect, useState } from "react";
import { LogoAnime } from "./LogoAnime";
import { useNavigate, useSearchParams } from "react-router-dom";

export const LogoAnimeAndNavigate: FC<{
    animeUrl: string,
    navigateUrl: string,
}> = ({ animeUrl, navigateUrl }) => {
    const [query] = useSearchParams();
    const skip = !!query.get('skip') as boolean;
    const [playing, setPlaying] = useState(skip === false);
    const navigate = useNavigate();

    // 画面遷移 
    function onEnded() {
        setPlaying(false);
        if (navigateUrl !== "") navigate(navigateUrl);
    }

    useEffect(() => {
        const id = setTimeout(onEnded, 60 * 1000);
        return () => { clearTimeout(id) };
    }, []);

    useEffect(() => {
        if (playing === false) onEnded();
        if (animeUrl === '') onEnded();
    }, []);

    // end hooks

    if (animeUrl && playing) {
        return <LogoAnime url={animeUrl} onEnded={onEnded} />
    }

    return null;
};

