import { selector } from "recoil";
import { categoriesState } from "./categoriesState";


export const showCategoryState = selector<boolean>({
    key: "showCategoryState",
    get: ({ get }) => {
        const categories = get(categoriesState);
        return categories.length >= 2;
    },
});
