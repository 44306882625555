import ReactDOMServer from "react-dom/server";
import {
    Map as LeafletMap,
    DomEvent as LeafletDomEvent,
    DomUtil as LeafletDomUtil,
    Control as LeafletControl
} from "leaflet";
import { createControlComponent } from "@react-leaflet/core";
import { PointIcon } from "../../components/PointIcon";
import { MuiAccessibilityNewIcon } from "../../components/mui";

export const FromPointControlView = createControlComponent(({ position }) => {
    const control = new LeafletControl({ position });
    control.onAdd = (map: LeafletMap) => {
        const div = LeafletDomUtil.create("div");
        div.innerHTML = ReactDOMServer.renderToString((
            <PointIcon className="from_point text-primary">
                <MuiAccessibilityNewIcon />
            </PointIcon>
        ));
        LeafletDomEvent.on(div, "click", () => {
            map.fireEvent("update", { custom: "moveFromPoint" });
        });
        return div;
    };
    return control;
});
