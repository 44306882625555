import { atom } from "recoil";
import { usePointsQuery } from "../../generated/graphql";

type pointsQueryStateType = Pick<ReturnType<typeof usePointsQuery>, 'data' | 'loading'>;
export const pointsQueryState = atom<pointsQueryStateType>({
    key: "pointsQueryState",
    default: {
        data: {} as pointsQueryStateType['data'],
        loading: true,
    } as pointsQueryStateType,
});
