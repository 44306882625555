import { FC } from "react";
import { useRecoilValue } from "recoil";
import {
    SameFloorState,
    loadingSameFloorState,
    isUseRouteSearchState
} from "../../../stores";
import { SelectMovingModal } from './SelectMovingModal';

export const CustomSelectMovingModal: FC = () => {
    const loading = useRecoilValue(loadingSameFloorState);
    const sameFloor = useRecoilValue(SameFloorState);
    const isUseRouteSearch = useRecoilValue(isUseRouteSearchState);

    if (loading) return null;
    if (sameFloor) return null;
    if (!isUseRouteSearch) return null;

    return <SelectMovingModal />;
};
