import { FC, CSSProperties } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { linkQR } from '../../../../../functions/links/linkQR';
import { BarcodeScanner } from "./BarcodeScanner";
import { Bottom } from "../components/Bottom";
import { CircleButton } from "../components/CircleButton";
import { MuiCloseIcon } from "../../../../../components/mui";
import { toPointIdState } from "../../../../../stores";

export const CameraApp: FC<{
    onClose: () => void;
}> = ({ onClose: handleClose }) => {
    const toPointId = useRecoilValue(toPointIdState);
    const navigate = useNavigate();

    function handleScan(text: string) {

        console.log(text);

        // URL
        if (!text) return;
        if (!text.match(/^http/)) return;
        const urlObject = new URL(text);

        // 外部ページ
        if (urlObject.hostname !== window.location.hostname) {
            window.location.href = text;
            handleClose();
            return;
        }

        // 内部ページ
        if (urlObject.hostname === window.location.hostname) {
            const link = text2link({ text, toPointId });
            navigate(link);
            handleClose();
            return;
        }
    }

    return (
        <div style={style}>
            <BarcodeScanner onScan={handleScan} />
            <Bottom>
                <div style={{ margin: 20 }}>
                    <CircleButton onClick={handleClose}>
                        <MuiCloseIcon />
                    </CircleButton>
                </div>
            </Bottom>
        </div>
    );
};

// 内部ページ 遷移
function text2link({ text, toPointId }: { text: string, toPointId: string }): string {
    const { group, code } = getSearchQRParams(text);
    return linkQR({ group, code, to: toPointId });
}

// 「/qr/1/1001」形式を変換
function getSearchQRParams(text: string) {
    const url = new URL(text);
    const query = new URLSearchParams(url.search);
    const slashs = (query.get('/') || '').split('/') || [];
    return {
        group: slashs[1] || '',
        code: slashs[2] || '',
    };
}

const style = {
    position: "fixed",
    top: 0,
    width: "100vw",
    height: "100vh",
    zIndex: 1002,
    background: "#000",
} as CSSProperties;
