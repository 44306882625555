import { LatLngTuple } from "leaflet";

// graphql
import { Tooltip, Floor, Point } from "../generated/graphql";

// local
import { getNumber } from "./utils";

export function getPointCenter(
    pos: Point | null
): LatLngTuple | null {
    return _getCenter(pos);
}

export function getTooltipCenter(
    pos: Tooltip | null,
): LatLngTuple | null {
    return _getCenter(pos);
}

export function getZoomRange(floor: Floor): { minZoom: number; maxZoom: number } {
    let minZoom = getNumber(floor?.zoom_min, -2);
    let maxZoom = getNumber(floor?.zoom_max, 2);

    if (minZoom > maxZoom) {
        minZoom = -2;
        maxZoom = 2;
    }
    return { minZoom, maxZoom };
}

function _getCenter(
    pos: Tooltip | Point | null
): LatLngTuple | null {
    if (!pos?.center) return null;
    return (pos.center) as LatLngTuple;
}
