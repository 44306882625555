import { FC, CSSProperties, ReactNode } from "react";

export const BoxButton: FC<{
    onClick: () => void;
    children: ReactNode;
}> = ({ children, onClick }) => (
    <div className="btn" style={style} onClick={onClick}>
        <div className="d-flex justify-content-center align-items-center h-100 w-100">
            {children}
        </div>
    </div>
);

const style = {
    width: 35,
    height: 35,
    background: "#486AA8",
    color: "#fff",
} as CSSProperties;