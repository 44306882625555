import { FC, ReactNode, useState, useEffect, CSSProperties } from "react";

export const FadeStyleOn: FC<{
    children: ReactNode;
}> = ({ children }) => {
    const [className, setClassName] = useState<string>("fade");
    useEffect(() => {
        const id = setTimeout(() => {
            setClassName("fade show");
        }, 300);
        return () => clearTimeout(id);
    }, []);
    return <div className={className} style={style}>{children}</div>;
};

const style = {
    transitionDuration: "1s",
} as CSSProperties;