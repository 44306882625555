import { selector } from "recoil";
import { Floor } from "../../generated/graphql";
import { floorGroupState } from "./floorGroupState";

// selector

export const floorsState = selector<Floor[]>({
    key: "floorsState",
    get: ({ get }) => {
        const floorGroup = get(floorGroupState);
        return (floorGroup?.floors || []) as Floor[];
    },
});
