import { FC, useEffect, useState } from "react";
import { DestinationCards } from "../components/DestinationCards";
import { lang } from "../../../functions/lang";
import { useSearchApi } from "../hooks/useSearchApi";
import { usePushDataLayer } from "../hooks/usePushDataLayer";
import { IfBlock } from "../../../components/IfBlock";
import { sendLogPublicWhenSearchResult } from "../../../features/logPublic/sendLogPublicWhenSearchResult";
import { useLocation, useParams } from "react-router-dom";

type SearchDataType = {
    q_word: string | null,
    categoryName: string | null,
    floorName: string | null,
}

export const DestinationCardsContainer: FC = () => {

    const { destinations: destinations, loadMorePage, total, loading, hasMore, q_word, categoryName, floorName } = useSearchApi();

    const key = JSON.stringify({ q_word, categoryName, floorName });

    // GA4
    usePushDataLayer({ loading, total: total, keyword: q_word, category: categoryName, floor: floorName });

    useSendLogPublicWhenSearchResult({
        loading,
        search_keyword: q_word,
        search_total: total,
        search_category: categoryName,
        search_floor: floorName
    });

    return (
        <div className="row">
            <div className="col-12">
                <div className="text-right w-100">
                    <IfBlock visible={loading === false} elseElement={lang('loading...')}>
                        {[(total || 0).toLocaleString(), lang('件')].join('')}
                    </IfBlock>
                </div>
            </div>
            <div className="col-12">
                <DestinationCards key={key} hasMore={hasMore} destinations={destinations} getSearch={loadMorePage} />
            </div>
        </div>
    );
};

// 更新ごとにログを残す
function useSendLogPublicWhenSearchResult({
    loading, search_keyword, search_total, search_category, search_floor
}) {
    const location = useLocation();
    const params = useParams();
    useEffect(() => {
        if (loading) return;
        sendLogPublicWhenSearchResult({ location, params, search_keyword, search_total, search_category, search_floor });
    }, [loading, search_keyword, search_total, search_category, search_floor]);
}