import { selector } from "recoil";
import { routeSearchQueryState } from "../atoms/routeSearchQueryState";


export const loadingRouteSearchState = selector<boolean>({
    key: "loadingRouteSearchState",
    get: ({ get }) => {
        return get(routeSearchQueryState)?.loading || false;
    },
});
