import { FC } from "react";
import { useSetRecoilState } from "recoil";
import { Point } from "../../generated/graphql";
import { KokodokoMarker } from "../KokodokoMarker";
import { DestinationPopup } from "../../components/DestinationPopup";
import { currentPointState } from "../../stores";
import { DestinationPopupBody } from "../DestinationPopupBody";

export const MarkerDestinationIcon: FC<{ destinationPoint: Point; }> = ({ destinationPoint: destinationPoint }) => {
    const setCurrentPoint = useSetRecoilState(currentPointState);
    return (
        <KokodokoMarker
            point={destinationPoint}
            eventHandlers={{
                popupopen() { setCurrentPoint(destinationPoint); },
                popupclose() { setCurrentPoint(null); },
            }}
        >
            <DestinationPopup>
                <DestinationPopupBody destination={destinationPoint.destination} />
            </DestinationPopup>
        </KokodokoMarker>
    );
};
