import { FC, ReactNode } from "react";
import {
    LeafletEventHandlerFnMap,
    LatLngTuple
} from "leaflet";
import { Marker } from "react-leaflet";
import { NubmerIcon } from "./NubmerIcon";
import ReactDOMServer from "react-dom/server";
import { makeBottomOptions } from "./makeBottomOptions";
import { divIcon as leafletDivIcon } from "leaflet";


export const CustomNumberMarker: FC<{
    color: string;
    fillColor: string;
    tooltip: string;
    position: LatLngTuple;
    eventHandlers?: LeafletEventHandlerFnMap;
    children: ReactNode;
}> = ({ color, fillColor, tooltip, position, eventHandlers, children }) => {
    const width = 50;
    const height = 20;
    const offsetHeight = 3;
    const size = 20;
    const element = <NubmerIcon color={color} fillColor={fillColor} tooltip={tooltip} width={width} height={height} size={size} />
    return (
        <Marker
            icon={leafletDivIcon({
                html: ReactDOMServer.renderToString(element),
                className: "",
                ...makeBottomOptions({ width, height, offsetHeight, tooltipPosition: 'right' }),
            })}
            position={position}
            eventHandlers={eventHandlers}
        >
            {children}
        </Marker>
    );
}