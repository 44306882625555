import { FC, ReactNode } from "react";
import { useRecoilValue } from "recoil";
import { activeFloorMoveState } from "../stores";
import { IfBlock } from "../components/IfBlock";
export const IfCanEv: FC<{
    children: ReactNode
}> = ({ children }) => {
    const { ev: visible } = useRecoilValue(activeFloorMoveState);
    return <IfBlock visible={visible}>{children}</IfBlock>
};
