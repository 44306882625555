import { createHashRouter } from "react-router-dom";
import { App as QrApp } from "../../../pages/qr";
import { App as MdEditorApp } from "../../../pages/dev-mdeditor";
import { App as MapApp } from "../../../pages/map";
import { App as SearchApp } from "../../../pages/search";
import { App as MapRouteApp } from "../../../pages/map-route";
import { App as DetailPointApp } from "../../../pages/detail-point";
import { App as HomeApp } from "../../../pages/home";
import { loader } from "./loader";

export const router = createHashRouter([
    { loader: loader, path: "/dev-mdeditor", element: <MdEditorApp /> },
    { loader: loader, path: "/", element: <HomeApp /> },
    { loader: loader, path: "/map/:group/:floor/:from", element: <MapApp /> },
    { loader: loader, path: "/search/:group/:floor/:from", element: <SearchApp /> },
    { loader: loader, path: "/map-route/:group/:floor/:from/:to", element: <MapRouteApp /> },
    { loader: loader, path: "/detail-point/:group/:destinationId", element: <DetailPointApp /> },
    { loader: loader, path: "/qr/:group/:code", element: <QrApp /> },
    { loader: loader, path: "*", element: <HomeApp /> },
])
