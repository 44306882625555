import { MuiAccessibilityNewIcon, MuiSportsScoreIcon } from "../../../components/mui";
import { MuiIcon } from "./MuiIcon";
import { createMuiIcon } from "./createMuiIcon";

export const createGoalMapIcon: (
    color: string
) => ReturnType<typeof createMuiIcon> = (color = "red") => createMuiIcon({
    icon: (<MuiIcon color={color}><MuiSportsScoreIcon /></MuiIcon>)
});

export const createStartMapIcon: (
    color: string
) => ReturnType<typeof createMuiIcon> = (color = "red") => createMuiIcon({
    icon: (<MuiIcon color={color}><MuiAccessibilityNewIcon /></MuiIcon>)
});

