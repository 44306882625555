import { FC } from "react";
import { LoadFloorGroup } from "../../features/LoadData/LoadFloorGroup";
import { PageController } from "./parts/PageController";

export const App: FC = () => {
    return (
        <>
            <PageController />
            <LoadFloorGroup />
        </>
    )
}

