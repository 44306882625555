import { FC } from "react";

import { useRecoilState } from "recoil";
import { showQrCameraState } from "../../../../stores";
import { CameraApp } from "./features/CameraApp";
import { CameraButton } from "./components/CameraButton";

export const QrReaderEx: FC = () => {
    const [open, setOpen] = useRecoilState<boolean>(showQrCameraState);
    return open
        ? <CameraApp onClose={() => { setOpen(false) }} />
        : <CameraButton onOpen={() => { setOpen(true) }} />
};
