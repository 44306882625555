import { FC } from "react";
import { Link } from "react-router-dom";
import { Floor } from "../generated/graphql";

export const FloorTab: FC<{
    floors: Floor[];
    currentFloorId: string;
    to: ({ floor }: { floor: string }) => string; // TODO: 移動処理の変更 or 文字型に変更
}> = ({ floors, currentFloorId: current, to: toFunc }) => (
    floors.length > 0 && <ul className="nav">
        {floors
            // .filter((floor: Floor) => floor.id && floor.name != "")
            .map((floor: Floor, i: number) => (
                <li key={floor.id} className="nav-item nav-pills">
                    <Link
                        to={toFunc({ floor: floor.id })}
                        className={`nav-link ${floor.id == current ? "active" : ""}`}
                    >
                        {floor.name}
                    </Link>
                </li>
            ))
        }
    </ul>
);
