import {
    CSSProperties,
    FC,
    ReactNode,
    useState
} from "react";
import { SafeTouch } from "./SafeTouch";

// 画面は非表示だがiPhoneで２本指対応で必要

export const MapLock: FC<{
    children: ReactNode;
}> = ({ children }) => {
    const [mapLock, setMapLock] = useState<boolean>(false);
    // const [read, setRead] = useState<boolean>(false);
    const [read, setRead] = useState<boolean>(true);

    // end hooks
    const style1 = {
        background: "rgba(0,0,0, .6)",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        color: "white",
        zIndex: 2000,
        top: 0,
    } as CSSProperties;

    const style2 = {
        position: "relative",
    } as CSSProperties;

    function onStartMap() {
        setMapLock(false);
        setRead(true);
    }

    return (
        <SafeTouch read={read} setMapLock={setMapLock}>
            <div style={style2}>
                {mapLock && (
                    <div className="debug-39" style={style1}>
                        <div className="text-center">
                            <div>地図は指2本で操作します</div>
                            <div
                                className="btn btn-primary"
                                onClick={onStartMap}
                            >
                                解除
                            </div>
                        </div>
                    </div>
                )}
                {children}
            </div>
        </SafeTouch>
    );
};
