import { FC } from "react";
import { currentPointState, qrPointsState } from "../../stores";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { KokodokoMarker } from "../KokodokoMarker";

export const MarkerQrGroup: FC = () => {
    const qrPoints = useRecoilValue(qrPointsState);
    const setCurrentPoint = useSetRecoilState(currentPointState);
    return (
        <>
            {qrPoints.map((point, i: number) => (
                <KokodokoMarker
                    key={i}
                    point={point}
                    eventHandlers={{
                        popupopen() { setCurrentPoint(null); },
                        popupclose() { setCurrentPoint(null); },
                    }}
                />
            ))}
        </>
    );
};
