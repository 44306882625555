import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { useParams } from "react-router-dom";
import { currentParamState } from "../../../stores";

export function useSetCurrentParam() {
    const { group, floor, from } = useParams();
    const setCurrentParam = useSetRecoilState(currentParamState);
    useEffect(() => {
        setCurrentParam({ group, floor, from, to: undefined });
    }, [group, floor, from, setCurrentParam]);
}
