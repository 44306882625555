import { FC } from "react";
import { Bottom } from "./Bottom";
import { BoxButton } from "./BoxButton";
import { MuiQrCodeScannerIcon } from "../../../../../components/mui";
import { lang } from "../../../../../functions/lang";

export const CameraButton: FC<{
    onOpen: () => void;
}> = ({ onOpen: handleClick }) => (
    <Bottom>
        <div className="d-flex w-100 p-2 align-items-center">
            <div className="me-2">
                <BoxButton onClick={handleClick}>
                    <MuiQrCodeScannerIcon />
                </BoxButton>
            </div>
            <div style={{ color: "#000" }}>{lang('QR 読み込み')}</div>
        </div>
    </Bottom>
);
