import Button from '@mui/material/Button';
import { useSetRecoilState } from 'recoil';
import { openSelectMovingModalState } from '../../../stores';
import { lang } from '../../../functions/lang';


export const SelectMovingModalButton: React.FC = () => {
    const setOpen = useSetRecoilState(openSelectMovingModalState);
    return (
        <Button onClick={() => { setOpen(true); }}>
            {lang('条件指定')}
        </Button>
    );
};
