import { FC, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { distanceOfPointsEsQueryState } from "../../stores";
import { useDistanceOfPointsEsQuery } from "../../generated/graphql";
import { useParams } from "react-router-dom";


export const LoadDistanceEs: FC = () => {
    useLoadDistanceEs();
    return null;
};

function useLoadDistanceEs() {
    const { group, from } = useParams();
    const setValue = useSetRecoilState(distanceOfPointsEsQueryState);
    const { data, loading } = useDistanceOfPointsEsQuery({
        skip: !group || !from,
        variables: { id: group, from },
    });
    useEffect(() => {
        setValue({ data, loading });
    }, [data, loading, setValue]);
    return null;
};
