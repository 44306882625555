import { selector } from "recoil";
import { Point } from "../../generated/graphql";
import { currentFloorIdState } from "./currentFloorIdState";
import { allPointsState } from "./allPointsState";
import { _formatPoints } from "../functions";


export const floorPointsState = selector<Point[]>({
    key: "floorPointsState",
    get: ({ get }) => {
        const floorId = get(currentFloorIdState);
        const allPoints = get(allPointsState);
        const floorPoints = allPoints.filter((point: Point) => {
            return point.floor_id === floorId;
        });
        return _formatPoints(get, floorPoints) || [];
    }
});
