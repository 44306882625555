import { FC } from "react";
import { LoadigModal } from '../../../features/LoadingModal';
import { useLoading } from "../hooks/useLoading";
import { useFade } from "../../../hooks/useFade";

export const CustomLoadigModal: FC = () => {
    const loading = useLoading();
    const fade = useFade();
    return <LoadigModal open={fade === false && loading} />;
};
