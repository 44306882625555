import { selector } from "recoil";
import { floorGroupState } from "./floorGroupState";


export const naviStartNameState = selector<string>({
    key: "naviStartNameState",
    get: ({ get }) => {
        const floorGroup = get(floorGroupState);
        return floorGroup.navi_start_name || "...";
    },
});
