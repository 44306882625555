import { FC } from "react";

import { Layout } from "../features/MainLayout";
import { showQrCameraState } from "../stores";
import { useSetRecoilState } from "recoil";

export const App: FC = () => {
    const setShowQrCamera = useSetRecoilState(showQrCameraState);
    return (
        <Layout>
            <div className="pt-2">
                <div className="container">
                    <p>QRを読み込んでください</p>
                    <div
                        className="btn btn-primary"
                        onClick={() => {
                            setShowQrCamera(true);
                        }}
                    >
                        QR 読み込み
                    </div>
                </div>
            </div>
        </Layout>
    );
};
