import { FC } from "react";
import { LeafletEvent, Map as LeafletMap } from "leaflet";
import { useMapEvent } from 'react-leaflet';
import { ToPointControlView } from "./ToPointControlView";

export const ToPointControl: FC<{
    onClick: (map: LeafletMap) => void;
}> = ({ onClick }) => {
    const map = useMapEvent("update", (props: CustomLeafletEvent) => {
        if (props?.custom === "moveToPoint") {
            onClick(map);
        }
    });
    return <ToPointControlView position="bottomright" />;
};

type CustomLeafletEvent = LeafletEvent & { custom?: "moveFromPoint" | "moveToPoint"; };