import { FC, useState } from "react";
import ReactMarkdown from "react-markdown";

export const App: FC = () => {
    const [text, setText] = useState('');
    return (
        <div className="container pt-4">
            <textarea rows={10} className="form-control mb-4" value={text} onChange={(e) => { setText(e.target.value) }}></textarea>
            <ReactMarkdown linkTarget="_blank" components={{ p: 'p' }}>
                {formatMd(text)}
            </ReactMarkdown>
        </div>
    );
};

function formatMd(text: string): string {
    return text.replace(/\r*\n/g, '  \n')
}
