import { generatePath } from 'react-router-dom';
export function linkMap(search: linkMapProps): string {
    return generatePath('/map/:group/:floor/:from', {
        group: search.group || '',
        floor: search.floor || '',
        from: search.from || '',
    });
} 
export interface linkMapProps {
    group: string;
    floor: string;
    from: string;
}

