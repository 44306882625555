import { FC } from "react";
import { Link, useParams } from "react-router-dom";
import { lang } from "../../../functions/lang";
import { linkMap } from "../../../functions/links/linkMap";

export const LinkBack: FC = () => {
    const { group, from, floor } = useParams();
    const backLinkTo = linkMap({ group, floor, from });
    return (
        <Link to={backLinkTo} className="btn btn-primary">{lang('戻る')}</Link>
    );
};
