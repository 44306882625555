import { FC, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { toPointQueryState } from "../../stores";
import { useGetPointQuery } from "../../generated/graphql";
import { useParams } from "react-router-dom";


export const LoadToPoint: FC = () => {
    useLoadToPoint();
    return null;
};

function useLoadToPoint() {
    const { group, to } = useParams();
    const { data, loading } = useGetPointQuery({
        skip: !group || !to,
        variables: { group, id: to },
    });
    const setToPointQueryState = useSetRecoilState(toPointQueryState);
    useEffect(() => {
        setToPointQueryState({ data, loading });
    }, [data, loading, setToPointQueryState]);
};
