import { useRecoilValue, useSetRecoilState } from "recoil";
import { useNavigate, useParams } from "react-router-dom";
import { Map as LeafletMap } from "leaflet";
import { Point } from "../../../../generated/graphql";
import { ToPointControl } from "../../../../features/ToPointControl";
import { FromPointControl } from "../../../../features/FromPointControl";
import { getPointCenter } from "../../../../functions/map";
import { linkMapOrMapRoute } from '../../../../functions/links';
import { currentPointState, fromPointState, toPointState } from "../../../../stores";
import { FC } from "react";

export const CustomPointControl: FC = () => {
    const toPoint = useRecoilValue(toPointState);
    const fromPoint = useRecoilValue(fromPointState);
    const setCurrentPoint = useSetRecoilState(currentPointState);
    const { group, from, to } = useParams();
    const navigate = useNavigate();

    function onClickToPoint(map: LeafletMap): void {
        const center = getPointCenter(toPoint);
        if (!center) return;
        setCurrentPoint(toPoint);
        map.setView(center);
        const newFloor = toPoint.floor_id || "";
        setTimeout(() => {
            navigate(linkMapOrMapRoute({ group, floor: newFloor, from, to: to || '' }));
        });
    }

    function onFClickFromPoint(map: LeafletMap): void {
        const center = getPointCenter(fromPoint);
        if (!center) return;
        setCurrentPoint(fromPoint);
        map.setView(center);
        const newFloor = fromPoint.floor_id || "";
        setTimeout(() => {
            navigate(linkMapOrMapRoute({ group, floor: newFloor, from, to: to || '' }));
        });
    }


    return (
        <>
            <ToPointControl onClick={onClickToPoint} />
            <FromPointControl onClick={onFClickFromPoint} />
        </>
    );
}
