import { selector } from "recoil";
import { toPointQueryState } from "../atoms/toPointQueryState";
import { loadingFloorGroupState } from "./loadingFloorGroupState";


export const loadingToPointState = selector<boolean>({
    key: "loadingToPointState",
    get: ({ get }) => {
        const loading = get(loadingFloorGroupState);
        const data = get(toPointQueryState);
        return loading || data?.loading || false;
    }
});
