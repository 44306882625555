import { Control, DomUtil } from "leaflet";

/*
 * @class Control.Scale
 * @aka L.Control.Scale
 * @inherits Control
 *
 * A simple scale control that shows the scale of the current center of screen in metric (m/km) and imperial (mi/ft) systems. Extends `Control`.
 *
 * @example
 *
 * ```js
 * L.control.scale().addTo(map);
 * ```
 */

export const Scale = Control.extend({
    // @section
    // @aka Control.Scale options
    options: {
        position: "bottomleft",

        // @option maxWidth: Number = 100
        // Maximum width of the control in pixels. The width is set dynamically to show round values (e.g. 100, 200, 500).
        maxWidth: 100,

        // @option metric: Boolean = True
        // Whether to show the metric scale line (m/km).
        metric: true,

        // @option imperial: Boolean = True
        // Whether to show the imperial scale line (mi/ft).
        imperial: false,

        // @option updateWhenIdle: Boolean = false
        // If `true`, the control is updated on [`moveend`](#map-moveend), otherwise it's always up-to-date (updated on [`move`](#map-move)).

        ppm: 100,
    },

    onAdd(map) {
        const className = "leaflet-control-scale";
        const container = DomUtil.create("div", className);
        const { options } = this;

        this._addScales(options, `${className}-line`, container);

        map.on(options.updateWhenIdle ? "moveend" : "move", this._update, this);
        map.whenReady(this._update, this);

        return container;
    },

    onRemove(map) {
        map.off(
            this.options.updateWhenIdle ? "moveend" : "move",
            this._update,
            this
        );
    },

    _addScales(options, className, container) {
        if (options.metric) {
            this._mScale = DomUtil.create("div", className, container);
        }
        if (options.imperial) {
            this._iScale = DomUtil.create("div", className, container);
        }
    },

    _update() {
        const map = this._map;
        const y = map.getSize().y / 2;

        const { ppm } = this.options;

        let maxMeters = map.distance(
            map.containerPointToLatLng([0, y]),
            map.containerPointToLatLng([this.options.maxWidth, y])
        );

        maxMeters = ppm > 0 ? maxMeters / ppm : 0;

        this._updateScales(maxMeters);
    },

    _updateScales(maxMeters) {
        if (this.options.metric && maxMeters) {
            this._updateMetric(maxMeters);
        }
        if (this.options.imperial && maxMeters) {
            this._updateImperial(maxMeters);
        }
    },

    _updateMetric(maxMeters) {
        const meters = this._getRoundNum(maxMeters);
        const label = `${meters} m`;
        // 	label = meters < 100 ? meters + ' cm' : (meters / 100) + ' m';

        this._updateScale(this._mScale, label, meters / maxMeters);
    },

    _updateImperial(maxMeters) {
        const maxFeet = maxMeters * 3.2808399;
        let maxMiles;
        let miles;
        let feet;

        if (maxFeet > 5280) {
            maxMiles = maxFeet / 5280;
            miles = this._getRoundNum(maxMiles);
            this._updateScale(this._iScale, `${miles} mi`, miles / maxMiles);
        } else {
            feet = this._getRoundNum(maxFeet);
            this._updateScale(this._iScale, `${feet} ft`, feet / maxFeet);
        }
    },

    _updateScale(scale, text, ratio) {
        scale.style.width = `${Math.round(this.options.maxWidth * ratio)}px`;
        scale.innerHTML = text;
    },

    _getRoundNum(num) {
        const pow10 = Math.pow(10, `${Math.floor(num)}`.length - 1);
        let d = num / pow10;

        switch (true) {
            case d >= 10:
                d = 10;
                break;
            case d >= 5:
                d = 5;
                break;
            case d >= 3:
                d = 3;
                break;
            case d >= 2:
                d = 2;
                break;
            default:
                d = 1;
                break;
        }

        return pow10 * d;
    },
});

// @factory L.control.scale(options?: Control.Scale options)
// Creates an scale control with the given options.
function scale(options) {
    return new Scale(options);
}
