import { ComponentProps, FC } from "react";
import { Form, useLocation, useParams } from "react-router-dom";
import { linkSearch } from '../../../functions/links/linkSearch';
import { sendLogPublicWhenSearchStart } from "../../../features/logPublic/sendLogPublicWhenSearchStart";


export const SearchKeyword: FC = () => {
    const params = useParams();
    const { group, floor, from } = params;
    const location = useLocation();
    const link = linkSearch({ group, floor, from });

    const handleSubmit: ComponentProps<typeof Form>["onSubmit"] = (e) => {
        const target = e.currentTarget;
        const search_keyword = (target.querySelector('[name=q]') as HTMLInputElement).value || '';
        sendLogPublicWhenSearchStart({ location, params, search_keyword });
    }

    return (
        <Form method="get" onSubmit={handleSubmit} action={link}>
            <div className="row g-1">
                <div className="col">
                    <div className="input-group">
                        <input
                            name="q"
                            type="text"
                            className="form-control"
                            placeholder="ワード検索" />
                        <button type="submit" className="btn btn-primary">
                            検索
                        </button>
                    </div>
                </div>
            </div>
        </Form>
    );
};
