import { selector } from "recoil";
import { fromPointQueryState } from "../atoms/fromPointQueryState";
import { loadingFloorGroupState } from "./loadingFloorGroupState";


export const loadingFromPointState = selector<boolean>({
    key: "loadingFromPointState",
    get: ({ get }) => {
        const loading = get(loadingFloorGroupState);
        const data = get(fromPointQueryState);
        return loading || data?.loading || false;
    }
});
