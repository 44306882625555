import { FC } from "react";
import { useRecoilValue } from "recoil";
import { showFloorState } from "../../../stores";
import { MapFloorTab } from "./MapFloorTab";

// フロア選択

export const BorderAndMapFloorTab: FC = () => {
    const isShow = useRecoilValue(showFloorState);
    if (!isShow) return null;
    return (
        <>
            <div className="border-top" />
            <div className="container my-2">
                <MapFloorTab />
            </div>
        </>
    );
};
