import { CSSProperties, FC, ReactNode } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { CRS as LeafletCRS, LatLngBoundsExpression } from "leaflet";
import { MapContainer } from "react-leaflet";
import { currentPointState, floorZoomConfigsState, mapBearingConfigState, mapBearingState } from "../../../stores";
import { MyMapContainer } from "./MyMapContainer";

export const CustomMapContainer: FC<{
    children: ReactNode;
    center: Parameters<typeof MapContainer>[0]["center"];
    maxBounds: LatLngBoundsExpression;
}> = ({ children, center, maxBounds }) => {
    const setCurrentPoint = useSetRecoilState(currentPointState);
    const { minZoom, maxZoom, startZoom } = useRecoilValue(floorZoomConfigsState);
    const { is_use } = useRecoilValue(mapBearingConfigState);
    const mapBaering = useRecoilValue(mapBearingState);
    return (
        <MyMapContainer
            doubleClickZoom={false}
            minZoom={minZoom}
            maxZoom={maxZoom}
            maxBounds={maxBounds}
            crs={LeafletCRS.Simple}
            style={style}
            center={center}
            zoom={startZoom}
            attributionControl={false}
            inertia={false}
            whenReady={() => {
                setCurrentPoint(null);
            }}
            dragging={false}
            tap={false}
            scrollWheelZoom={false}
            zoomSnap={0}
            zoomDelta={0.5}
            wheelPxPerZoomLevel={120}
            rotate={is_use}
            bearing={mapBaering}
            rotateControl={false}
        >

            {children}
        </MyMapContainer>
    );
};

export const style = {
    width: "100%",
    height: "60vh",
    borderTop: "1px solid #dee2e6",
    borderBottom: "1px solid #dee2e6",
    background: "#999",
} as CSSProperties;