import { ComponentProps, FC } from "react";
import { useRecoilValue } from "recoil";
import { Form, useLocation, useParams } from "react-router-dom";
import { Category } from "../../../generated/graphql";
import { linkSearch } from '../../../functions/links/linkSearch';
import {
    categoriesState,
    floorGroupState,
    floorState,
    fromPointState
} from "../../../stores";
import { sendLogPublicWhenSearchStart } from "../../../features/logPublic/sendLogPublicWhenSearchStart";
import { IfBlock } from "../../../components/IfBlock";

export const CategoryList: FC = () => {
    const params = useParams();
    const location = useLocation();
    const categories = useRecoilValue(categoriesState);
    const floorGroup = useRecoilValue(floorGroupState);
    const floor = useRecoilValue(floorState);
    const fromPoint = useRecoilValue(fromPointState);

    const link = linkSearch({
        group: floorGroup.id || "",
        floor: floor.id || "",
        from: fromPoint.id || "",
    });

    const handleSubmit: ComponentProps<typeof Form>["onSubmit"] = (e) => {
        const search_category = (e.nativeEvent as SubmitEvent).submitter.textContent;
        sendLogPublicWhenSearchStart({ location, params, search_category });
    }

    return (
        <IfBlock visible={categories.length > 0} elseElement={<CategoryListEmpty />}>
            <Form method="get" onSubmit={handleSubmit} action={link}>
                {categories.map((category: Category, i: number) => (
                    <CategoryCardRow key={i} title={category.name} id={category.id} />
                ))}
            </Form>
        </IfBlock>
    );
};

const CategoryListEmpty: FC = () => (
    <div className="border-top py-2">
        <div className="text-start btn text-decoration-none rounded-0">
            -
        </div>
    </div>
)

const CategoryCardRow: FC<{
    title: string,
    id: string,
}> = ({ title, id }) => (
    <div className="border-top py-2">
        <button type="submit" name="q_category" value={id} className="text-start btn btn-link text-decoration-none rounded-0">
            {title}
        </button>
    </div>
);
