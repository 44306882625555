import { selector } from "recoil";
import { routeSearchState } from "./routeSearchState";


export const routeSearchNotFoundState = selector<boolean>({
    key: "routeSearchNotFoundState",
    get: ({ get }) => {
        return get(routeSearchState).isNotFound || false;
    },
});
