import { selector } from "recoil";
import { getNumber } from "../../functions/utils";
import { floorState } from "./floorState";


export const floorPpmState = selector<number>({
    key: "floorPpmState",
    get: ({ get }) => {
        const { ppm } = get(floorState) || {};
        return getNumber(ppm, 0);
    },
});
