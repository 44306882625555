import { FC } from "react";
import { Layout } from "../../../features/MainLayout";
import { MapForMap } from "../components/MapForMap";
import { BorderAndMapFloorTab } from "../components/BorderAndMapFloorTab";
import { IfShowCategory } from "../../../features/IfShowCategory";
import { SelectCategory } from "../components/SelectCategory";
import { IfShowMarker } from "../../../features/IfShowMarker";
import { MarkerPopupCard } from "../features/MarkerPopupCard";
import { SearchKeyword } from "../features/SearchKeyword";

export const PageView: FC = () => {
    return (
        <Layout>
            {/* 検索情報 */}
            <div className="container mt-2">
                <div className="mb-2">
                    <SearchKeyword />
                </div>
                <IfShowCategory>
                    <div className="mb-2">
                        <SelectCategory />
                    </div>
                </IfShowCategory>
            </div>

            {/* 目的地(選択あり) */}
            <IfShowMarker>
                <div className="border-top" />
                <div className="container my-2">
                    <MarkerPopupCard />
                </div>
            </IfShowMarker>

            {/* フロア選択 */}
            <BorderAndMapFloorTab />

            <div className="mb-4">
                <MapForMap />
            </div>
        </Layout>
    );
};
