import { selector } from "recoil";
import { Point } from "../../generated/graphql";
import {
    floorPointsState,
    fromPointState,
    toPointState,
} from "..";


export const destinationPointsState = selector<Point[]>({
    key: "destinationPointsState",
    get: ({ get }) => {
        const points = get(floorPointsState);
        const fromPoint = get(fromPointState);
        const toPoint = get(toPointState);
        return points.filter((point) => {
            if (!point) return false;
            if (String(point?.id) === String(fromPoint?.id)) return false;
            if (String(point?.id) === String(toPoint?.id)) return false;
            if (point?.is_qr === true) return false;
            return point?.is_destination || false;
        });
    }
});
