import { CSSProperties, FC } from "react";

export const NubmerIcon: FC<{
    color: string;
    fillColor: string;
    tooltip: string;
    width: number;
    height: number;
    size: number;
}> = ({ color, fillColor, tooltip, width, height, size }) => (
    <div style={{
        ...styleContainer,
        width,
        height,
    }}>
        <div style={{
            ...stylePin,
            background: color,
        }} />
        <div style={{
            ...styleBody,
            background: fillColor,
            color: color,
            minWidth: size,
            height,
            borderColor: color,
            fontSize: size / 1.8,
        }}>
            <div className="p-1">{tooltip}</div>
        </div>
    </div>
);

const styleBody = {
    borderRadius: 25,
    borderStyle: "solid",
    borderWidth: "thin",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
} as CSSProperties;

const styleContainer = {
    display: "flex",
    justifyContent: "center",
} as CSSProperties;

const stylePin = {
    position: "absolute",
    bottom: -2,
    width: 6,
    height: 6,
    left: "calc(50% - 3px)",
    transform: "rotate(45deg)",
    zIndex: -1,
} as CSSProperties;