import axios from "axios";
import { LoaderProps } from "../MainApp/features/loader";


export function sendLogPublic({ request, params }: LoaderProps) {
    const url = new URL(request.url);
    const query = new URLSearchParams(url.search);
    const { group, floor, from, to, code } = params;
    const q = query.get('q');
    const search = url.search;
    const pathname = url.pathname;

    const event_name = '';
    const search_keyword = '';
    const search_total = '';
    const search_category = '';
    const search_floor = '';

    axios.post(import.meta.env.VITE_APP_LOG_URL || "", {
        floor_group_id: group,
        from_point_id: from,
        to_point_id: to,
        floor_id: floor,
        search: search,
        pathname: pathname,
        event_name,
        search_keyword,
        search_category,
        search_floor,
        search_total,
        code,
        q: q,
    });
}
