import { CSSProperties, FC, useRef } from "react";

export const LogoAnime: FC<{
    url: string;
    onEnded: () => void;
}> = ({ url, onEnded }) => {
    const refVideo = useRef<HTMLVideoElement>(null);
    return (
        <div style={styles.wrap}>
            <div style={styles.videoBg}>
                <video
                    style={styles.videoBg__video}
                    ref={refVideo}
                    src={url}
                    muted
                    autoPlay
                    onLoadedData={() => {
                        startVideo(refVideo.current);
                    }}
                    onEnded={onEnded}
                    playsInline />
            </div>
        </div>
    );
};

function startVideo(video: HTMLVideoElement | null) {
    if (!video) return;
    if (!video.played) return;
    if (video.played.length > 0) return;
    video.play();
    setTimeout(() => {
        startVideo(video);
    }, 100);
}

const styles = {
    wrap: {
        position: "relative",
        height: "100vh",
    } as CSSProperties,
    videoBg: {
        position: "relative",
        height: "100%",
        background: "#fff",
        overflow: "hidden",
    } as CSSProperties,
    videoBg__video: {
        display: "block",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100vw",
        height: "60vw",
        filter: "drop-shadow(0px 0px #000)",
    } as CSSProperties,
};
