import { generatePath } from 'react-router-dom';

export function linkSearch({ group, floor, from }: linkSearchProps): string {
    return generatePath('/search/:group/:floor/:from', {
        group: group || '',
        floor: floor || '',
        from: from || '',
    });
}
interface linkSearchProps {
    group: string;
    floor: string;
    from: string;
}

