import { selector } from "recoil";
import { Floor } from "../../generated/graphql";
import { getNumber } from "../../functions/utils";
import { getZoomRange } from "../../functions/map";
import { floorState } from "./floorState";


export const floorZoomConfigsState = selector<{
    minZoom: number;
    maxZoom: number;
    startZoom: number;
}>({
    key: "floorZoomConfigsState",
    get: ({ get }) => {
        const floor = get(floorState) || {} as Floor;
        const { minZoom, maxZoom } = getZoomRange(floor);
        const startZoom = getNumber(floor.zoom_start, -1);
        return { minZoom, maxZoom, startZoom };
    },
});
