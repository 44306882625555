import { selector } from "recoil";
import { floorGroupState } from "./floorGroupState";


export const isShowScaleState = selector<boolean>({
    key: "isShowScaleState",
    get: ({ get }) => {
        const floorGroup = get(floorGroupState);
        return (floorGroup?.is_show_scale && floorGroup?.is_show_route_meter) || false;
    },
});
