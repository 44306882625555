import { FC } from "react";
import { useRecoilValue } from "recoil";
import { LatLngTuple } from "leaflet";
import { Polyline } from "react-leaflet";
import { getPointCenter } from "../../../../functions/map";
import {
    floorlineEndPointState,
    floorlineStartPointState, floorRoutesPosisiontsState,
    floorState,
    fromPointState,
    toPointState
} from "../../../../stores";
import { CustomMiniCircleMarker } from "./CustomMiniCircleMarker";

export const Routes: FC = () => {
    const floorLineStartPoint = useRecoilValue(floorlineStartPointState);
    const floorLineEndPoint = useRecoilValue(floorlineEndPointState);
    const fromPoint = useRecoilValue(fromPointState);
    const toPoint = useRecoilValue(toPointState);
    const routes = useRecoilValue(floorRoutesPosisiontsState);
    const floor = useRecoilValue(floorState);

    // end hooks
    const pos1 = getPointCenter(floorLineStartPoint);
    const pos2 = getPointCenter(floorLineEndPoint);

    const pos01 = getPointCenter(fromPoint);
    const pos02 = getPointCenter(toPoint);

    return (
        <>
            {routes.map((route: LatLngTuple[], i: number) => {
                return <Polyline key={i} positions={route} dashArray="1 10" weight={5} />;
            })}
            {pos1 && <CustomMiniCircleMarker center={pos1} />}
            {pos2 && <CustomMiniCircleMarker center={pos2} />}
            {floor.id && floor.id === fromPoint.floor_id && pos01 && pos1 && <Polyline weight={1} positions={[pos01, pos1]} />}
            {floor.id && floor.id === toPoint.floor_id && pos02 && pos2 && <Polyline weight={1} positions={[pos02, pos2]} />}
        </>
    );
};
