import { selector } from "recoil";
import { Floor } from "../../generated/graphql";
import { floorsState } from "./floorsState";
import { currentFloorIdState } from "./currentFloorIdState";


export const floorState = selector<Floor>({
    key: "floorState",
    get: ({ get }) => {
        const floorId = get(currentFloorIdState);
        const floors = get(floorsState);
        for (const floor of floors) {
            if (floor.id === floorId) {
                return floor;
            }
        }
        return {} as Floor;
    }
});
