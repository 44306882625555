import { selector } from "recoil";
import { Category } from "../../generated/graphql";
import { floorGroupState } from "./floorGroupState";


export const categoriesState = selector<Category[]>({
    key: "categoriesState",
    get: ({ get }) => {
        const floorGroup = get(floorGroupState);
        const categories = (floorGroup?.categories || []) as Category[];
        return categories.filter(a => a.name !== '');
    },
});
