import { FC, CSSProperties } from "react";
import ReactDOMServer from "react-dom/server";
import { Marker } from "react-leaflet";
import { DivIcon as LeafletDivIcon } from "leaflet";
import { MuiArrowRightAltIcon } from "../../../components/mui";

// graphql
import { Point, RouteSearchResult } from "../../../generated/graphql";

// local
import { getPointCenter } from "../../../functions/map";

const areaWidth = 120;
const areaHeight = 120;

function arrowMarkerIcon(point: Point, routeSearch: RouteSearchResult, bearing: number) {
    if (!routeSearch) return null;

    const qrInfo = routeSearch.qrInfo || "";
    const qrAngle = routeSearch.qrAngle || "";

    if (qrInfo == "" || qrAngle == "") {
        return null;
    }

    // 地図の回転を考慮
    const qrAngle2 = mergeBearAngle(qrAngle, bearing);

    switch (qrAngle2) {
        case "1":
            return arrowMarkerIconTop(point, qrInfo);
        case "2":
            return arrowMarkerIconRight(point, qrInfo);
        case "3":
            return arrowMarkerIconBottom(point, qrInfo);
        case "4":
            return arrowMarkerIconLeft(point, qrInfo);

        case "5":
            return arrowMarkerIconTopRight(point, qrInfo);
        case "6":
            return arrowMarkerIconBottomRight(point, qrInfo);
        case "7":
            return arrowMarkerIconBottomLeft(point, qrInfo);
        case "8":
            return arrowMarkerIconTopLeft(point, qrInfo);

        default:
            return null;
    }
}

function mergeBearAngle(qrAngle: string, bearing: number): string {
    const bearingAngle = Math.round(bearing / 45);
    const rotateTable = ["1", "5", "2", "6", "3", "7", "4", "8"];
    const list = {
        "1": rotateTable[(0 + bearingAngle) % 8] ?? [],
        "5": rotateTable[(1 + bearingAngle) % 8] ?? [],
        "2": rotateTable[(2 + bearingAngle) % 8] ?? [],
        "6": rotateTable[(3 + bearingAngle) % 8] ?? [],
        "3": rotateTable[(4 + bearingAngle) % 8] ?? [],
        "7": rotateTable[(5 + bearingAngle) % 8] ?? [],
        "4": rotateTable[(6 + bearingAngle) % 8] ?? [],
        "8": rotateTable[(7 + bearingAngle) % 8] ?? [],
    };
    return list[qrAngle] ?? qrAngle;
}

function arrowMarkerIconTop(point: Point, info: string) {
    return new LeafletDivIcon({
        className: "cst-dummy",
        html: ReactDOMServer.renderToString(
            <ArrorMarkerIconBase angle="top" info={info} />
        ),
        iconAnchor: [areaWidth / 2, areaHeight + 40],
    });
}
function arrowMarkerIconRight(point: Point, info: string) {
    return new LeafletDivIcon({
        className: "cst-dummy",
        html: ReactDOMServer.renderToString(
            <ArrorMarkerIconBase angle="right" info={info} />
        ),
        iconAnchor: [0, areaHeight / 2],
    });
}
function arrowMarkerIconBottom(point: Point, info: string) {
    return new LeafletDivIcon({
        className: "cst-dummy",
        html: ReactDOMServer.renderToString(
            <ArrorMarkerIconBase angle="bottom" info={info} />
        ),
        iconAnchor: [areaWidth / 2, 0],
    });
}
function arrowMarkerIconLeft(point: Point, info: string) {
    return new LeafletDivIcon({
        className: "cst-dummy",
        html: ReactDOMServer.renderToString(
            <ArrorMarkerIconBase angle="left" info={info} />
        ),
        iconAnchor: [areaWidth, areaHeight / 2],
    });
}

function arrowMarkerIconTopLeft(point: Point, info: string) {
    return new LeafletDivIcon({
        className: "cst-dummy",
        html: ReactDOMServer.renderToString(
            <ArrorMarkerIconBase angle="topleft" info={info} />
        ),
        iconAnchor: [areaWidth / 2 + 20, areaHeight + 40],
    });
}
function arrowMarkerIconBottomLeft(point: Point, info: string) {
    return new LeafletDivIcon({
        className: "cst-dummy",
        html: ReactDOMServer.renderToString(
            <ArrorMarkerIconBase angle="bottomleft" info={info} />
        ),
        iconAnchor: [areaWidth / 2 + 20, 0],
    });
}
function arrowMarkerIconTopRight(point: Point, info: string) {
    return new LeafletDivIcon({
        className: "cst-dummy",
        html: ReactDOMServer.renderToString(
            <ArrorMarkerIconBase angle="topright" info={info} />
        ),
        iconAnchor: [20, areaHeight + 40],
    });
}
function arrowMarkerIconBottomRight(point: Point, info: string) {
    return new LeafletDivIcon({
        className: "cst-dummy",
        html: ReactDOMServer.renderToString(
            <ArrorMarkerIconBase angle="bottomright" info={info} />
        ),
        iconAnchor: [areaWidth / 2 - 20, 0],
    });
}

interface ArrorMarkerIconBaseProps {
    angle:
    | "left"
    | "right"
    | "top"
    | "bottom"
    | "topleft"
    | "topright"
    | "bottomleft"
    | "bottomright";
    info: string;
}
const ArrorMarkerIconBase: FC<ArrorMarkerIconBaseProps> = ({ angle, info }) => {
    const style = {
        color: "red",
        width: areaWidth,
        height: areaHeight,
        textAlign: "center",
    } as CSSProperties;
    const style2 = {
        fontSize: 50,
    } as CSSProperties;
    const style3 = {
        fontSize: 25,
        background: "#fff",
        borderRadius: 2,
        width: "auto",
        padding: 5,
        height: 30,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid red",
        whiteSpace: "nowrap",
    } as CSSProperties;

    switch (angle) {
        case "right":
            return (
                <div style={style} className="d-flex align-items-center">
                    <MuiArrowRightAltIcon style={style2} />
                    <div style={style3}>{info}</div>
                </div>
            );

        case "left":
            return (
                <div style={style} className="d-flex align-items-center justify-content-end">
                    <div style={style3}>{info}</div>
                    <div style={{ transform: "rotate(180deg)" }}>
                        <MuiArrowRightAltIcon style={style2} />
                    </div>
                </div>
            );

        case "top":
            return (
                <div
                    style={style}
                    className="d-flex align-items-center flex-column justify-content-end"
                >
                    <div style={style3}>{info}</div>
                    <div style={{ transform: "rotate(-90deg)" }}>
                        <MuiArrowRightAltIcon style={style2} />
                    </div>
                </div>
            );

        case "bottom":
            return (
                <div
                    style={style}
                    className="d-flex align-items-center flex-column"
                >
                    <div style={{ transform: "rotate(90deg)" }}>
                        <MuiArrowRightAltIcon style={style2} />
                    </div>
                    <div style={style3}>{info}</div>
                </div>
            );

        case "topright":
            return (
                <div
                    style={style}
                    className="d-flex align-items-center flex-column justify-content-end"
                >
                    <div style={style3}>{info}</div>
                    <div style={{ transform: "rotate(-45deg)" }}>
                        <MuiArrowRightAltIcon style={style2} />
                    </div>
                </div>
            );

        case "topleft":
            return (
                <div
                    style={style}
                    className="d-flex align-items-center flex-column justify-content-end"
                >
                    <div style={style3}>{info}</div>
                    <div style={{ transform: "rotate(-135deg)" }}>
                        <MuiArrowRightAltIcon style={style2} />
                    </div>
                </div>
            );

        case "bottomright":
            return (
                <div
                    style={style}
                    className="d-flex align-items-center flex-column"
                >
                    <div style={{ transform: "rotate(45deg)" }}>
                        <MuiArrowRightAltIcon style={style2} />
                    </div>
                    <div style={style3}>{info}</div>
                </div>
            );

        case "bottomleft":
            return (
                <div
                    style={style}
                    className="d-flex align-items-center flex-column"
                >
                    <div style={{ transform: "rotate(135deg)" }}>
                        <MuiArrowRightAltIcon style={style2} />
                    </div>
                    <div style={style3}>{info}</div>
                </div>
            );
        default:
            return null;
    }
};

export const ArrowMarker: FC<{
    point: Point;
    routeSearch: RouteSearchResult;
    bearing: number;
}> = ({ point, routeSearch, bearing }) => {
    const center = getPointCenter(point);
    const icon = arrowMarkerIcon(point, routeSearch, bearing);
    if (!icon) return null;
    if (!center) return null;
    return <Marker icon={icon} position={center} />;
};
