import { selector } from "recoil";
import { fromPointState } from "./fromPointState";
import { toPointState } from "./toPointState";


export const SameFloorState = selector<boolean>({
    key: "SameFloorState",
    get: ({ get }) => {
        const toPoint = get(toPointState);
        const fromPoint = get(fromPointState);
        return (fromPoint?.floor_id == toPoint?.floor_id);
    }
});
