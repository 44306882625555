import { FC } from "react";
import { DestinationCardsContainer } from "../features/DestinationCardsContainer";
import { SearchKeyword } from "../features/SearchKeyword";
import { LinkBack } from "../features/LinkBack";
import { IfShowFloor } from "../../../features/IfShowFloor";
import { SelectFloor } from "../components/SelectFloor";
import { IfShowCategory } from "../../../features/IfShowCategory";
import { SelectCategory } from "../components/SelectCategory";
import { Form, useLocation, useParams, useSearchParams, useSubmit } from "react-router-dom";

export const PageView: FC = () => {
    const location = useLocation();
    const link = location.pathname;
    const [query] = useSearchParams();
    const q_category = query.get('q_category') || '';
    const q_floor = query.get('q_floor') || '';
    return (
        <>
            <div className="d-flex justify-content-start mb-2 justify-content-between">
                <LinkBack />
            </div>
            <Form method="get" action={link}>
                <input type="hidden" name="q_category" value={q_category} />
                <input type="hidden" name="q_floor" value={q_floor} />
                <div className="row g-2">
                    <div className="col-12">
                        <SearchKeyword />
                    </div>
                    <IfShowFloor>
                        <div className="col">
                            <SelectFloor />
                        </div>
                    </IfShowFloor>
                    <IfShowCategory>
                        <div className="col">
                            <SelectCategory />
                        </div>
                    </IfShowCategory>
                    <div className="col-12">
                        <DestinationCardsContainer /> {/* 検索処理 */}
                    </div>
                </div>
            </Form>
        </>
    );



}