import { FC } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Marker } from "react-leaflet";
import { DestinationPopup } from "../../../../components/DestinationPopup";
import { getPointCenter } from "../../../../functions/map";
import { createGoalMapIcon } from "../../../../plugins/leaflet/MuiIcon";
import {
    currentPointState, fromPointState,
    routeSearchArrivedState,
    toPointState
} from "../../../../stores";
import { DestinationPopupBody } from "../../../../features/DestinationPopupBody";

export const ToMarker: FC = () => {
    const setCurrentPoint = useSetRecoilState(currentPointState);
    const fromPoint = useRecoilValue(fromPointState);
    const toPoint = useRecoilValue(toPointState);
    const arrived = useRecoilValue(routeSearchArrivedState);

    // end hooks
    const center = getPointCenter(toPoint);

    if (!center) return null;
    if (fromPoint.id === toPoint.id && arrived) return null;

    return (
        <Marker
            zIndexOffset={2000}
            position={center}
            icon={createGoalMapIcon("red")}
            eventHandlers={{
                popupopen: () => { setCurrentPoint(toPoint); },
                popupclose: () => { setCurrentPoint(null); },
            }}
        >
            {toPoint.is_destination && (
                <DestinationPopup>
                    <DestinationPopupBody destination={toPoint.destination} />
                </DestinationPopup>
            )}
        </Marker>
    );
};
