import { FC, useEffect, useState } from "react";
import { useMapEvent } from "react-leaflet";
import { useRecoilValue } from "recoil";
import {
    destinationPointsState,
    leafletZoomNameHideState
} from "../../stores";
import { MarkerDestinationName } from "./MarkerDestinationName";


export const MarkerDestinationNameGroup: FC = () => {
    const destinationPoints = useRecoilValue(destinationPointsState);

    // 非表示
    const zoomNameHide = useRecoilValue(leafletZoomNameHideState);
    const [nameHide, setNameHide] = useState(true);
    const map = useMapEvent('zoomend', () => {
        setNameHide(map.getZoom() <= zoomNameHide);
    });
    useEffect(() => {
        setNameHide(map.getZoom() <= zoomNameHide);
    }, [map, zoomNameHide]);

    if (nameHide) return null;
    return (
        <>
            {destinationPoints.map((point, i: number) => (
                <MarkerDestinationName key={i} destinationPoint={point} />
            ))}
        </>
    );
};
