import { FC, CSSProperties } from "react";

export const Center: FC<{
    title: string
}> = ({ title }) => (
    <div style={style}>{title}</div>
)

const style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    width: "100%",
} as CSSProperties;