import { FC } from "react";
import { CSSProperties } from "react";

const style = {
    position: "absolute",
    width: "100%",
    height: "100%",
} as CSSProperties;

export const Fukidashi: FC<{
    color: string;
}> = ({ color }) => (
    <div style={style}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{ width: "100%", height: "100%" }}
            viewBox="0 0 24 24"
            fill={color}
        >
            <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z" />
        </svg>
    </div>
);
