import { FC } from "react";
import { CustomCardImgTop } from "./CustomCardImgTop";
import { DetailButton } from "./DetailButton";
import { ChangeToPointButton } from "./ChangeToPointButton";
import { IfShowCategory } from "../../../features/IfShowCategory";
import { IfShowFloor } from "../../../features/IfShowFloor";
import { IfShowDistance } from "../../../features/IfShowDistance";
import { IfBlock } from "../../../components/IfBlock";
import { MuiElevatorIcon, MuiEscalatorIcon, MuiStairsIcon } from '../../../components/mui';
import { IfShowRouteMeter } from "../../../features/IfShowRouteMeter";
import { IfCanEv } from "../../../features/IfCanEv";
import { IfCanEs } from "../../../features/IfCanEs";
import { IfCanStep } from "../../../features/IfCanStep";
import { lang } from "../../../functions/lang";

export const DestinationCard: FC<{
    sameFloor: boolean;
    distanceCost: number;
    distanceVisible: boolean;
    name: string;
    imageUrl: string;
    pointId: string;
    detailUrl: string;
    categoryName: string;
    floorName: string;
}> = ({ categoryName, floorName, sameFloor, distanceCost, distanceVisible, name, imageUrl, pointId, detailUrl }) => {
    return (
        <div className={`card h-100`}>
            <IfBlock visible={!!imageUrl}>
                <CustomCardImgTop src={imageUrl} />
            </IfBlock>
            <div className="card-body">
                <div className="d-flex justify-content-between mb-2">
                    <IfShowCategory>
                        <small>{categoryName}</small>
                    </IfShowCategory>
                    <IfShowFloor>
                        <small>{floorName}</small>
                    </IfShowFloor>
                </div>
                <div className="card-title">
                    <h6>{name}</h6>
                </div>

                <div className="row g-2">
                    <IfBlock visible={!!detailUrl}>
                        <div className="col-12">
                            <DetailButton href={detailUrl} />
                        </div>
                    </IfBlock>
                    <div className="col-6">
                    </div>
                    <div className="col-6">
                        <ChangeToPointButton toPointId={pointId} name={name} />
                    </div>
                </div>
                <IfShowDistance sameFloor={sameFloor}>
                    <hr className="mb-2" />
                    <DistanceBlock
                        sameFloor={sameFloor}
                        distanceCost={distanceCost}
                        distanceVisible={distanceVisible}
                    />
                </IfShowDistance>
            </div>
        </div >
    );
};

const DistanceBlock: FC<{
    sameFloor: boolean;
    distanceCost: number;
    distanceVisible: boolean;
}> = ({ sameFloor, distanceCost, distanceVisible }) => {
    return (
        <div className="row gx-1">
            <div className="col">
                <div className="row">
                    <div className="col">
                        <IfShowRouteMeter>
                            <IfBlock
                                visible={distanceVisible}
                                elseElement={<>{lang('経路が存在しません。')}</>}
                            >
                                {distanceCost} m
                            </IfBlock>
                        </IfShowRouteMeter>
                    </div>
                    <div className="col-auto">
                        <IfBlock visible={sameFloor === false}>
                            <IfCanEv>
                                <span title="エレベータ"> <MuiElevatorIcon /> </span>
                            </IfCanEv>
                            <IfCanEs>
                                <span title="エスカレータ"> <MuiEscalatorIcon /> </span>
                            </IfCanEs>
                            <IfCanStep>
                                <span title="階段"> <MuiStairsIcon /> </span>
                            </IfCanStep>
                        </IfBlock>
                    </div>
                </div>
            </div>
        </div>
    );
};
