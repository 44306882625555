import { FC } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Marker } from "react-leaflet";
import { getPointCenter } from "../functions/map";
import { currentPointState, fromPointState, isFromPointInFloorState } from "../stores";
import { createStartMapIcon } from "../plugins/leaflet/MuiIcon";
import { DestinationPopup } from '../components/DestinationPopup';
import { DestinationPopupBody } from "./DestinationPopupBody";

export const FromMarker: FC = () => {
    const setCurrentPoint = useSetRecoilState(currentPointState);
    const fromPoint = useRecoilValue(fromPointState);
    const center = getPointCenter(fromPoint);

    if (!fromPoint) return null;
    if (!center) return null;
    return (
        <Marker
            zIndexOffset={2000}
            position={center}
            icon={createStartMapIcon("blue")}
            eventHandlers={{
                popupopen: () => { setCurrentPoint(fromPoint) },
                popupclose: () => { setCurrentPoint(null) },
            }}
        >
            {fromPoint.is_destination && (
                <DestinationPopup>
                    <DestinationPopupBody destination={fromPoint.destination} />
                </DestinationPopup>
            )}
        </Marker >
    );
};