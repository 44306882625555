import { FC, useEffect, useState } from "react";
import { useMapEvent } from "react-leaflet";
import { useRecoilValue } from "recoil";
import {
    destinationPointsState,
    leafletZoomIconHideState
} from "../../stores";
import { MarkerDestinationIcon } from "./MarkerDestinationIcon";


export const MarkerDestinationIconGroup: FC = () => {
    const destinationPoints = useRecoilValue(destinationPointsState);
    const zoomIconHide = useRecoilValue(leafletZoomIconHideState);
    const [iconHide, setIconHide] = useState(true);
    const map = useMapEvent('zoomend', (params) => {
        setIconHide(map.getZoom() <= zoomIconHide);
    });
    useEffect(() => {
        setIconHide(map.getZoom() <= zoomIconHide);
    }, [map, zoomIconHide]);

    if (iconHide) return null;
    return (
        <>
            {destinationPoints.map((point, i: number) => {
                return <MarkerDestinationIcon key={i} destinationPoint={point} />;
            })}
        </>
    );
};
