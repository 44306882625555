import { FC, ReactNode } from "react";
import { useRecoilValue } from "recoil";
import { IfBlock } from "../components/IfBlock";
import { isFromPointInFloorState } from "../stores";

export const IfFromPointInFloor: FC<{
    children: ReactNode
}> = ({ children }) => {
    const visible = useRecoilValue(isFromPointInFloorState);
    return <IfBlock visible={visible}>{children}</IfBlock>
}
