import { selector } from "recoil";
import { fromPointQueryState } from "../atoms/fromPointQueryState";


export const loadingFromPointQueryState = selector<boolean>({
    key: "loadingFromPointQueryState",
    get: ({ get }) => {
        return get(fromPointQueryState)?.loading || false;
    },
});
