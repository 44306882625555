import { FC } from "react";

import { useRecoilValue } from "recoil";
import {
    currentPointState,
    fromFloorIdState,
    naviStartNameState,
    pointTypeState,
} from "../../../../stores";
import { MarkerPopupCardView } from "./presenter";
import { linkMapRoute } from "../../../../functions/links/linkMapRoute";
import { useParams } from "react-router-dom";

export const MarkerPopupCard: FC = () => {
    const point = useRecoilValue(currentPointState);
    const pointType = useRecoilValue(pointTypeState);
    const naviStartName = useRecoilValue(naviStartNameState);
    const { url } = useLinkMapRoute({ to: point?.id || '' });

    if (point === null) return null;

    switch (pointType) {
        case "notfound": return null;
        case "fromPoint":
        case "toPoint":
            return (
                <MarkerPopupCardView
                    toPointId={point?.destination?.id}
                    name={point?.destination?.name}
                    detailUrl={point?.destination?.detail_url}
                />
            );
        case "normal":
            return (
                <MarkerPopupCardView
                    toPointId={point?.destination?.id}
                    name={point?.destination?.name}
                    detailUrl={point?.destination?.detail_url}
                    url={url}
                    naviStartName={naviStartName}
                />
            )
    }
};


function useLinkMapRoute({ to }: { to: string }): {
    url: string,
} {
    const { group, floor, from } = useParams();
    const fromFloorId = useRecoilValue(fromFloorIdState);
    const url = linkMapRoute({ group, from, to, floor: fromFloorId || floor });
    return { url }
}
