import { gql, SuspenseQueryHookOptions, useSuspenseQuery } from '@apollo/client';
import { FloorGroup, Point } from "../../generated/graphql"

const QUERY = gql`
query qrcode($group: ID!, $code: ID!, $to: ID!) {
    fromPoint: firstPoint(input: { floor_group_id: $group, code: $code }) {
        id
        floor_id
    }
    toPoint: firstPoint(input: { floor_group_id: $group, id: [$to] }) {
        id
        floor_id
    }
    floorGroup: FloorGroup(id: $group) {
        id
        opening_movie_url
        floors {
            id
            image_url
        }
    }
}
`;
type QueryData = {
    fromPoint: Point;
    toPoint: Point;
    floorGroup: FloorGroup;
}
type QueryVariables = {
    group: string;
    code: string;
    to: string;
};
export function useQrcodeSuspenseQuery(options: SuspenseQueryHookOptions<QueryData, QueryVariables>) {
    return useSuspenseQuery<QueryData, QueryVariables>(QUERY, options);
}