import { selector } from "recoil";
import { Destination } from "../../generated/graphql";
import { destinationsQueryState } from "../atoms/destinationsQueryState";


export const destinationsState = selector<Destination[]>({
    key: "destinationsState",
    get: ({ get }) => {
        const { data } = get(destinationsQueryState);
        return (data?.FloorGroup?.destinations?.data || []) as Destination[];
    },
});
