import { FC } from "react";
import { PointLayout } from "../../components/PointLayout";
import { PageController } from "./parts/PageController";

export const App: FC = () => {
    return (
        <PointLayout>
            <PageController />
        </PointLayout>
    );
};


