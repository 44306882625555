import { selector } from "recoil";
import { toPointQueryState } from "../atoms/toPointQueryState";


export const loadingToPointQueryState = selector<boolean>({
    key: "loadingToPointQueryState",
    get: ({ get }) => {
        return get(toPointQueryState)?.loading || false;
    },
});
