import { FC } from "react";
import { useRecoilValue } from "recoil";
import { ArrowMarker } from "../ArrowMarker";
import {
    fromPointState,
    isFromPointInFloorState, mapBearingState, routeSearchState
} from "../../../../stores";

export const CustomArrowMarker: FC = () => {
    const routeSearch = useRecoilValue(routeSearchState);
    const fromPoint = useRecoilValue(fromPointState);
    const isFromPointInFloor = useRecoilValue(isFromPointInFloorState);
    const mapBaering = useRecoilValue(mapBearingState);
    if (!isFromPointInFloor) return null;
    return <ArrowMarker point={fromPoint} routeSearch={routeSearch} bearing={mapBaering} />;
};
