import axios from "axios";
import { ComponentProps, FC } from "react";
import { IfBlock } from "../../components/IfBlock";
import { Polygon, Rectangle, useMapEvent } from "react-leaflet";
import { currentPointIdState, floorCustomDataUrlState } from "../../stores";
import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

export const AliasArea = () => {
    return (
        <AliasAreaService />
    );
}

const AliasAreaService = () => {

    const floorCustomDataUrl = useRecoilValue(floorCustomDataUrlState);
    const setCurrentPointId = useSetRecoilState(currentPointIdState);
    const [aliasAreas, setAliasAreas] = useState<aliasAreaType[]>([]);

    useEffect(() => {
        if (floorCustomDataUrl === null || floorCustomDataUrl === '') {
            setAliasAreas([]);
            return;
        }
        setTimeout(async () => {
            const { data } = await axios.get(floorCustomDataUrl).catch(() => ({ data: [] }));
            console.log(data);
            setAliasAreas((data?.aliasAreas || []) as aliasAreaType[]);
        }, 0);
    }, [floorCustomDataUrl]);

    return (
        <IfBlock visible={floorCustomDataUrl !== null}>
            <AliasAreaView
                aliasAreas={aliasAreas}
                onClick={(pointId) => {
                    setCurrentPointId(pointId);
                }} />
        </IfBlock>
    );
};



const AliasAreaView: FC<{
    aliasAreas: aliasAreaType[];
    onClick: (pointId: string) => void
}> = ({ aliasAreas, onClick: handleClick }) => {
    useMapEvent('click', (e) => {
        console.log(Math.round(e.latlng.lat), Math.round(e.latlng.lng));

    });
    const aliasAreaWherePositions = aliasAreas.filter((aliasArea) => !!aliasArea?.positions)
    const aliasAreaWhereBounds = aliasAreas.filter((aliasArea) => !!aliasArea?.bounds)
console.log(aliasAreaWhereBounds);
    return (
        <>
            {aliasAreaWherePositions.map((aliasArea, i) => (
                <Polygon
                    key={i}
                    eventHandlers={{ click() { handleClick(aliasArea.pointId) } }}
                    fillColor={'#fff'}
                    fillOpacity={0.1}
                    pathOptions={{ weight: 0 }}
                    positions={aliasArea.positions} />
            ))}
            {aliasAreaWhereBounds.map((aliasArea, i) => (
                <Rectangle
                    key={i}
                    eventHandlers={{ click() { handleClick(aliasArea.pointId) } }}
                    fillColor={'#fff'}
                    fillOpacity={0.1}
                    pathOptions={{ weight: 0 }}
                    bounds={aliasArea.bounds} />
            ))}
        </>
    );
};

export interface aliasAreaType {
    name?: string;
    pointId: string;
    bounds?: ComponentProps<typeof Rectangle>["bounds"];
    positions?: ComponentProps<typeof Polygon>["positions"];
}