import { generatePath } from 'react-router-dom';

export function linkMapRoute(search: linkMapRouteProps): string {
    return generatePath('/map-route/:group/:floor/:from/:to', {
        group: search.group || '',
        floor: search.floor || '',
        from: search.from || '',
        to: search.to || '',
    });
}
export interface linkMapRouteProps {
    group: string;
    floor: string;
    from: string;
    to: string;
}

