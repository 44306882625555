import { atom } from "recoil";
import { useBaseFloorGroupQuery } from "../../generated/graphql";

type BaseFloorGroupStateType = Pick<ReturnType<typeof useBaseFloorGroupQuery>, 'data' | 'loading'>;
export const baseFloorGroupState = atom<BaseFloorGroupStateType>({
    key: "baseFloorGroupState",
    default: {
        data: {} as BaseFloorGroupStateType['data'],
        loading: true,
    } as BaseFloorGroupStateType,
});
