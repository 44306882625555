import { FC, ReactNode } from "react";
import { NameBlock } from "./NameBlock";
import { MuiSportsScoreIcon } from "../../../../components/mui";
import { Link, useLocation, useParams } from "react-router-dom";
import { IfBlock } from "../../../../components/IfBlock";
import { sendLogPublicWhenSelectPointRoute } from "../../../../features/logPublic/sendLogPublicWhenSelectPointRoute";

export const MarkerPopupCardView: FC<{
    name: string;
    detailUrl: string;
    url?: string;
    naviStartName?: string;
    toPointId: string;
}> = ({ toPointId, name, detailUrl, url = '', naviStartName = '' }) => (
    <div className="row g-2 py-2">
        <div className="col-12">
            <NameBlock
                name={name}
                url={detailUrl}
            />
        </div>
        <div className="col-6">
        </div>
        <IfBlock visible={(url !== '' && naviStartName !== '')}>
            <div className="col-6">
                {/* ここへ行くボタン */}
                <div className="rounded-circle">
                    <LinkAndLog name={name} toPointId={toPointId} to={url} className="btn btn-sm btn-danger w-100">
                        <MuiSportsScoreIcon />
                        {naviStartName}
                    </LinkAndLog>
                </div> {/* ここへ行くボタン */}
            </div>
        </IfBlock>
    </div>
);

const LinkAndLog: FC<{
    children: ReactNode;
    to: string;
    toPointId: string;
    className: string;
    name: string;
}> = ({ to, name, children, className, toPointId }) => {
    const location = useLocation();
    const params = useParams();
    const handleClick = () => {
        sendLogPublicWhenSelectPointRoute({
            location, params,
            select_point_id: toPointId,
            select_point_name: name,
        });
    }
    return (
        <Link onClick={handleClick} to={to} className={className}>
            {children}
        </Link>
    )
}
