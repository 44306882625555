import { FC } from "react";

export const CustomCardImgTop: FC<{
    src: string;
}> = ({ src }) => (
    <img
        src={src}
        alt="写真"
        className="card-img-top"
        style={{ maxHeight: 200, objectFit: "cover" }} />
);
