import { FC, Suspense } from "react";
import { QrAnimeSuspenseBlock } from "../features/QrAnimeSuspenseBlock";
import { Center } from "../../../components/Center";


export const PageView: FC = () => (
    <Suspense fallback={<Center title={"loading..."} />}>
        <QrAnimeSuspenseBlock />
    </Suspense>
);
