import { FC, ReactNode } from "react";
import { Fukidashi } from "./Fukidashi";
import { MapIcon } from "./MapIcon";
import { CSSProperties } from "react";

export const MuiIcon: FC<{
    color: string,
    children: ReactNode,
}> = ({ color, children }) => (
    <div style={style}>
        <Fukidashi color={color} />
        <MapIcon color={color}>{children}</MapIcon>
    </div>
);

const style = {
    background: "transparent",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 40,
} as CSSProperties;
