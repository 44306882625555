import { FC } from "react";
import { useParams } from "react-router-dom";
import { setToPointStorage } from "../../../functions/storage";

// 目的地
export const WatchToPoint: FC = () => {
    const { to } = useParams();
    setToPointStorage(to || "");
    return null;
};
