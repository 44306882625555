import { atom } from "recoil";
import { useGetPointQuery } from "../../generated/graphql";

type FromPointQueryStateType = Pick<ReturnType<typeof useGetPointQuery>, 'data' | 'loading'>;
export const fromPointQueryState = atom<FromPointQueryStateType>({
    key: "fromPointQueryState",
    default: {
        data: {} as FromPointQueryStateType['data'],
        loading: true,
    } as FromPointQueryStateType,
});
