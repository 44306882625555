import { linkMapProps } from "./linkMap";
import { linkMapRouteProps } from "./linkMapRoute";
import { linkMap } from './linkMap';
import { linkMapRoute } from './linkMapRoute';

export function linkMapOrMapRoute(search: linkMapRouteProps): string {
    if (!search.to) {
        return linkMap(search as linkMapProps);
    } else {
        return linkMapRoute(search as linkMapRouteProps);
    }
}
