import { FC } from 'react';
import { lang } from '../functions/lang';
import { MuiCircularProgress, MuiDialog, MuiDialogContent } from '../components/mui';

export const LoadigModal: FC<{
    open: boolean;
}> = ({ open }) => (
    <MuiDialog open={open} fullWidth={true}>
        <MuiDialogContent>
            <div className="text-center">
                <MuiCircularProgress />
                <div className="mt-3">{lang('読み込み中')}</div>
            </div>
        </MuiDialogContent>
    </MuiDialog>
);
