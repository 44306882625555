import { FC, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { currentMoveOptionState, routeSearchQueryState } from "../../stores";
import { useRouteSearchQuery } from "../../generated/graphql";
import { useParams } from "react-router-dom";


export const LoadRouteSearch: FC = () => {
    useLoadRouteSearch();
    return null;
};

function useLoadRouteSearch() {
    const { group, from, to } = useParams();
    const moveOption = useRecoilValue(currentMoveOptionState);

    const { data, loading } = useRouteSearchQuery({
        skip: !group || !from || !to,
        variables: { group, from, to, moveOption },
    });

    const setRouteSearch = useSetRecoilState(routeSearchQueryState);

    useEffect(() => {
        setRouteSearch({ data, loading });
    }, [loading, data, setRouteSearch]);

    return null;
};
