import { CircleMarkerProps } from "react-leaflet";
import { Icon } from "../../generated/graphql";

export function makeCircleMarkerProps(icon: Icon): CircleMarkerProps {
    const props = {} as CircleMarkerProps;
    if (icon?.radius) props.radius = icon.radius;
    if (icon?.color) props.color = icon.color;
    if (icon?.fill_color) props.fillColor = icon.fill_color;
    if (icon?.opacity) props.opacity = icon.opacity;
    if (icon?.fill_opacity) props.fillOpacity = icon.fill_opacity;
    if (icon?.weight) props.weight = icon.weight;
    if (icon?.weight === 0) props.weight = 0;
    return props;
}
