import { FC } from "react";
import { useRecoilValue } from "recoil";
import { SameFloorState } from "../../../stores";
import { SelectMovingModalButton } from './SelectMovingModalButton';


export const CustomSelectMovingModalButton: FC = () => {
    const sameFloor = useRecoilValue(SameFloorState);
    if (sameFloor) return null;
    return <SelectMovingModalButton />;
};
