import { selector } from "recoil";
import { Floor } from "../../generated/graphql";
import { floorsState } from "./floorsState";
import { currentFloorIdState } from "./currentFloorIdState";

// select, currentParam

export const currentFloorState = selector<Floor | null>({
    key: "currentFloorState",
    get: ({ get }) => {
        const floorId = get(currentFloorIdState);
        const floors = get(floorsState);
        return floors.filter((floor) => floor?.id === floorId)[0] || null;
    },
});
