import { selector } from "recoil";
import { FloorGroup } from "../../generated/graphql";
import { baseFloorGroupState } from "../atoms/baseFloorGroupState";

// select, floorGroup

export const floorGroupState = selector<FloorGroup>({
    key: "floorGroupState",
    get: ({ get }) => {
        const data = get(baseFloorGroupState);
        return (data?.data?.FloorGroup || {}) as FloorGroup;
    }
});
