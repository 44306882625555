import { selector } from "recoil";
import { Point } from "../../generated/graphql";
import { pointsQueryState } from "../atoms/pointsQueryState";
import { _formatPoints } from "../functions";


export const allPointsState = selector<Point[]>({
    key: "allPointsState",
    get: ({ get }) => {
        const { data } = get(pointsQueryState);
        const allPoints: Point[] = data?.FloorGroup?.points?.data || [];
        return _formatPoints(get, allPoints) || [];
    }
});
