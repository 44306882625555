import { MouseEventHandler } from 'react';
import { MuiButton, MuiDialog, MuiDialogActions, MuiDialogContent, MuiDialogTitle } from '../../../components/mui';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
    distanceOfToPointEsState,
    distanceOfToPointEvState,
    distanceOfToPointStepState,
    currentFloorMoveState,
    openSelectMovingModalState,
    toPointState,
} from '../../../stores';
import { lang } from '../../../functions/lang';
import { IfCanEv } from '../../../features/IfCanEv';
import { IfCanEs } from '../../../features/IfCanEs';
import { IfCanStep } from '../../../features/IfCanStep';

type EventType = MouseEventHandler<HTMLDivElement>

export const SelectMovingModal: React.FC = () => {
    const [open, setOpen] = useRecoilState(openSelectMovingModalState);

    const setCurrentFloorMove = useSetRecoilState(currentFloorMoveState);
    const toPoint = useRecoilValue(toPointState);

    const costEv = useRecoilValue(distanceOfToPointEvState);
    const costEs = useRecoilValue(distanceOfToPointEsState);
    const costStep = useRecoilValue(distanceOfToPointStepState);

    const handleCancel = () => {
        setOpen(false);
    };
    const handleClickEv: EventType = (e) => {
        setCurrentFloorMove("EV");
        setOpen(false);
    };
    const handleClickEs: EventType = (e) => {
        setCurrentFloorMove("ES");
        setOpen(false);
    };
    const handleClickStep: EventType = (e) => {
        setCurrentFloorMove("STEP");
        setOpen(false);
    };

    return (
        <MuiDialog open={open} fullWidth={true}>
            <MuiDialogTitle>{lang('条件指定')}</MuiDialogTitle>
            <MuiDialogContent>
                <div>
                    <div className="card text-black mb-3">
                        <div className="card-body">
                            <div className="row mb-2">
                                <div className="col">{lang('目的地')}</div>
                                <div className="col-auto"> {toPoint?.destination?.floor?.name || ''} </div>
                            </div>
                            <div>
                                {toPoint?.destination?.name || ''}
                            </div>
                        </div>
                    </div>
                    <IfCanEv>
                        <div className="mb-3">
                            <div onClick={handleClickEv} className="btn btn-primary w-100">
                                <div className="row g-0">
                                    <div className="col">
                                        {lang('エレベータで行く')}
                                    </div>
                                    <div className="col-auto">
                                        {costEv}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </IfCanEv>
                    <IfCanEs>
                        <div className="mb-3">
                            <div onClick={handleClickEs} className="btn btn-primary w-100">
                                <div className="row g-0">
                                    <div className="col">
                                        {lang('エスカレータで行く')}
                                    </div>
                                    <div className="col-auto">
                                        {costEs}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </IfCanEs>
                    <IfCanStep>
                        <div className="mb-3">
                            <div onClick={handleClickStep} className="btn btn-primary w-100">
                                <div className="row g-0">
                                    <div className="col">
                                        {lang('階段で行く')}
                                    </div>
                                    <div className="col-auto">
                                        {costStep}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </IfCanStep>
                </div>
            </MuiDialogContent>
            <MuiDialogActions>
                <MuiButton onClick={handleCancel}>{lang('キャンセル')}</MuiButton>
            </MuiDialogActions>
        </MuiDialog>
    );
}