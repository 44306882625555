import { selector } from "recoil";
import { toPointState } from "./toPointState";
import { floorState } from "./floorState";


export const isToPointInFloorState = selector<boolean>({
    key: "istoPointInFloorState",
    get: ({ get }) => {
        const floor = get(floorState);
        const toPoint = get(toPointState);
        return floor.id == toPoint.floor_id;
    },
});
