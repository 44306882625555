import { FC, CSSProperties, ReactNode } from "react";

export const CircleButton: FC<{
    children: ReactNode;
    onClick: () => void;
}> = ({ children, onClick: handleClick }) => (
    <div className="shadow rounded-circle" onClick={handleClick}>
        <div
            className="btn text-primary bg-white btn-outline-primary rounded-circle"
            style={style}
        >
            <div className="d-flex justify-content-center align-items-center h-100 w-100">
                {children}
            </div>
        </div>
    </div>
);

const style = {
    width: 50,
    height: 50,
} as CSSProperties;
