export function linkQR(search: linkQrProps): string {
    const query = new URLSearchParams({ skip: 'on' });
    if (search.to) query.set('to', search.to);
    return [
        [
            '',
            'qr',
            search.group,
            search.code,
        ].join('/'),
        query.toString(),
    ].join('?');
}
interface linkQrProps {
    group: string;
    code: string;
    to?: string;
}

