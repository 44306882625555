import { FC } from "react";
import { useRecoilValue } from "recoil";
import {
    loadingFloorGroupState,
} from "../../../stores";
import { LoadigModal } from '../../../features/LoadingModal';
import { useFade } from "../../../hooks/useFade";

export const CustomLoadigModal: FC = () => {
    const loading1 = useRecoilValue(loadingFloorGroupState);
    const fade = useFade();
    return <LoadigModal open={fade === false && loading1} />;
};
