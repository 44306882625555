import { FC, CSSProperties } from "react";
import { useZxing } from "react-zxing";

export const BarcodeScanner: FC<{
    onScan: (text: string) => void;
}> = ({ onScan: handleScan }) => {
    const { ref } = useZxing({
        onResult(result) {
            const text = result.getText();
            if (text) {
                handleScan(text);
            }
        },
    });
    return (
        <>
            <div style={styles.viewFinder}></div>
            <div style={styles.videoContainer}>
                <video ref={ref} style={styles.video} />
            </div>
        </>
    );
};

const styles = {
    videoContainer: {
        width: '100%',
        maxWidth: "75vh",
        aspectRatio: 1,
        overflow: 'hidden',
    } as CSSProperties,
    video: {
        width: '100%',
        maxWidth: "75vh",
    } as CSSProperties,
    viewFinder: {
        top: 0,
        left: 0,
        zIndex: 1,
        boxSizing: "border-box",
        border: "50px solid rgba(0, 0, 0, 0.3)",
        boxShadow: "inset 0 0 0 5px rgba(255, 0, 0, 0.5)",
        position: "absolute",
        width: "100%",
        maxWidth: "75vh",
        aspectRatio: 1,
    } as CSSProperties,

}