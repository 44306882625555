import axios from "axios";
import { LoaderFunction } from "react-router-dom";
import { lazySendLogPublic } from "../../logPublic/lazySendLogPublic";

axios.defaults.withCredentials = true;
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

export const loader: LoaderFunction = ({ request, params }) => {
    lazySendLogPublic({ request, params });
    ga4({ request, params });
    return { request, params };
}

export type LoaderProps = {
    request: Parameters<LoaderFunction>[0]["request"],
    params: Parameters<LoaderFunction>[0]["params"],
}

const ga4 = ({ request }: LoaderProps) => {
    const url = new URL(request.url);
    document.title = ['ここどこQR', url.pathname].join(' | ');
};