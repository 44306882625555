import { selector } from "recoil";
import { fromPointState } from "./fromPointState";


export const fromFloorIdState = selector<string>({
    key: "fromFloorIdState",
    get: ({ get }) => {
        const fromPoint = get(fromPointState);
        return fromPoint.floor_id || "";
    },
});
