import { atom } from "recoil";
import { useRouteSearchQuery } from "../../generated/graphql";

// loading
type RouteSearchQueryStateType = Pick<ReturnType<typeof useRouteSearchQuery>, 'data' | 'loading'>;
export const routeSearchQueryState = atom<RouteSearchQueryStateType>({
    key: "routeSearchQueryState",
    default: {
        data: undefined,
        loading: true,
    } as RouteSearchQueryStateType,
});
