import { selector } from "recoil";
import { Point } from "../../generated/graphql";
import { currentPointState } from "../atoms/currentPointState";
import { allPointsState } from "./allPointsState";


export const currentPointIdState = selector<string | null>({
    key: "currentPointIdState",
    get: ({ get }) => {
        const currentPoint = get(currentPointState);
        return currentPoint?.id || null;
    },
    set: ({ set, get }, newCurrentPointId) => {
        const allPoints = get(allPointsState);
        const newCurrentPoint = allPoints.filter((point: Point) => {
            return newCurrentPointId === point.id;
        })[0] || null;
        set(currentPointState, newCurrentPoint);
    },
});
