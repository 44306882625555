import { selector } from "recoil";
import { Icon } from "../../generated/graphql";
import { baseFloorGroupState } from "../atoms/baseFloorGroupState";


export const leafletIconsState = selector<Icon[]>({
    key: "leafletIconsState",
    get: ({ get }) => {
        const data = get(baseFloorGroupState);
        return (data?.data?.mapIcons || []) as Icon[];
    }
});
