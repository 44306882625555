import { FC } from "react";
import { PointLayout } from "../../components/PointLayout";
import { LoadFloorGroup } from "../../features/LoadData/LoadFloorGroup";
import { CustomLoadigModal } from "./components/CustomLoadigModal";
import { PageController } from "./parts/PageController";

export const App: FC = () => {
    return (
        <PointLayout>
            <PageController />
            <CustomLoadigModal />
            <LoadFloorGroup />
        </PointLayout>
    );
};


