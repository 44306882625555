import { sendLogPublic } from "./sendLogPublic";
import { LoaderProps } from "../MainApp/features/loader";

export let timerId = 0;
export function lazySendLogPublic({ request, params }: LoaderProps) {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
        sendLogPublic({ request, params });
    }, 1000);
}
