import { CSSProperties, FC } from "react";
import ReactMarkdown from "react-markdown";
import { Odaimoku } from "../../../../generated/graphql";

export const DestinationBlockView: FC<{
    imageUrl: string;
    name: string;
    body: string;
    odaimoku: Odaimoku[];
}> = ({ imageUrl, name, body, odaimoku }) => {
    return (
        <>
            <div className="mb-2">
                {imageUrl && (
                    <img className="w-100 bg-light" src={imageUrl} alt="" />
                )}
            </div>
            <h4> {name} </h4>
            <hr />
            <ReactMarkdown linkTarget="_blank" components={{ p: 'p' }}>
                {formatMd(body || '')}
            </ReactMarkdown>
            <table className="table table-bordered">
                <tbody>
                    {odaimoku
                        .filter(a => a)
                        .map(({ title, md }: Odaimoku, i: number) => {
                            return (
                                <OdaimokuTr key={i} title={title} md={md} />
                            );
                        })
                    }
                </tbody>
            </table>
        </>
    );
};

const OdaimokuTr: FC<{
    title: string,
    md: string,
}> = ({ title, md }) => {
    return (
        <tr>
            <th style={style.tableTh}>
                {title}
            </th>
            <td>
                <ReactMarkdown
                    linkTarget="_blank"
                    components={{ p: "p" }}
                    className="mb-0"
                >
                    {formatMd(md || '')}
                </ReactMarkdown>
            </td>
        </tr>
    )
}

function formatMd(text: string): string {
    return text.replace(/\r*\n/g, '  \n')
}
const style = {
    tableTh: {
        backgroundColor: "#eee",
    } as CSSProperties
}
