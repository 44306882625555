import { FC, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { destinationsQueryState } from "../../stores";
import { useDestinationsQuery } from "../../generated/graphql";
import { useParams } from "react-router-dom";


export const LoadDestinations: FC = () => {
    useLoadDestinations();
    return null;
};

function useLoadDestinations() {
    const { group } = useParams();
    const { data, loading } = useDestinationsQuery({
        skip: !group,
        variables: { group },
    });
    const setValue = useSetRecoilState(destinationsQueryState);
    useEffect(() => {
        setValue({ data, loading });
    }, [data, loading, setValue]);
};
