import { FC } from "react";
import { CustomSelectMovingModalButton } from "./CustomSelectMovingModalButton";
import { RouteMessageNaviAngle } from "./RouteMessageNaviAngle";
import { RouteMessageNaviRoute } from "./RouteMessageNaviRoute";
import { RouteMessageTotal } from "./RouteMessageTotal";
import { lang } from "../../../functions/lang";
import { loadingRouteSearchState, routeSearchArrivedState, routeSearchNotFoundState } from "../../../stores";
import { useRecoilValue } from "recoil";

export const RouteMessageNaviTotal: FC = () => {
    const isArrived = useRecoilValue(routeSearchArrivedState);
    const isNotFound = useRecoilValue(routeSearchNotFoundState);
    const isLoading = useRecoilValue(loadingRouteSearchState);

    if (isLoading) {
        return (
            <span>{lang('検索中')}</span>
        );
    }

    if (isNotFound) {
        return (
            <span>{lang('経路が存在しません。')}</span>
        );
    }

    if (isArrived) {
        return (
            <span>{lang('到着しました')}</span>
        );
    }
    return <RouteMessageNaviTotalView />;
};

const RouteMessageNaviTotalView: FC = () => {
    return (
        <div className="row">
            {/* 経路案内 */}
            <div className="col-12">
                <RouteMessageNaviAngle />
                <RouteMessageNaviRoute />
            </div>

            {/* 距離 & 条件指定 */}
            <div className="col-12 text-end">
                <div className="row">
                    <div className="col text-start">
                        <RouteMessageTotal />
                    </div>
                    <div className="col-auto">
                        <CustomSelectMovingModalButton />
                    </div>
                </div>
            </div>
        </div>
    );
};
