import { FC } from "react";
import { PageView } from "./PageView";


export const PageController: FC<{
}> = () => {
    return (
        <PageView />
    );
};
