import { FC, CSSProperties, ReactNode } from "react";

export const Bottom: FC<{
    children: ReactNode;
}> = ({ children }) => (
    <div style={style}>
        <div className="d-flex justify-content-center">{children}</div>
    </div>
);

const style = {
    position: "fixed",
    bottom: 0,
    zIndex: 1001,
} as CSSProperties;