import { FC } from "react";

export const NameBlock: FC<{
    name: string;
    url: string;
}> = ({ name, url }) => (
    <div className="row align-items-center">
        <div className="col-9">
            <h6 className="mb-0">{name}</h6>
        </div>
        {url && (
            <div className="col-3">
                <DetailButton url={url} />
            </div>
        )}
    </div>
);

const DetailButton: FC<{ url: string }> = ({ url }) => {
    return (
        <a
            className="btn btn-sm btn-outline-primary w-100"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
        >
            詳細
        </a>
    );
};