import { ReactNode } from "react";
import ReactDOMServer from "react-dom/server";
import { divIcon as leafletDivIcon } from "leaflet";

export const createMuiIcon: createMuiIconType<{
    icon: ReactNode;
}> = ({ icon }) => leafletDivIcon({
    html: ReactDOMServer.renderToString(<>{icon}</>),
    className: "",
    iconSize: [w, h],
    iconAnchor: [w / 2, h],
    tooltipAnchor: [w / 2, -h / 2],
    popupAnchor: [0, -h],
});

type createMuiIconType<T> = (props: T) => ReturnType<typeof leafletDivIcon>
const [w, h] = [40, 40];