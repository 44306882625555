import { FC, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { baseFloorGroupState } from "../../stores";
import { useBaseFloorGroupQuery } from "../../generated/graphql";
import { useParams } from "react-router-dom";


export const LoadFloorGroup: FC = () => {
    useLoadFloorGroup();
    return null;
};

function useLoadFloorGroup() {
    const { group } = useParams();
    const { data, loading } = useBaseFloorGroupQuery({
        skip: !group,
        variables: { group },
    });
    const setBaseFloorGroup = useSetRecoilState(baseFloorGroupState);
    useEffect(() => {
        setBaseFloorGroup({ data, loading });
    }, [data, loading, setBaseFloorGroup]);
}