import { FC, ReactNode } from "react";
import { IfBlock } from "../components/IfBlock";
import { useRecoilValue } from "recoil";
import { isShowDistanceState } from "../stores";

export const IfShowDistance: FC<{
    sameFloor: boolean;
    children: ReactNode;
}> = ({ children, sameFloor }) => {
    const visible = useRecoilValue(isShowDistanceState({ sameFloor }));
    return <IfBlock visible={visible}>{children}</IfBlock>;
};
