import { selector } from "recoil";
import { Point } from "../../generated/graphql";
import { currentParamState } from "../atoms/currentParamState";
import { toPointQueryState } from "../atoms/toPointQueryState";
import { loadingToPointState } from "./loadingToPointState";
import { _formatPoints2 } from "../functions";


export const toPointState = selector<Point>({
    key: "toPointState",
    get: ({ get }) => {
        const loading = get(loadingToPointState);
        const { data } = get(toPointQueryState);
        const { to } = get(currentParamState);
        if (loading || !to || !data?.Point) return {} as Point;
        return _formatPoints2(get, data?.Point as Point);
    }
});
