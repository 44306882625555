// https://github.com/Leaflet/Leaflet/blob/main/src/control/Control.Zoom.js

import "./style.css"

import { Control, Map, DomUtil, DomEvent } from 'leaflet';

const rotateRight = '<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium cst-css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="RotateRightIcon" style="font-size:1.25rem"><path d="M15.55 5.55 11 1v3.07C7.06 4.56 4 7.92 4 12s3.05 7.44 7 7.93v-2.02c-2.84-.48-5-2.94-5-5.91s2.16-5.43 5-5.91V10l4.55-4.45zM19.93 11c-.17-1.39-.72-2.73-1.62-3.89l-1.42 1.42c.54.75.88 1.6 1.02 2.47h2.02zM13 17.9v2.02c1.39-.17 2.74-.71 3.9-1.61l-1.44-1.44c-.75.54-1.59.89-2.46 1.03zm3.89-2.42 1.42 1.41c.9-1.16 1.45-2.5 1.62-3.89h-2.02c-.14.87-.48 1.72-1.02 2.48z"></path></svg>'
const rotateLeft = '<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium cst-css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="RotateLeftIcon" style="font-size:1.25rem"><path d="M7.11 8.53 5.7 7.11C4.8 8.27 4.24 9.61 4.07 11h2.02c.14-.87.49-1.72 1.02-2.47zM6.09 13H4.07c.17 1.39.72 2.73 1.62 3.89l1.41-1.42c-.52-.75-.87-1.59-1.01-2.47zm1.01 5.32c1.16.9 2.51 1.44 3.9 1.61V17.9c-.87-.15-1.71-.49-2.46-1.03L7.1 18.32zM13 4.07V1L8.45 5.55 13 10V6.09c2.84.48 5 2.94 5 5.91s-2.16 5.43-5 5.91v2.02c3.95-.49 7-3.85 7-7.93s-3.05-7.44-7-7.93z"></path></svg>'

export const Rotate = Control.extend({
    options: {
        position: 'topleft',
        zoomInText: rotateRight,
        zoomInTitle: 'Rotate right',
        zoomOutText: rotateLeft,
        zoomOutTitle: 'Rotate left'
    },

    onAdd(map: Map) {
        const RotateName = 'leaflet-control-rotate',
            container = DomUtil.create('div', `${RotateName} leaflet-bar`),
            options = this.options;

        this._zoomInButton = this._createButton(options.zoomInText, options.zoomInTitle,
            `${RotateName}-in`, container, this._zoomIn);
        this._zoomOutButton = this._createButton(options.zoomOutText, options.zoomOutTitle,
            `${RotateName}-out`, container, this._zoomOut);

        // this._updateDisabled();
        // map.on('zoomend zoomlevelschange', this._updateDisabled, this);

        return container;
    },

    // onRemove(map: Map) {
    //     map.off('zoomend zoomlevelschange', this._updateDisabled, this);
    // },

    // disable() {
    //     this._disabled = true;
    //     this._updateDisabled();
    //     return this;
    // },

    // enable() {
    //     this._disabled = false;
    //     this._updateDisabled();
    //     return this;
    // },

    _zoomIn(e) {
        const map = this._map;
        if (!this._disabled) {
            const bear = map.getBearing();
            const newBear = Math.round((bear + 45) / 45) * 45;
            map.fireEvent("updateRotate", { bear: newBear });
        }
    },

    _zoomOut(e) {
        const map = this._map;
        if (!this._disabled) {
            const bear = map.getBearing();
            const newBear = Math.round((bear - 45) / 45) * 45;
            map.fireEvent("updateRotate", { bear: newBear });
        }
    },

    _createButton(html, title, className, container, fn) {
        const link = DomUtil.create('a', className, container);
        link.innerHTML = html;
        link.href = '#';
        link.title = title;

        link.setAttribute('role', 'button');
        link.setAttribute('aria-label', title);

        DomEvent.disableClickPropagation(link);
        DomEvent.on(link, 'click', DomEvent.stop);
        DomEvent.on(link, 'click', fn, this);
        DomEvent.on(link, 'click', this._refocusOnMap, this);

        return link;
    },

    // _updateDisabled() {
    //     const map = this._map,
    //         className = 'leaflet-disabled';

    //     this._zoomInButton.classList.remove(className);
    //     this._zoomOutButton.classList.remove(className);
    //     this._zoomInButton.setAttribute('aria-disabled', 'false');
    //     this._zoomOutButton.setAttribute('aria-disabled', 'false');

    //     if (this._disabled || map._zoom === map.getMinZoom()) {
    //         this._zoomOutButton.classList.add(className);
    //         this._zoomOutButton.setAttribute('aria-disabled', 'true');
    //     }
    //     if (this._disabled || map._zoom === map.getMaxZoom()) {
    //         this._zoomInButton.classList.add(className);
    //         this._zoomInButton.setAttribute('aria-disabled', 'true');
    //     }
    // }
});

