import { FC, Suspense } from "react";
import { DestinationSuspenseBlock } from "../features/DestinationSuspenseBlock";
import { lang } from "../../../functions/lang";
import { Center } from "../../../components/Center";

export const PageView: FC<{
    destinationId: string
}> = ({ destinationId }) => (
    <Suspense fallback={<Center title={lang('読み込み中')} />}>
        <DestinationSuspenseBlock destinationId={destinationId} />
    </Suspense>
)
