import { FC } from "react";
import { Link, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { naviEndNameState } from "../../../stores";
import { linkMap } from '../../../functions/links/linkMap';

export const SearchEnd: FC = () => {
    const { group, floor, from } = useParams();
    const naviEndName = useRecoilValue(naviEndNameState);
    return (
        <Link
            to={linkMap({ group, floor, from })}
            className="btn btn-sm btn-primary"
        >
            {naviEndName}
        </Link>
    );
};
