import { FC, ReactNode } from "react";
import { LeafletEventHandlerFnMap } from "leaflet";
import { Point } from "../../generated/graphql";
import { getPointCenter } from "../../functions/map";
import { CustomImageMarker } from "./CustomImageMarker";
import { CustomNumberMarker } from "./CustomNumberMarker";
import { CircleMarker } from "react-leaflet";
import { makeCircleMarkerProps } from "./makeCircleMarkerProps";


export const KokodokoMarker: FC<{
    point: Point;
    children?: ReactNode;
    eventHandlers?: LeafletEventHandlerFnMap;
}> = ({ point, eventHandlers, children }) => {
    const icon = point.icon;
    const tooltip = point.tooltip || '';
    const center = getPointCenter(point);
    const type = icon?.icon_type_id || null;

    if (!icon) return null;
    if (!type) return null;
    if (!center) return null;

    switch (type) {
        // サークルアイコン
        case "1":
            return (
                <CircleMarker
                    center={center}
                    eventHandlers={eventHandlers}
                    {...makeCircleMarkerProps(icon)}
                />
            );

        // 番号アイコン
        case "2":
            return (
                <CustomNumberMarker
                    position={center}
                    eventHandlers={eventHandlers}
                    color={icon.color}
                    fillColor={icon.fill_color}
                    tooltip={tooltip}
                >
                    {children}
                </CustomNumberMarker>
            );

        // 画像
        case "3":
            return (
                <CustomImageMarker
                    position={center}
                    eventHandlers={eventHandlers}
                    width={icon.width}
                    height={icon.height}
                    iconUrl={icon.image_url}
                >
                    {children}
                </CustomImageMarker>
            );
    }
    return null;
};
