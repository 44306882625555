import { selector } from "recoil";
import { Point } from "../../generated/graphql";
import { fromPointQueryState } from "../atoms/fromPointQueryState";
import { loadingFromPointState } from "./loadingFromPointState";
import { _formatPoints2 } from "../functions";


export const fromPointState = selector<Point>({
    key: "fromPointState",
    get: ({ get }) => {
        const loading = get(loadingFromPointState);
        const { data } = get(fromPointQueryState);

        if (loading) return {} as Point;
        if (data?.Point) {
            return _formatPoints2(get, data?.Point as Point);
        }
        return {} as Point;
    }
});
