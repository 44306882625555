import { FC, ReactNode } from "react";
import { useRecoilValue } from "recoil";
import { IfBlock } from "../components/IfBlock";
import { showCategoryState } from "../stores";

export const IfShowCategory: FC<{
    children: ReactNode
}> = ({ children }) => {
    const visible = useRecoilValue(showCategoryState);
    return <IfBlock visible={visible}>{children}</IfBlock>
}
