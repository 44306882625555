import { atom } from "recoil";

export const currentParamState = atom<CurrentParamType>({
    key: "currentMapParamState",
    default: {} as CurrentParamType,
});
type CurrentParamType = {
    group: string,
    floor: string,
    from: string,
    to?: string,
}
