import { MapEx } from "../../../../features/MapEx";
import { FromMarker } from '../../../../features/FromMarker';
import { RotateControl } from "../../../../features/RotateControl";
import { CustomPointControl } from "./CustomPointControl";
import { MarkerDestinationIconGroup } from "../../../../features/MarkerEx/MarkerDestinationIconGroup";
import { MarkerDestinationNameGroup } from "../../../../features/MarkerEx/MarkerDestinationNameGroup";
import { MarkerQrGroup } from "../../../../features/MarkerEx/MarkerQrGroup";
import { LayerGroup } from "react-leaflet";
import { IfShowPoint } from "../../../../features/IfShowPoint";
import { IfFromPointInFloor } from "../../../../features/IfFromPointInFloor";

export const MapForMap = () => {
    return (
        <MapEx>
            <IfShowPoint>
                <LayerGroup>
                    <MarkerDestinationIconGroup />
                </LayerGroup>
                <LayerGroup>
                    <MarkerDestinationNameGroup />
                </LayerGroup>
            </IfShowPoint>
            <LayerGroup>
                <MarkerQrGroup />
            </LayerGroup>
            <IfFromPointInFloor>
                <LayerGroup>
                    <FromMarker />
                </LayerGroup>
            </IfFromPointInFloor>
            <CustomPointControl />
            <RotateControl />
        </MapEx>
    );
};


