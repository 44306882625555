import { FC } from "react";
import { PageController } from "./parts/PageController";
import { CustomLoadigModal } from "./components/CustomLoadigModal";
import { LoadPoints } from "../../features/LoadData/LoadPoints";
import { LoadDestinations } from "../../features/LoadData/LoadDestinations";
import { LoadFromPoint } from "../../features/LoadData/LoadFromPoint";
import { LoadFloorGroup } from "../../features/LoadData/LoadFloorGroup";
import { removeLocalStorageAll } from "../../functions/storage";
import { useSetCurrentParam } from "./hooks/useSetCurrentParam";

export const App: FC = () => {
    removeLocalStorageAll();
    useSetCurrentParam();
    return (
        <>
            <CustomLoadigModal />
            <PageController />
            <LoadFloorGroup />
            <LoadDestinations />
            <LoadPoints />
            <LoadFromPoint />
        </>
    );
};