import { FC, ReactNode } from "react";
import { LayerGroup } from "react-leaflet";
import { MapEx } from "../../../../features/MapEx";
import { FromMarker } from '../../../../features/FromMarker';
import { RotateControl } from "../../../../features/RotateControl";
import { CustomPointControl } from "./CustomPointControl";
import { Tooltips } from "./Tooltips";
import { CustomArrowMarker } from "./CustomArrowMarker";
import { ToMarker } from "./ToMarker";
import { Routes } from "./Routes";
import { MarkerDestinationIconGroup } from "../../../../features/MarkerEx/MarkerDestinationIconGroup";
import { MarkerDestinationNameGroup } from "../../../../features/MarkerEx/MarkerDestinationNameGroup";
import { IfShowPoint } from "../../../../features/IfShowPoint";
import { IfFromPointInFloor } from "../../../../features/IfFromPointInFloor";
import { IfToPointInFloor } from "../../../../features/IfToPointInFloor";

export const MapForMapRoute: FC<{
    children?: ReactNode;
}> = ({ children }) => {
    return (
        <MapEx>
            <Routes />
            <IfShowPoint>
                <LayerGroup>
                    <MarkerDestinationIconGroup />
                </LayerGroup>
                <LayerGroup>
                    <MarkerDestinationNameGroup />
                </LayerGroup>
            </IfShowPoint>
            <IfFromPointInFloor>
                <LayerGroup>
                    <FromMarker />
                    <CustomArrowMarker />
                </LayerGroup>
            </IfFromPointInFloor>
            <IfToPointInFloor>
                <ToMarker />
            </IfToPointInFloor>
            <Tooltips />
            <CustomPointControl />
            <RotateControl />
            {children}
        </MapEx>
    );
};


