import { selector } from "recoil";
import { floorsState } from "./floorsState";


export const showFloorState = selector<boolean>({
    key: "showFloorState",
    get: ({ get }) => {
        const floors = get(floorsState);
        return floors.length >= 2;
    },
});
