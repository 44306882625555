import { selector } from "recoil";
import { LatLngTuple } from "leaflet";
import { Route } from "../../generated/graphql";
import { floorRoutesState } from "./floorRoutesState";
import { _formatPolyList } from "../functions";


export const floorRoutesPosisiontsState = selector<LatLngTuple[][]>({
    key: "floorRoutesPosisiontsState",
    get: ({ get }) => {
        const floorRoutes = get(floorRoutesState);

        // 表示経路
        const polyList = floorRoutes
            .map((route: Route) => {
                const poly = (route?.paths || [])
                    .filter((pos) => pos);
                return poly.length > 0 ? poly as LatLngTuple[] : null;
            }).filter((poly) => poly);
        return _formatPolyList(polyList as LatLngTuple[][]);
    }
});
