import { FC } from "react";
import { LeafletEvent, Map as LeafletMap } from "leaflet";
import { useMapEvent } from 'react-leaflet';
import { FromPointControlView } from "./FromPointControlView";

export const FromPointControl: FC<{
    onClick: (map: LeafletMap) => void;
}> = ({ onClick: handleClick }) => {
    const map = useMapEvent("update", (props: CustomLeafletEvent) => {
        if (props?.custom === "moveFromPoint") {
            handleClick(map);
        }
    });
    return <FromPointControlView position="bottomright" />;
};

type CustomLeafletEvent = LeafletEvent & { custom?: "moveFromPoint" | "moveToPoint"; };