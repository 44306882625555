import { FC } from "react";
import { useRecoilValue } from "recoil";
import { routeSearchState } from "../../../stores";

// 経路案内 ※方向

export const RouteMessageNaviAngle: FC = () => {
    const { naviAngle } = useRecoilValue(routeSearchState);
    if (!naviAngle) return null;
    return <div>{naviAngle}</div>;
};
