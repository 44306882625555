import { selector } from "recoil";
import { loadingRouteSearchState } from "./loadingRouteSearchState";
import { routeSearchState } from "./routeSearchState";
import { routeSearchArrivedState } from "./routeSearchArrivedState";
import { routeSearchNotFoundState } from "./routeSearchNotFoundState";
import { isShowRouteMeterState } from "./isShowRouteMeterState";
import { isUseRouteSearchState } from "./isUseRouteSearchState";
import { SameFloorState } from "./SameFloorState";


export const isShowRouteMessageState = selector<boolean>({
    key: "isShowRouteMessageState",
    get: ({ get }) => {
        const isUseRouteSearch = get(isUseRouteSearchState);
        const isArrived = get(routeSearchArrivedState);
        const isNotFound = get(routeSearchNotFoundState);
        const isLoading = get(loadingRouteSearchState);
        const { naviAngle } = get(routeSearchState);
        const { naviRoute } = get(routeSearchState);
        const isShowRouteMeter = get(isShowRouteMeterState);
        const sameFloor = get(SameFloorState);

        // 全体OFF
        if (!isUseRouteSearch) return false;

        // グループ1
        if (isArrived) return true;
        if (isNotFound) return true;
        if (isLoading) return true;

        // グループ2
        if (naviAngle) return true;
        if (naviRoute) return true;
        if (isShowRouteMeter && !isNotFound) return true;
        if (!sameFloor) return true;

        return false;
    }
});
