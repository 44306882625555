import {
    ReactNode,
    FC
} from "react";
import { useFade } from "../../../../hooks/useFade";
import { FadeStyleOff } from "./FadeStyleOff";
import { FadeStyleOn } from "./FadeStyleOn";

export const FadeStyle: FC<{
    children: ReactNode;
}> = ({ children }) => {
    const fade = useFade();
    if (fade) {
        return <FadeStyleOn>{children}</FadeStyleOn>;
    } else {
        return <FadeStyleOff>{children}</FadeStyleOff>;
    }
};
