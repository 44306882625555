import { CSSProperties, FC } from "react";
import { Destination } from "../generated/graphql";
import { lang } from "../functions/lang";

export const DestinationPopupBody: FC<{
    destination: Destination | null;
}> = ({ destination }) => {
    const { name, image_url, detail_url } = destination || {};

    if (!name) {
        return null;
    }

    if (name && !image_url) {
        return (
            <a
                href={detail_url}
                target="_blank"
            >
                <div className="h-100 w-100 d-flex justify-content-center align-items-center">{name}</div>
            </a>
        );
    }

    if (name && image_url) {
        return (
            <>
                <div className="mb-2">{name}</div>
                <a
                    href={detail_url}
                    target="_blank"
                    style={{ display: "inline-block", pointerEvents: "all" }}
                >
                    <img src={image_url} style={imgStyle} alt={lang('イメージ図')} />
                </a>
            </>
        );
    };
}

const imgStyle = {
    width: 200,
    height: 100,
    background: "#999",
    objectFit: "cover",
    opacity: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
} as CSSProperties;
