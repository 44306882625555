import { atom } from "recoil";
import { useGetPointQuery } from "../../generated/graphql";

type ToPointQueryStateType = Pick<ReturnType<typeof useGetPointQuery>, 'data' | 'loading'>;
export const toPointQueryState = atom<ToPointQueryStateType>({
    key: "toPointQueryState",
    default: {
        data: {} as ToPointQueryStateType['data'],
        loading: true,
    } as ToPointQueryStateType,
});
