import { selector } from "recoil";
import { DistanceOfPoint } from "../../generated/graphql";
import { distanceOfPointsEsQueryState } from "../distanceAtoms/distanceOfPointsEsQueryState";


export const distanceOfPointsEsState = selector<DistanceOfPoint[]>({
    key: "distanceOfPointsEsState",
    get: ({ get }) => {
        const { data, loading } = get(distanceOfPointsEsQueryState);
        if (loading) return [] as DistanceOfPoint[];
        return (data?.pointsEs?.points || []) as DistanceOfPoint[];
    }
});
