import { GetRecoilValue } from "recoil";
import { Destination, Icon, Point } from "../generated/graphql";
import { destinationsState } from "./selectors/destinationsState";
import { leafletIconsState } from "./selectors/leafletIconsState";
import { LatLngTuple } from "leaflet";


export function _formatPoints(get: GetRecoilValue, points: Point[]): Point[] {
    const destinations = get(destinationsState);
    const icons = get(leafletIconsState);
    return points.map((point) => {
        return {
            ...point,
            destination: point.is_destination ? _getDestination(destinations, point.destination_id || '') : undefined,
            icon: _getIcon(icons, point.icon_id || '') || undefined,
        } as Point;
    });
}export function _formatPoints2(get: GetRecoilValue, point: Point): Point {
    const icons = get(leafletIconsState);
    return {
        ...point,
        icon: _getIcon(icons, point.icon_id || '') || undefined,
    } as Point;
}
export function _formatPolyList(polyList: LatLngTuple[][]): LatLngTuple[][] {
    if (polyList.length < 2) return polyList;

    const newPolyList = [polyList[0]];
    for (let i = 1; i < polyList.length; i++) {
        const end = newPolyList.length - 1;
        const poly1 = newPolyList[end];
        const poly2 = polyList[i];
        const p1s = poly1[0];
        const p2s = poly2[0];
        const p1e = poly1[poly1.length - 1];
        const p2e = poly2[poly2.length - 1];

        // 同じ
        if (_posEq(p1e, p2s)) {
            newPolyList[end] = newPolyList[end].concat(poly2);
            continue;
        }

        // 同じ ※正x逆
        if (_posEq(p1e, p2e)) {
            newPolyList[end] = newPolyList[end].concat(poly2.reverse());
            continue;
        }

        // 同じ ※逆x正
        if (_posEq(p1s, p2s)) {
            newPolyList[end] = newPolyList[end].reverse().concat(poly2);
            continue;
        }

        // 同じ ※逆x逆
        if (_posEq(p1s, p2e)) {
            newPolyList[end] = newPolyList[end].reverse().concat(poly2.reverse());
            continue;
        }

        newPolyList.push(poly2);
    }
    return newPolyList;
}
export function _getDestination(destinations: Destination[], id: string): Destination | undefined {
    if (!id) return undefined;
    for (const destination of destinations) {
        if (String(destination.id) === String(id)) {
            return destination;
        }
    }
    return undefined;
}
export function _getIcon(icons: Icon[], id: string): Icon | undefined {
    if (!id) return undefined;
    for (const icon of icons) {
        if (String(icon.id) === String(id)) {
            return icon;
        }
    }
    return undefined;
}
export function _posEq(p1: LatLngTuple, p2: LatLngTuple): boolean {
    return (p1[0] === p2[0] && p1[1] === p2[1]);
}

