import { FC, ReactNode } from "react";
import { useRecoilValue } from "recoil";
import { CRS as LeafletCRS, LatLngBounds } from "leaflet";
import { ImageOverlay } from "react-leaflet";

// local
import { MapLock } from "./features/MapLock";
import { MeterScaleControl } from "./features/MeterScaleControl";
import { getPointCenter } from "../../functions/map";

import {
    floorBoundState,
    maxBoundState,
    floorState,
    fromPointState,
} from "../../stores";
import { CustomMapContainer } from "./features/CustomMapContainer";
import { DummyMap } from "./DummyMap";
import { IfShowScale } from "../IfShowScale";
import { AliasArea } from "./AliasArea";

export const mapStyle = {
    width: "100%",
    height: "60vh",
    borderTop: "1px solid #dee2e6",
    borderBottom: "1px solid #dee2e6",
    background: "#999",
};

export const MapEx: FC<{
    children: ReactNode;
}> = ({ children }) => {
    const fromPoint = useRecoilValue(fromPointState);
    const floorBound = useRecoilValue(floorBoundState);
    const maxBounds = useRecoilValue(maxBoundState);
    const floor = useRecoilValue(floorState);

    const center = getPointCenter(fromPoint) || null;

    const { image_url } = floor;

    const isComplete = LeafletCRS && floor && image_url && fromPoint;

    if (!isComplete || !floorBound || !center || !maxBounds) {
        return <DummyMap />;
    }
    const leafletBounds = new LatLngBounds(floorBound[0], floorBound[1]);
    return (
        <MapLock>
            <CustomMapContainer center={center} maxBounds={maxBounds}>
                <IfShowScale>
                    <MeterScaleControl />
                </IfShowScale>
                <ImageOverlay url={image_url} bounds={leafletBounds} />
                {children}
                <AliasArea />
            </CustomMapContainer>
        </MapLock>
    );
};


