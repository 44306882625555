import { FC, ReactNode } from "react";
import {
    LeafletEventHandlerFnMap,
    LatLngTuple
} from "leaflet";
import { Marker } from "react-leaflet";
import { icon as leafletIcon } from "leaflet";
import { makeBottomOptions } from "./makeBottomOptions";

export const CustomImageMarker: FC<{
    position: LatLngTuple;
    iconUrl: string;
    height: number;
    width: number;
    eventHandlers?: LeafletEventHandlerFnMap;
    children?: ReactNode;
}> = ({ position, iconUrl, height, width, eventHandlers, children }) => (
    <Marker
        icon={leafletIcon({
            iconUrl,
            ...makeBottomOptions({ width, height }),
        })}
        position={position}
        eventHandlers={eventHandlers}
    >
        {children}
    </Marker>
);


