import { selector } from "recoil";
import { LatLngBoundsExpression as LeafletLatLngBoundsExpression } from "leaflet";
import { floorState } from "./floorState";


export const maxBoundState = selector<LeafletLatLngBoundsExpression | undefined>({
    key: "maxBoundState",
    get: ({ get }) => {
        const floor = get(floorState);
        const h = floor.height_size || 0;
        const w = floor.width_size || 0;
        if (h === 0 || w === 0) return undefined;
        return [
            [0 - h / 2, 0 - w / 2],
            [h + h / 2, w + w / 2],
        ];
    },
});
