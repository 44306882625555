import { FC } from "react";
import { useRecoilValue } from "recoil";
import { useParams } from "react-router-dom";
import { LatLngTuple } from "leaflet";
import { Tooltip as TooltipType } from "../../../../generated/graphql";
import { getTooltipCenter } from "../../../../functions/map";
import { linkMapOrMapRoute } from '../../../../functions/links';
import { routeSearchState } from "../../../../stores";
import { TooltipMarker } from "./TooltipMarker";

export const Tooltips: FC = () => {
    const { group, floor: floor_id, from, to } = useParams();
    const routeSearch = useRecoilValue(routeSearchState);

    // 対象のみ
    const baseTooltips = (routeSearch.tooltips || []).filter((tooltip) => {
        if (!tooltip) return false;
        if (!(tooltip?.floor_id == floor_id)) return false;
        return true;
    }) as TooltipType[];

    // format
    const tooltips = baseTooltips.map((tooltip) => {
        if (!tooltip) return null;
        const pos = getTooltipCenter(tooltip);
        const newFloor = tooltip.toFloorId || tooltip.fromFloorId || '';
        const url = linkMapOrMapRoute({ group, from, to: to || '', floor: newFloor });
        const body = tooltip.body || '';
        return { pos, url, body };
    }) as {
        pos: LatLngTuple;
        url: string;
        body: string;
    }[];

    return <>
        {tooltips.map((item, i: number) => {
            return <TooltipMarker key={i} position={item.pos} body={item.body} url={item.url} />;
        })}
    </>;
};
