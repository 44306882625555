import { useRecoilValue } from "recoil";
import {
    loadingFloorGroupState,
    loadingFloorState,
    loadingFromPointState,
    loadingIconsState,
    loadingRouteSearchState,
    loadingToPointState,
    openSelectMovingModalState
} from "../../../stores";

export function useLoading() {
    const loadingFloorGroup = useRecoilValue(loadingFloorGroupState);
    const loadingFloor = useRecoilValue(loadingFloorState);
    const loadingRouteSearch = useRecoilValue(loadingRouteSearchState);
    const loadingIcons = useRecoilValue(loadingIconsState);
    const loadingFromPoint = useRecoilValue(loadingFromPointState);
    const loadingToPoint = useRecoilValue(loadingToPointState);
    const isOpenModal = useRecoilValue(openSelectMovingModalState);
    if (loadingFloorGroup || loadingFloor || loadingIcons || loadingFromPoint || loadingToPoint) return true;
    if (!isOpenModal && loadingRouteSearch) return true;
    return false;
}
