import { selector } from "recoil";
import { RouteSearchResult } from "../../generated/graphql";
import { routeSearchQueryState } from "../atoms/routeSearchQueryState";


export const routeSearchState = selector<RouteSearchResult>({
    key: "routeSearchState",
    get: ({ get }) => {
        const { data, loading } = get(routeSearchQueryState);
        if (loading) return {} as RouteSearchResult;
        return (data?.routeSearch || {}) as RouteSearchResult;
    },
});
