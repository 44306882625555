import { selector } from "recoil";
import { DistanceOfPoint } from "../../generated/graphql";
import { distanceOfPointsStepQueryState } from "../distanceAtoms/distanceOfPointsStepQueryState";


export const distanceOfPointsStepState = selector<DistanceOfPoint[]>({
    key: "distanceOfPointsStepState",
    get: ({ get }) => {
        const { data, loading } = get(distanceOfPointsStepQueryState);
        if (loading) return [] as DistanceOfPoint[];
        return (data?.pointsStep?.points || []) as DistanceOfPoint[];
    }
});
