import { CSSProperties, FC } from "react";

export const DummyMap: FC = () => <div style={style} />;

export const style = {
    width: "100%",
    height: "60vh",
    borderTop: "1px solid #dee2e6",
    borderBottom: "1px solid #dee2e6",
    background: "#999",
} as CSSProperties;