import { linkMapRouteProps } from "../../functions/links/linkMapRoute";
import { linkHome } from "../../functions/links/linkHome";
import { linkMapOrMapRoute } from '../../functions/links';

// 必須確認
export function getLinkMapRouteProps({ group, floor, from, to }: {
    group: string;
    floor: string;
    from: string;
    to: string;
}): linkMapRouteProps | null {
    if (!group) return null;
    if (!floor) return null;
    if (!from) return null;
    return { group, floor, from, to };
}

export function myLinkMapOrMapRoute(params: linkMapRouteProps | null, skip: boolean) {
    if (!params) return linkHome();
    if (skip) return linkMapOrMapRoute(params);
    return linkMapOrMapRouteWithFade(params);
};

function linkMapOrMapRouteWithFade(search: linkMapRouteProps): string {
    const url = linkMapOrMapRoute(search);
    const query = new URLSearchParams();
    query.set('fade', 'on');
    return [
        url,
        query.toString(),
    ].join('?');
}
