import { FC } from "react";
import { DivIcon as LeafletDivIcon } from "leaflet";
import { Marker } from "react-leaflet";
import { useSetRecoilState } from "recoil";
import { Point } from "../../generated/graphql";
import { DestinationPopup } from "../../components/DestinationPopup";
import { currentPointState } from "../../stores";
import { getPointCenter } from '../../functions/map';
import { DestinationPopupBody } from "../DestinationPopupBody";


export const MarkerDestinationName: FC<{ destinationPoint: Point; }> = ({ destinationPoint }) => {
    const setCurrentPoint = useSetRecoilState(currentPointState);
    const center = getPointCenter(destinationPoint);

    // 目的地名
    const name = destinationPoint?.destination?.name || "";
    const texticon = new LeafletDivIcon({
        className: "text-nowrap",
        html: `<div class="cst-texticon"><span>${name}</span></div>`,
        iconAnchor: [-15, 20],
        popupAnchor: [0, -20],
    });

    if (!center) return null;
    if (!name) return null;
    return (
        <Marker icon={texticon}
            position={center}
            eventHandlers={{
                popupopen() { setCurrentPoint(destinationPoint); },
                popupclose() { setCurrentPoint(null); },
            }}
        >
            <DestinationPopup>
                <DestinationPopupBody destination={destinationPoint.destination} />
            </DestinationPopup>
        </Marker>
    );
};
