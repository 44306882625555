import { selector } from "recoil";
import { floorState } from "./floorState";


export const floorCenterState = selector<[number, number] | null>({
    key: "floorCenterState",
    get: ({ get }) => {
        const floor = get(floorState);
        const h = floor.height_size || null;
        const w = floor.width_size || null;
        if (h === null || w === null) return null;
        return [h / 2, w / 2];
    },
});
