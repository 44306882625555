import { FC } from "react";
import { useRecoilValue } from "recoil";
import { useParams } from "react-router-dom";
import { FloorTab } from "../../../components/FloorTab";
import { linkMap } from '../../../functions/links/linkMap';
import {
    floorState,
    floorsState
} from "../../../stores";


export const MapFloorTab: FC = () => {
    const floor = useRecoilValue(floorState);
    const floors = useRecoilValue(floorsState);
    const { group, from } = useParams();
    return (
        <FloorTab
            floors={floors}
            currentFloorId={floor.id || ""}
            to={({ floor }) => {
                return linkMap({ group, floor, from });
            }} />
    );
};
