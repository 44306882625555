import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import { linkHome } from "../../functions/links/linkHome";
import { QrReaderEx } from "./features/QrReaderEx";
import { SearchEnd } from "./features/SearchEnd";
import { FadeStyle } from "./features/FadeStyle";
import { lang } from "../../functions/lang";

export const Layout: FC<{
    showSearchEnd?: boolean;
    showCamera?: boolean;
    children: ReactNode;
}> = ({
    children,
    showSearchEnd = false,
    showCamera = true,
}) => (
        <FadeStyle>
            <nav className="navbar bg-dark navbar-dark">
                <div className="container-fluid">
                    <Link className="navbar-brand" to={linkHome()}>
                        {lang('ここどこQR / エリアナビ')}
                    </Link>
                    {showSearchEnd && <SearchEnd />}
                </div>
            </nav>
            {children}
            <div style={{ height: 100 }} />
            {showCamera && <QrReaderEx />}
        </FadeStyle>
    );
