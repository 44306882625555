import { FC } from "react";
import { useSetCurrentParam } from "../hooks/useSetCurrentParam";
import { useResetOpenSelectMovingModal } from "../hooks/useResetOpenSelectMovingModal";
import { PageView } from "./PageView";

export const PageController: FC = () => {

    // 反映
    useSetCurrentParam();

    // モーダル初期化
    useResetOpenSelectMovingModal();

    return <PageView />;
};
