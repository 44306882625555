import { ApolloClient, InMemoryCache } from "@apollo/client";

export const client = new ApolloClient({
    uri: import.meta.env.VITE_APP_GRAPHQL_URL,
    cache: new InMemoryCache(),
    defaultOptions: {
        // watchQuery: {
        //     fetchPolicy: "no-cache",
        //     errorPolicy: "ignore",
        // },
        // query: {
        //     fetchPolicy: "no-cache",
        //     errorPolicy: "all",
        // },
    },
});
