import { FC } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useNavigate, useParams } from "react-router-dom";
import { Map as LeafletMap } from "leaflet";
import { FromPointControl } from "../../../../features/FromPointControl";
import { getPointCenter } from "../../../../functions/map";
import { linkMap } from '../../../../functions/links/linkMap';
import { currentPointState, fromPointState } from "../../../../stores";

export const CustomPointControl: FC = () => {
    const fromPoint = useRecoilValue(fromPointState);
    const setCurrentPoint = useSetRecoilState(currentPointState);
    const { group, from } = useParams();
    const navigate = useNavigate();

    function onClickFromPoint(map: LeafletMap): void {
        const center = getPointCenter(fromPoint);
        if (!center) return;
        setCurrentPoint(fromPoint);
        map.setView(center);
        const newFloor = fromPoint.floor_id || "";
        setTimeout(() => {
            navigate(linkMap({ group, floor: newFloor, from }));
        });
    }

    return (
        <>
            <FromPointControl onClick={onClickFromPoint} />
        </>
    );
};
