
export function makeBottomOptions({ width, height, offsetHeight = 0, tooltipPosition = 'bottom' }: {
    width: number;
    height: number;
    offsetHeight?: number;
    tooltipPosition?: 'bottom' | 'right';
}): {
    iconSize: [number, number];
    iconAnchor: [number, number];
    popupAnchor: [number, number];
    tooltipAnchor: [number, number];
} {
    return {
        iconSize: [width, height],
        iconAnchor: [width / 2, height + offsetHeight],
        popupAnchor: [0, -height - offsetHeight],
        tooltipAnchor: (() => {
            switch (tooltipPosition) {
                case 'right': return [width / 2, -height / 2 - offsetHeight];
                case 'bottom': default: return [0, -height];
            }
        })(),
    };
}
