import { FC } from "react";
import { Category } from "../../../generated/graphql";
import { useRecoilValue } from "recoil";
import { categoriesState } from "../../../stores";
import { useSearchParams } from "react-router-dom";
import { setQueryValue } from "../functions/setQueryValue";

export const SelectCategory: FC = () => {
    const key = "q_category";
    const categories = useRecoilValue(categoriesState);
    const [query, setQuery] = useSearchParams();
    const value = query.get(key) || '';
    function setValue(value: string) {
        setQuery((query) => setQueryValue(query, key, value));
    }
    return (
        <select
            onChange={(e) => { setValue(e.target.value); }}
            value={value}
            className="form-select"
        >
            <option value="">すべてのカテゴリ</option>
            {(categories || [])
                .filter((e: Category, i: number) => e?.id && e?.name)
                .map((e: Category, i: number) => (
                    <option key={i} value={e.id}>
                        {e.name}
                    </option>
                )
                )}
        </select>
    );
};