import { selector } from "recoil";
import { LatLngTuple } from "leaflet";
import { floorRoutesState } from "./floorRoutesState";


export const firstFloorRoutePathPointState = selector<LatLngTuple | null>({
    key: "firstFloorRoutePathPointState",
    get: ({ get }) => {
        const floorRoutes = get(floorRoutesState);
        if (!floorRoutes) return null;
        if (!floorRoutes[0]) return null;
        if (!floorRoutes[0].paths) return null;
        if (!floorRoutes[0].paths[0]) return null;
        return floorRoutes[0].paths[0] as LatLngTuple;
    },
});
