import { FC } from "react";
import { useRecoilValue } from "recoil";
import { MapRoutePartsCard } from "./MapRoutePartsCard";
import { toPointState } from "../../../stores";
import { lang } from "../../../functions/lang";


export const ToPointDetail: FC = () => {
    const toPoint = useRecoilValue(toPointState);
    const { destination } = toPoint;
    if (!destination) return null;
    if (!toPoint) return null;
    return (
        <>
            <h6>{lang('目的地')}</h6>
            <MapRoutePartsCard destination={destination} />
        </>
    );
};
