import { FC } from "react";
import { useRecoilValue } from "recoil";
import { isShowRouteMessageState } from "../../../stores";
import { RouteMessageNaviTotal } from "./RouteMessageNaviTotal";

// 経路情報
export const BorderAndRouteMessageNaviTotal: FC = () => {
    const isShow = useRecoilValue(isShowRouteMessageState);
    if (!isShow) return null;
    return <BorderAndRouteMessageNaviTotalView />
};

const BorderAndRouteMessageNaviTotalView: FC = () => {
    return (
        <>
            <div className="border-top" />
            <div className="container my-2">
                <RouteMessageNaviTotal />
            </div>
        </>
    );
}