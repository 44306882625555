import { FC } from "react";
import { useMapEvent } from 'react-leaflet';
import { useRecoilValue, useSetRecoilState } from "recoil";
import { mapBearingConfigState, mapBearingState } from "../stores";
import { RotateControl as LeafletRotateControl } from "../plugins/leaflet/RotateControl";
import { IfBlock } from '../components/IfBlock';

export const RotateControl:FC = () => {
    const { is_use } = useUpdateRotate();
    return (
        <IfBlock visible={is_use}>
            <LeafletRotateControl />
        </IfBlock>
    )
}

function useUpdateRotate(): {
    is_use: boolean;
} {
    const setBearing = useSetRecoilState(mapBearingState);
    const { is_use } = useRecoilValue(mapBearingConfigState);

    // @ts-ignore
    const map = useMapEvent('updateRotate', ({ bear }) => {
        if (is_use === false) return;
        setBearing(bear || 0);
        map.setBearing(bear);
    });

    return { is_use }
};
