import { CSSProperties, FC, ReactNode, cloneElement } from "react";

export const MapIcon: FC<{
    color: string
    children: ReactNode,
}> = ({ color, children }) => {
    const newChildren = cloneElement(<>{children}</>, { style: styles.icon });
    return (
        <div style={{ ...styles.circle, background: color }}>{newChildren}</div>
    );
};

const styles = {
    circle: {
        position: "absolute",
        color: "#fff",
        fontSize: 18,
        width: 30,
        height: 30,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 25,
        marginTop: -6,
    } as CSSProperties,
    icon: {
        display: "inline-flex",
        verticalAlign: "middle",
    } as CSSProperties,

}