import { FC, ReactNode } from "react";
import { useRecoilValue } from "recoil";
import { floorPpmState, isShowScaleState } from "../stores";
import { IfBlock } from "../components/IfBlock";
export const IfShowScale: FC<{
    children: ReactNode
}> = ({ children }) => {
    const isShowScale = useRecoilValue(isShowScaleState);
    const ppm = useRecoilValue(floorPpmState);
    const visible = isShowScale && ppm !== 0;
    return <IfBlock visible={visible}>{children}</IfBlock>
};
