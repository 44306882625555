import { FC, ReactNode } from "react";
import { useRecoilValue } from "recoil";
import { IfBlock } from "../components/IfBlock";
import { isToPointInFloorState } from "../stores";

export const IfToPointInFloor: FC<{
    children: ReactNode
}> = ({ children }) => {
    const visible = useRecoilValue(isToPointInFloorState);
    return <IfBlock visible={visible}>{children}</IfBlock>
}
