import { selector } from "recoil";
import { Point } from "../../generated/graphql";
import { routeSearchState } from "./routeSearchState";
import { floorState } from "./floorState";


export const floorlineStartPointState = selector<Point | null>({
    key: "floorlineStartPointState",
    get: ({ get }) => {
        const floor = get(floorState);
        const routeSearch = get(routeSearchState);
        const { lineStart } = routeSearch;
        if (!lineStart) return null;
        return lineStart.floor_id == floor.id ? lineStart : null;
    },
});
