import { selector } from "recoil";
import { DistanceOfPoint } from "../../generated/graphql";
import { distanceOfPointsEvQueryState } from "../distanceAtoms/distanceOfPointsEvQueryState";

// selector

export const distanceOfPointsEvState = selector<DistanceOfPoint[]>({
    key: "distanceOfPointsEvState",
    get: ({ get }) => {
        const { data, loading } = get(distanceOfPointsEvQueryState);
        if (loading) return [] as DistanceOfPoint[];
        return (data?.pointsEv?.points || []) as DistanceOfPoint[];
    }
});
