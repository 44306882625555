import { FC } from "react";
import { PageView } from "./PageView";
import { useSetCurrentParam } from "../hooks/useSetCurrentParam";

export const PageController: FC = () => {
    useSetCurrentParam();
    // end hooks
    return <PageView />
};

