import { useEffect, useState } from "react";
import { Destination } from "../../../generated/graphql";
import { useSearchDestinationLazyQuery } from "../graphql";
import { useParams, useSearchParams } from "react-router-dom";
import { categoriesState, floorsState, loadingFloorGroupState } from "../../../stores";
import { useRecoilValue } from "recoil";

export function useSearchApi(): {
    loading: boolean;
    destinations: Destination[];
    total: number | undefined;
    hasMore: boolean;
    q_word: string;
    q_floor: string;
    q_category: string;
    categoryName: string;
    floorName: string;
    loadMorePage: (page: number) => void;
} {

    const { group, from } = useParams();
    const [query] = useSearchParams();
    const categories = useRecoilValue(categoriesState);
    const floors = useRecoilValue(floorsState);
    const loadingFloorGroup = useRecoilValue(loadingFloorGroupState);
    const keyword = query.get('q') || '';
    const category_id = query.get('q_category') || '';
    const floor_id = query.get('q_floor') || '';

    const categoryName = categories.filter(category => category.id === category_id)[0]?.name || '';
    const floorName = floors.filter(floor => floor.id === floor_id)[0]?.name || '';

    const defaultApiReult = {
        destinations: [],
        loading: true,
        q_word: keyword,
        q_category: category_id,
        q_floor: floor_id,
        categoryName,
        floorName,
        total: undefined,
    };

    // 全データ
    const [apiResult, setApiResult] = useState<{
        destinations: Destination[]
        loading: boolean,
        q_word: string,
        q_category: string,
        q_floor: string,
        categoryName: string,
        floorName: string,
        total: number | undefined,
    }>(defaultApiReult);

    // API検索
    const [getDestinations, { data, loading }] = useSearchDestinationLazyQuery({});

    // API 再検索
    useEffect(() => {
        setApiResult(defaultApiReult);
        getDestinations({ variables: { group, keyword, from, category_id, floor_id, page: 1, first: 5 } });
    }, [from, getDestinations, group, keyword, category_id, floor_id]);

    // merge load more
    useEffect(() => {
        if (loading) return;
        if (loadingFloorGroup) return;
        setApiResult((apiResult) => {
            let destinations = apiResult.destinations;
            const newDestinations = (data?.searchDestination3?.data || []) as Destination[];
            const page = data?.searchDestination3?.paginatorInfo?.currentPage;
            if (page === 1) {
                destinations = [];
            }
            return {
                ...apiResult,
                q_category: category_id,
                q_floor: floor_id,
                q_word: keyword,
                loading,
                categoryName,
                floorName,
                total: data?.searchDestination3?.paginatorInfo?.total,
                destinations: [...destinations, ...newDestinations] as Destination[]
            };
        });
    }, [data, loading, loadingFloorGroup, setApiResult]);

    return {
        ...apiResult,
        loading: loading || apiResult.loading,
        hasMore: data?.searchDestination3?.paginatorInfo?.hasMorePages || false,
        loadMorePage: async (page: number) => {
            await getDestinations({ variables: { group, keyword, category_id, floor_id, from, page, first: 5 } });
        },
    };
}
