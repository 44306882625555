import { useEffect } from "react";

export function usePushDataLayer({ loading, total, keyword, floor, category }: {
    loading: boolean;
    total: number | undefined;
    keyword: string;
    floor: string;
    category: string;
}) {
    useEffect(() => {
        if (loading) return;
        if (total === undefined) return;
        if (window.dataLayer) {
            window.dataLayer.push({
                event: '検索完了',
                total,
                keyword,
                floor,
                category,
            });
            console.log({ keyword, floor, category, total });
        }
    }, [loading, total, keyword]);
}
