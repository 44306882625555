import { selector } from "recoil";
import { Route } from "../../generated/graphql";
import { routeSearchState } from "./routeSearchState";
import { floorState } from "./floorState";


export const floorRoutesState = selector<Route[]>({
    key: "floorRoutesState",
    get: ({ get }) => {
        const floor = get(floorState);
        const routeSearch = get(routeSearchState);
        return ((routeSearch.routes || []) as Route[]).filter(
            (route: Route) => {
                return route.floor_id == floor.id;
            }
        );
    },
});
