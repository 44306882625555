import {
    useRef,
    CSSProperties,
    FC,
    ReactNode,
    useEffect
} from "react";


export const SafeTouch: FC<{
    children: ReactNode;
    read: boolean;
    setMapLock: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
    children, read, setMapLock,
}) => {
        const ref = useRef<HTMLDivElement>(null);
        const style = {
            width: "100%",
            height: "100%",
        } as CSSProperties;

        useEffect(() => {
            if (!ref || !ref.current) return;
            const current = ref.current;
            current.addEventListener("touchstart", onTouchStart, {
                passive: false,
            });
            return () => {
                current.removeEventListener("touchstart", onTouchStart);
            };
        }, [ref]);

        // end hooks
        function onTouchStart(e: TouchEvent): void {
            if (e.touches.length > 1) {
                e.preventDefault();
            }
        }

        function onTouchMove(e: React.TouchEvent): void {
            if (e.touches.length === 1) {
                if (!read) {
                    setMapLock(true);
                }
            }
        }

        return (
            <div ref={ref} style={style} onTouchMove={onTouchMove}>
                {children}
            </div>
        );
    };
