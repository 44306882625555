import { selector } from "recoil";
import { floorGroupState } from "./floorGroupState";


export const naviEndNameState = selector<string>({
    key: "naviEndNameState",
    get: ({ get }) => {
        const floorGroup = get(floorGroupState);
        return floorGroup.navi_end_name || "...";
    },
});
