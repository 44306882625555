import { FC } from "react";
import { Accordion } from "@mui/material";
import { AccordionSummary } from "@mui/material";
import { AccordionDetails } from "@mui/material";
import { CategoryList } from "../features/CategoryList";
import { styled } from "@mui/material";
import { Typography } from "@mui/material";
import { MuiExpandMoreIcon } from "../../../components/mui";

export const SelectCategory: FC = () => (
    <Accordion>
        <AccordionSummary
            expandIcon={<MuiExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <TypographyEx>カテゴリから選ぶ</TypographyEx>
        </AccordionSummary>
        <AccordionDetails className="p-0">
            <div className="w-100">
                <CategoryList />
            </div>
        </AccordionDetails>
    </Accordion>
);

const TypographyEx = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(15),
    fontWeight: "normal",
}));
