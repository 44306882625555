import { useEffect } from "react";
import { Floor } from "../../../generated/graphql";

// 画像読み込み

export const useLoadFloorImage = (floors: Floor[]) => {
    return useEffect(() => {
        floors.map((floor: Floor) => {
            const img = new Image();
            if (floor.image_url) img.src = floor.image_url;
            return null;
        });
    }, [floors]);
};
