import { selector } from "recoil";
import { fromPointState } from "./fromPointState";
import { floorState } from "./floorState";


export const isFromPointInFloorState = selector<boolean>({
    key: "isFromPointInFloorState",
    get: ({ get }) => {
        const floor = get(floorState);
        const fromPoint = get(fromPointState);
        return floor.id == fromPoint.floor_id;
    },
});
