import { selector } from "recoil";
import { floorGroupState } from "./floorGroupState";


export const isUseRouteSearchState = selector<boolean>({
    key: "isUseRouteSearchState",
    get: ({ get }) => {
        const floorGroup = get(floorGroupState);
        return floorGroup?.is_use_route_search || false;
    },
});
