import { selector } from "recoil";
import { routeSearchState } from "./routeSearchState";


export const routeSearchArrivedState = selector<boolean>({
    key: "routeSearchArrivedState",
    get: ({ get }) => {
        return get(routeSearchState).isArrived || false;
    },
});
