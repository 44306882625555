import { FC, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { useMap } from 'react-leaflet';
import { firstFloorRoutePathPointState, floorState } from "../../../stores";


export const ChangeFloorEvent: FC = () => {
    const map = useMap();
    const pos = useRecoilValue(firstFloorRoutePathPointState);
    const floor = useRecoilValue(floorState);
    useEffect(() => {
        if (!floor) return;
        if (!pos) return;
        const z = floor.zoom_start as number;
        map.setView(pos, z, { animate: false });

    }, [floor, map, pos]);
    return null;
};
