import { selector } from "recoil";
import { floorGroupState } from "./floorGroupState";


export const floorGroupCustomDataUrlState = selector<string | null>({
    key: "floorGroupCustomDataUrlState",
    get: ({ get }) => {
        const floorGroup = get(floorGroupState);
        return floorGroup?.custom_data_url || null;
    },
});
