import { selector } from "recoil";
import { MoveOption } from "../../generated/graphql";
import { currentFloorMoveState } from "../distanceAtoms/currentFloorMoveState";


export const currentMoveOptionState = selector<MoveOption>({
    key: "currentMoveOptionState",
    get: ({ get }) => {
        const move = get(currentFloorMoveState);
        switch (move) {
            case "ANY": return { useEv: true, useEs: true, useStep: true, } as MoveOption;
            case "EV": return { useEv: true, useEs: false, useStep: false, } as MoveOption;
            case "ES": return { useEv: false, useEs: true, useStep: false, } as MoveOption;
            case "STEP": return { useEv: false, useEs: false, useStep: true, } as MoveOption;
        }
    },
});
