import axios from "axios";
import { Location, LoaderFunction } from "react-router-dom";


export function sendLogPublicWhenSelectPointRoute({
    location,
    params,
    search_keyword = '',
    search_total = '',
    search_category = '',
    search_floor = '',
    select_point_id = '',
    select_point_name = '',
}: {
    location: Location,
    params: Parameters<LoaderFunction>[0]["params"],
    search_keyword?: string,
    search_total?: string,
    search_category?: string,
    search_floor?: string,
    select_point_id?: string,
    select_point_name?: string,
}) {
    const query = new URLSearchParams(location.search);
    const { group, floor, from, to, code } = params;
    const q = query.get('q');
    const search = location.search;
    const pathname = location.pathname;

    const event_name = 'select_point_route';

    axios.post(import.meta.env.VITE_APP_LOG_URL || "", {
        floor_group_id: group,
        from_point_id: from,
        to_point_id: to,
        floor_id: floor,
        search: search,
        pathname: pathname,
        event_name,
        search_keyword,
        search_category,
        search_floor,
        search_total,
        select_point_id,
        select_point_name,
        code,
        q: q,
    });
}
