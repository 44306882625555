import { FC, ReactNode } from "react";
import { useRecoilValue } from "recoil";
import { isShowRouteMeterState, } from "../stores";
import { IfBlock } from "../components/IfBlock";
export const IfShowRouteMeter: FC<{
    children: ReactNode
}> = ({ children }) => {
    const visible = useRecoilValue(isShowRouteMeterState);
    return <IfBlock visible={visible}>{children}</IfBlock>
};
