import { FC, useEffect } from "react";
import { Floor } from "../../../../generated/graphql";
import { useQrcodeSuspenseQuery } from "../../graphql";
import { getLinkMapRouteProps, myLinkMapOrMapRoute } from "../../functions";
import { useLoadFloorImage } from "../../hooks/useLoadFloorImage";
import { QrAnimeBlockView } from "./presenter";
import { useParams, useSearchParams } from "react-router-dom";
import { getToPoint } from "../../../../functions/storage";


export const QrAnimeSuspenseBlock: FC<{
}> = () => {

    const { group, code } = useParams();
    const [query] = useSearchParams();
    const to = query.get('to') || getToPoint() || '';
    const skip = !!query.get('skip') as boolean;

    // QRから情報取得
    const { data } = useQrcodeSuspenseQuery({
        skip: !group || !code,
        variables: { group, code, to },
    });

    // データ設定
    const floor: string = data?.fromPoint?.floor_id || "";
    const from: string = data?.fromPoint?.id || "";
    const animeUrl: string = data?.floorGroup?.opening_movie_url || import.meta.env.VITE_APP_LOGO_ANIME_URL || '';
    const floors: Floor[] = (data?.floorGroup?.floors || []) as Floor[];

    // URL
    const params = getLinkMapRouteProps({ group, floor, from, to });
    const navigateUrl = myLinkMapOrMapRoute(params, skip);

    // 画像先読み
    useLoadFloorImage(floors);

    return (
        <QrAnimeBlockView
            animeUrl={animeUrl}
            navigateUrl={navigateUrl}
        />
    );
};