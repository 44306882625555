import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { useMap } from 'react-leaflet';
import { mapBearingConfigState, naviAngleBearingState } from "../../../stores";


export const RoadMapBearing = () => {
    const map = useMap();
    const bearing = useRecoilValue(naviAngleBearingState);
    const { is_auto } = useRecoilValue(mapBearingConfigState);
    useEffect(() => {
        if (is_auto === false) return;
        map.fireEvent("updateRotate", { bear: bearing });
    }, [is_auto, bearing, map]);
    return null;
};
