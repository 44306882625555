import { selector } from "recoil";
import { baseFloorGroupState } from "../atoms/baseFloorGroupState";

// select, loading

export const loadingFloorGroupState = selector<boolean>({
    key: "loadingFloorGroupState",
    get: ({ get }) => {
        const data = get(baseFloorGroupState);
        return data?.loading || false;
    }
});
