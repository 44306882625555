import { selector } from "recoil";
import { pointTypeState } from "./pointTypeState";


export const isShowMarkerPopupCardState = selector<boolean>({
    key: "isShowMarkerPopupCardState",
    get: ({ get }) => {
        const pointType = get(pointTypeState);
        if (pointType === "notfound") return false;
        return true;
    }
});
