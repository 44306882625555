import { FC, ReactNode } from "react";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { linkMapOrMapRoute } from '../../../functions/links';
import { categoriesState, floorsState, fromFloorIdState, naviStartNameState } from "../../../stores";
import { sendLogPublicWhenSearchEnd } from "../../../features/logPublic/sendLogPublicWhenSearchEnd";
import { sendLogPublicWhenSelectPointSearch } from "../../../features/logPublic/sendLogPublicWhenSelectPointSearch";

// 検索画面
export const ChangeToPointButton: FC<{
    toPointId: string;
    name: string;
}> = ({ toPointId, name }) => {
    const { url } = useLink({ toPointId });
    const { handleClick } = useSendLogPublic({ toPointId , name });
    const naviStartName = useRecoilValue(naviStartNameState);
    return (
        <Link to={url} onClick={handleClick} className="btn btn-sm btn-danger w-100">
            {naviStartName}
        </Link>
    );
};

function useLink({ toPointId }: { toPointId: string }): {
    url: string
} {
    const { group, floor, from } = useParams();
    const fromFloorId = useRecoilValue(fromFloorIdState);
    const url = linkMapOrMapRoute({ group, from, to: toPointId, floor: fromFloorId || floor });

    return { url }
}

function useSendLogPublic({
    toPointId = '',
    name = '',
}: {
    toPointId: string;
    name: string;
}): {
    handleClick: () => void
} {
    const location = useLocation();
    const params = useParams();
    const [query] = useSearchParams();
    const categories = useRecoilValue(categoriesState);
    const floors = useRecoilValue(floorsState);

    const handleClick = () => {
        const search_keyword = query.get('q') || '';
        const search_category = categories.filter(category => category.id === query.get('q_category'))[0]?.name || '';
        const search_floor = floors.filter(floor => floor.id === query.get('q_floor'))[0]?.name || '';
        sendLogPublicWhenSearchEnd({
            location, params,
            search_keyword,
            search_category,
            search_floor,
        });
        sendLogPublicWhenSelectPointSearch({
            location, params,
            select_point_id: toPointId,
            select_point_name: name,
            search_keyword,
            search_category,
            search_floor,
        });
    }
    return { handleClick }
}
