import { SuspenseQueryHookOptions, gql, useSuspenseQuery } from '@apollo/client';
import { Destination } from "../../generated/graphql"

const QUERY = gql`
query detailPoint($id: ID!) {
    Destination(id: $id) {
        id
        name
        body
        odaimoku {
            title
            md
        }
        image_url
        floor_id
        detail_url
    }
}
`;
type QueryData = {
    Destination: Destination,
}
type QueryVariables = {
    id: string;
};
export function useSuspenseDestinationQuery(options: SuspenseQueryHookOptions<QueryData, QueryVariables>) {
    return useSuspenseQuery<QueryData, QueryVariables>(QUERY, options);
}