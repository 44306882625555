import { FC } from "react";
import { Layout } from "../../../features/MainLayout";
import { MapForMapRoute } from "../components/MapForMapRoute";
import { ToPointDetail } from "../components/ToPointDetail";
import { ChangeFloorEvent } from "../components/ChangeFloorEvent";
import { BorderAndMapFloorTab } from "../components/BorderAndMapFloorTab";
import { BorderAndRouteMessageNaviTotal } from "../components/BorderAndRouteMessageNaviTotal";
import { RoadMapBearing } from "../components/RoadMapBearing";
import { IfShowMarker } from "../../../features/IfShowMarker";
import { MarkerPopupCard } from "../features/MarkerPopupCard";

export const PageView: FC = () => {
    return (
        <Layout showSearchEnd>
            {/* 経路情報 */}
            <BorderAndRouteMessageNaviTotal />

            {/* 目的地情報(一時) */}
            <IfShowMarker>
                <div className="border-top" />
                <div className="container my-2">
                    <MarkerPopupCard />
                </div>
            </IfShowMarker>

            {/* フロア選択 */}
            <BorderAndMapFloorTab />

            {/* 地図 */}
            <div className="mb-4">
                <MapForMapRoute>
                    <RoadMapBearing />
                    <ChangeFloorEvent />
                </MapForMapRoute>
            </div>
            {/* 目的地情報(確定) */}
            <div className="container my-4">
                <ToPointDetail />
            </div>
        </Layout>
    );
};
