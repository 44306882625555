import { FC } from "react";
import { LoadPoints } from "../../features/LoadData/LoadPoints";
import { LoadDestinations } from "../../features/LoadData/LoadDestinations";
import { LoadToPoint } from "../../features/LoadData/LoadToPoint";
import { LoadFromPoint } from "../../features/LoadData/LoadFromPoint";
import { LoadFloorGroup } from "../../features/LoadData/LoadFloorGroup";
import { LoadRouteSearch } from "../../features/LoadData/LoadRouteSearch";
import { WatchToPoint } from "./components/WatchToPoint";
import { CustomLoadigModal } from "./components/CustomLoadigModal";
import { CustomSelectMovingModal } from "./components/CustomSelectMovingModal";
import { PageController } from "./parts/PageController";
import { LoadDistanceEs } from "../../features/LoadData/LoadDistanceEs";
import { LoadDistanceEv } from "../../features/LoadData/LoadDistanceEv";
import { LoadDistanceStep } from "../../features/LoadData/LoadDistanceStep";

export const App: FC = () => {
    return <>
        <WatchToPoint />
        <CustomLoadigModal />
        <CustomSelectMovingModal />
        <PageController />
        <LoadFromPoint />
        <LoadToPoint />
        <LoadFloorGroup />
        <LoadDestinations />
        <LoadPoints />
        <LoadRouteSearch />
        <LoadDistanceEs />
        <LoadDistanceEv />
        <LoadDistanceStep />
    </>
};


