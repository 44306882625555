import { selector } from "recoil";
import { DistanceOfPoint } from "../../generated/graphql";
import { isShowRouteMeterState } from "../selectors/isShowRouteMeterState";
import { toPointState } from "../selectors/toPointState";
import { distanceOfPointsEsState } from "./distanceOfPointsEsState";


export const distanceOfToPointEsState = selector<string>({
    key: "distanceOfToPointEsState",
    get: ({ get }) => {
        const toPoint = get(toPointState);
        const distances = get(distanceOfPointsEsState);
        const distance = (distances.filter((item) => item.to == toPoint.id)[0] || {}) as DistanceOfPoint;
        const isShowRouteMeter = get(isShowRouteMeterState);

        if (!isShowRouteMeter) return '';
        if (!distance) return '- m';
        if (!distance.cost) return '- m';
        return distance.cost + ' m';
    }
});
