import { selector } from "recoil";
import { getNumber } from "../../functions/utils";
import { floorState } from "./floorState";


export const leafletZoomNameHideState = selector<number>({
    key: "leafletZoomNameHideState",
    get: ({ get }) => {
        const floor = get(floorState);
        return getNumber(floor?.zoom_name_hide, -1);
    },
});
