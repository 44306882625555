import { selector } from "recoil";
import { floorGroupState } from "./floorGroupState";


export const IsSkipSelectMoving = selector<boolean>({
    key: "IsSkipSelectMoving",
    get: ({ get }) => {
        const floorGroup = get(floorGroupState);
        return floorGroup?.is_skip_select_moving || false;
    }
});
