import { FC } from "react";
import { DestinationBlockView } from "./presenter";
import { Center } from "../../../../components/Center";
import { useSuspenseDestinationQuery } from "../../graphql";
import { Destination } from "../../../../generated/graphql";

export const DestinationSuspenseBlock: FC<{
    destinationId: string
}> = ({ destinationId }) => {
    const destination = useLoadDestination({ destinationId });

    // end hooks
    if (!destination) return <Center title="404: 存在しないページです" />;

    return <DestinationBlockView
        imageUrl={destination.image_url || ''}
        name={destination.name || ''}
        body={destination.body || ''}
        odaimoku={destination.odaimoku || []}
    />;
};

function useLoadDestination({ destinationId }: {
    destinationId: string
}): Destination | null {
    const { data } = useSuspenseDestinationQuery({
        skip: !destinationId,
        variables: {
            id: destinationId,
        },
    });
    return data?.Destination || null;
};