import { selector } from "recoil";
import { ActiveFloorMoveType } from "../../generated/graphql";
import { floorGroupState } from "./floorGroupState";


export const activeFloorMoveState = selector<ActiveFloorMoveType>({
    key: "activeFloorMoveState",
    get: ({ get }) => {
        const floorGroup = get(floorGroupState);
        return floorGroup?.activeFloorMove || {} as ActiveFloorMoveType;
    },
});
