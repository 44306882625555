import { RouterProvider } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { client } from "./features/client";
import { RecoilRoot } from "recoil";
import { router } from "./features/router";

function App() {
    return (
        <RecoilRoot>
            <ApolloProvider client={client}>
                < RouterProvider router={router} />
            </ApolloProvider>
        </RecoilRoot>
    );
}

export default App;