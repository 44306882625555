import { selector } from "recoil";
import { Point } from "../../generated/graphql";
import { currentPointState } from "../atoms/currentPointState";
import { toPointState } from "./toPointState";
import { fromPointState } from "./fromPointState";


export type PointTypeType = "notfound" | "fromPoint" | "toPoint" | "normal";
export const pointTypeState = selector<PointTypeType>({
    key: "pointTypeState",
    get: ({ get }) => {
        const point = (get(currentPointState) || {}) as Point;
        const fromPoint = get(fromPointState);
        const toPoint = get(toPointState);

        const pointId = point.id || '';
        const fromPointId = fromPoint.id || '';
        const toPointId = toPoint.id || '';

        if (!pointId) return "notfound";
        if (!point?.destination) return "notfound";
        if (pointId === fromPointId) return "fromPoint";
        if (pointId === toPointId) return "toPoint";

        return "normal";
    }
});
