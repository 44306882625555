import { FC } from "react";
import { LogoAnimeAndNavigate } from "../../components/LogoAnimeAndNavigate";

export const QrAnimeBlockView: FC<{
    animeUrl: string;
    navigateUrl: string;
}> = ({ animeUrl, navigateUrl }) => {
    return (
        <LogoAnimeAndNavigate
            animeUrl={animeUrl}
            navigateUrl={navigateUrl}
        />
    );
};
