import { atom } from "recoil";
import { useDestinationsQuery } from "../../generated/graphql";

type destinationsQueryStateType = Pick<ReturnType<typeof useDestinationsQuery>, 'data' | 'loading'>;
export const destinationsQueryState = atom<destinationsQueryStateType>({
    key: "destinationsQueryState",
    default: {
        data: {} as destinationsQueryStateType['data'],
        loading: true,
    } as destinationsQueryStateType,
});
