import { FC, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { distanceOfPointsEvQueryState } from "../../stores";
import { useDistanceOfPointsEvQuery } from "../../generated/graphql";
import { useParams } from "react-router-dom";


export const LoadDistanceEv: FC = () => {
    useLoadDistanceEv();
    return null;
};

function useLoadDistanceEv() {
    const { group, from } = useParams();
    const setValue = useSetRecoilState(distanceOfPointsEvQueryState);
    const { data, loading } = useDistanceOfPointsEvQuery({
        skip: !group || !from,
        variables: { id: group, from },
    });
    useEffect(() => {
        setValue({ data, loading });
    }, [data, loading, setValue]);
    return null;
};
