import { FC } from "react";
import { useRecoilValue } from "recoil";
import { useParams } from "react-router-dom";
import { FloorTab } from "../../../components/FloorTab";
import { linkMapRoute } from '../../../functions/links/linkMapRoute';
import {
    floorsState,
    floorState
} from "../../../stores";


export const MapRouteFloorTab: FC = () => {
    const floor = useRecoilValue(floorState);
    const floors = useRecoilValue(floorsState);
    const { group, from, to } = useParams();
    if (!to) return null;
    return (
        <FloorTab
            floors={floors}
            currentFloorId={floor.id || ""}
            to={({ floor }) => {
                return linkMapRoute({ group, floor, from, to });
            }} />
    );
};
